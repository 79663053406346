import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as Notification } from "assets/icons/notification.svg";
import HoveredIcon from "components/HoveredIcon";
import { ToastContainer } from "react-toastify";
import "./ReactToastify.css";

const bgColors = {
  info: "bg-transparent mt-4",
  success: "bg-success dark:bg-[#63E235]",
  warning: "bg-errorOrange dark:bg-[#E27A35]",
  error: "bg-errorRed dark:bg-[#DF411E]",
  default: "",
};

const Toaster = () => (
  <ToastContainer
    autoClose={5000}
    limit={10}
    className={(type) =>
      `${type?.type === "info" ? "max-w-[800px]" : "max-w-[656px]"} w-fit ${type?.defaultClassName
      }`
    }
    toastClassName={(type) =>
      `${type?.type === "info"
        ? "rounded-[22px] !bg-white !items-start"
        : "rounded-full"
      } ${type?.defaultClassName} w-full  dark:!bg-deepSpace900 dark:text-grey100`
    }
    bodyClassName={(type) =>
      `${type?.type === "info" ? "" : "items-center"} flex max-sm:text-xs`
    }
    icon={({ type }) => (
      <div className={`${bgColors[type]} ml-1.5 p-1.5 max-sm:p-1 mr-5 rounded-full`}>
        <Notification
          className={`${type === "info" ? "fill-darkBlue dark:fill-grey100" : "fill-white dark:fill-deepSpace900"
            } max-sm:w-3.5 max-sm:h-3.5 w-5 h-5`}
        />
      </div>
    )}
    closeOnClick={false}
    closeButton={({ closeToast, type }) => (
      <div
        className={`${type === "info" ? "" : "my-auto"}`}
        onClick={closeToast}
      >
        <HoveredIcon
          Icon={Clear}
          className={`mr-1.5 ${type === "info" ? "ml-4" : "ml-10"}`}
          iconClassName="w-4 h-4 max-sm:w-3 max-sm:h-3"
        />
      </div>
    )}
  />
);
export default Toaster;
