
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as Emoji } from 'assets/images/404-emoji.svg';
import Button from 'components/Button';

const NotFound = () => (
    <div className="h-screen w-full relative bg-[url('assets/images/404-graphic.svg')] bg-no-repeat bg-center flex items-center justify-center">
        <div className="flex flex-col justify-center items-center">
            <Emoji />
            <div className="text-[96px] text-white font-bold leading-1 mt-[30px]">404</div>
            <div className="text-[48px] text-white font-normal">Page not Found</div>
            <Logo className="w-[70px] h-[70px] mt-[88px]" />
            <Button title="Home" className="w-[228px] h-[56px] border-[2px] border-darkBlue bg-white text-darkBlue dark:text-grey100 font-bold hover:!border-darkBlue mt-[42px]" isLink redirect={"/"} />
        </div>
    </div>
)

export default NotFound