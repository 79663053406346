import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import SocialIcons from "components/SocialIcons";
import jwt from "jwt-decode";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useLoginMutation } from "store/api/authApi";
import { gtmLoginSubmit } from "utils/googleTagManager";
import { object, string } from "yup";
import LoginLayout from "./LoginLayout";

interface FormValues {
  email: string;
  password: string;
}

let loginSchema = object({
  email: string().trim().required("Email is Required").email("Email is invalid"),
  password: string().required("Password is Required"),
});

const Login = () => {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(['rege']);
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(loginSchema),
  });
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const [login, { isLoading, error }] = useLoginMutation();

  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error?.status === 400) {
        toast.error("Wrong Email or Password!");
        //reset(); on purpose commenting this line as we do not want to clear the fields when user enter wrong info
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleCreateAccount = () => {
    var path = isAffiliate ? PATH.affiliateCreateAccount : PATH.registration
    navigate(path);
    removeCookie('rege', {
      domain: process.env.REACT_APP_DOMAIN_URL,
    })
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const returnUrl = searchParams.get("redir");
    const zudaValue = searchParams.get("zuda") === 'true';
    await login({ ...values, zuda: zudaValue }).unwrap().then(data => {
      if (data.result.accessToken) {
        const token = jwt(data.result.accessToken);
        gtmLoginSubmit(token); // send login event to google tag manager
      }
    });
    if (returnUrl) {
      if (returnUrl.includes("redir")) {
        navigate(PATH.dashboard.root)
      }
      else
        navigate(returnUrl);
    }
  };
  const inputContClassName = "flex flex-col gap-[5px]"
  const inputClassName = "h-[44px] max-md:h-[48px] py-[5px] px-[17px] border-2 rounded-[7px]"
  const labelClassName = "text-lg font-medium leading-[28px] max-md:text-[14px] max-md:leading-[20px]"

  return (
    <LoginLayout>
      <div className="mx-auto max-w-[365px] max-md:max-w-[330px] w-full">
        <h5 className="text-[28px] leading-[36px] font-medium mb-[12px] max-sm:!text-center max-sm:text-2xl !text-left max-md:!text-center">Welcome!</h5>
        <h4 className="text-[16px] leading-[24px] font-medium mb-[15px] max-md:text-center">Please enter your details</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
          className="flex flex-col"
        >
          <div className={`${inputContClassName} mb-[15px]`}>
            <label htmlFor="email" className={labelClassName}>Email</label>
            <ControlledInput<FormValues>
              className={inputClassName}
              placeholder="Enter your Email"
              name="email"
              register={register}
              type="text"
              variant="contained"
              fontSize="text-base"
              inputClassName="autofill:bg-transparent"
              errors={errors}
            />
          </div>

          <div className={`${inputContClassName}`}>
            <label htmlFor="password" className={labelClassName}>Password</label>
            <ControlledInput
              type="password"
              className={inputClassName}
              placeholder="Password"
              name="password"
              register={register}
              variant="contained"
              togglePassword
              fontSize="text-base"
              inputClassName="autofill:bg-transparent"
              errors={errors}
            />
          </div>
          <Button
            type="submit"
            title="Log In"
            loading={isLoading}
            color="primaryPurple"
            height="h-[44px] max-md:h-[48px]"
            titleClassName="!text-base font-bold group-hover:text-white"
            className="w-full mb-[25px] mt-[15px] max-md:mb-[35px] group"
          />
        </form>
        <Link
          className="text-electricBlue hover:text-primaryPurple dark:hover:text-purple500 underline cursor-pointer text-center mb-[25px] block"
          to={PATH.requestPassword}
        >
          Lost your Password?
        </Link>
        <div className="flex justify-center items-center gap-[32px] max-md:gap-[29px] mb-[25px]">
          <p className="">
            New to ZIK?{" "}
          </p>
          <Button
            type="button"
            title="Create an Account"
            handleClick={handleCreateAccount}
            color="smoothGreen"
            titleClassName="font-medium"
            height="h-[36px] max-md:h-[44px]"
            className="max-w-[190px] max-sm:max-w-[200px]"
          />
        </div>
        <div className="flex justify-center items-center">
          <SocialIcons />
        </div>

      </div>
    </LoginLayout>
  );
};

export default Login;
