import { Table } from "@tanstack/react-table";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import ReactPaginate from "react-paginate";
import { UsePaginationResponse } from "types/common";
interface Props<T> {
  table?: Table<T>;
  pagination?: UsePaginationResponse;
  onPageChange?: (newPage: number) => void;
  labeled?: boolean;
  className?: string;
  labelClassName?: string;
}

const Pagination = <T,>({
  table,
  labeled,
  pagination,
  onPageChange,
  className,
  labelClassName,
}: Props<T>) => {

  const prevNextStyles = "w-8 h-8 flex items-center justify-center rounded-full group bg-neutral2 hover:bg-primaryPurple dark:bg-deepSpace800 dark:hover:bg-purple500"
  const prevNextIconStyles = "fill-darkBlue group-hover:fill-white dark:fill-lilac400 dark:group-hover:fill-lilac400"
  const current = table
    ? table.getState().pagination.pageIndex
    : pagination
      ? pagination.current
      : 0;

  const totalCount = table
    ? table.getPrePaginationRowModel().rows.length
    : pagination
      ? pagination.total
      : 0;

  const goto = table
    ? table.setPageIndex
    : pagination
      ? pagination.goto
      : () => { };

  const pageSize = table
    ? table.getState().pagination.pageSize
    : pagination
      ? pagination.pageSize
      : 0;

  const totalPages = table
    ? table.getPageCount()
    : pagination
      ? pagination.totalPages
      : 0;

  const hasNext = table
    ? table.getCanNextPage()
    : pagination
      ? pagination.hasNext
      : 0;

  return (
    <div
      className={`flex justify-between items-center max-sm:gap-4 max-sm:flex-col dark:text-grey100 ${className || ""
        }`}
    >
      {labeled && (
        <p className={`text-xs ${labelClassName || ""}`}>
          Showing {totalCount > 0 ? current * pageSize + 1 : 0} to{" "}
          {hasNext ? current * pageSize + pageSize : totalCount} of {totalCount}{" "}
          entries
        </p>
      )}
      <ReactPaginate
        forcePage={current}
        breakLabel="..."
        nextLabel={
          <button
            className={prevNextStyles}
          >
            <ArrowLeft className={`rotate-180 ${prevNextIconStyles}`} />
          </button>
        }
        marginPagesDisplayed={1}
        onPageChange={(event) => {
          onPageChange && onPageChange(event.selected);
          goto(event.selected);
        }}
        pageClassName="rounded-full cursor-pointer hover:bg-primaryPurple hover:text-white dark:hover:text-lilac400"
        pageLinkClassName={
          "w-8 h-8 rounded-full flex items-center justify-center cursor-pointer font-medium text-sm"
        }
        previousLinkClassName={prevNextStyles}
        nextLinkClassName={prevNextStyles}
        activeClassName="text-primaryPurple dark:text-purple500 hover:!text-primaryPurple dark:hover:!text-purple500 border-2 border-primaryPurple dark:border-purple500 hover:bg-transparent w-8 h-8 relative"
        activeLinkClassName="!absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
        className="flex items-center gap-2"
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel={
          <button
            className={prevNextStyles}
          >
            <ArrowLeft className={prevNextIconStyles} />
          </button>
        }
      />
    </div>
  );
};

export default Pagination;
