import Button from "components/Button";
import Textarea from "components/Input/Textarea";
import Modal from "components/Modal";
import Rating from "components/Rating";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
interface Props {
  helpers: ReturnType<useModal>;
  title?: string;
  rating: number;
  comment: string;
  modalBtnTitle?: string;
  isLoading: boolean;
  onRatingChange: (newVal: number) => void;
  onCommentChange: (newVal: string) => void;
  onSaveSearch: () => void;
}

const SaveSearchModal: FC<Props> = ({
  helpers,
  onSaveSearch,
  title,
  rating,
  onRatingChange,
  comment,
  onCommentChange,
  modalBtnTitle,
  isLoading,
}) => {
  const [isError, setIsError] = useState(false);

  const onSave = () => {
    if (comment === "" || rating === 0) {
      setIsError(true);
    } else {
      onSaveSearch();
    }
  };
  useEffect(() => {
    if (isError) {
      if (comment !== "" && rating !== 0) {
        setIsError(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, rating]);

  return (
    <Modal
      helpers={helpers}
      cardClassName="py-7"
      maxWidth="max-w-[509px]"
      buttonCloseClassName="!top-[20.5px] !right-[25px]"
      titleClassName="mb-[13px]"
      title={title || "Add to Search List"}
    >
      <div className="flex flex-col items-center justify-center gap-3">
        <Textarea
          value={comment}
          handleChange={onCommentChange}
          className="max-w-[248px] h-[7rem] scrollbar dark:placeholder:text-grey100"
          placeholder="Comment"
        />
        <div className="flex flex-col items-center gap-[4.4px] mb-4">
          <h5 className="text-sm font-bold dark:text-lilac400">Rating</h5>
          <Rating rating={rating} handleRatingChange={onRatingChange} itemClassName="!py-0" />
        </div>
        <Button
          title={modalBtnTitle || "Save Search"}
          loading={isLoading}
          handleClick={onSave}
          color="smoothGreen"
          height="h-9"
          className="max-w-[188px] dark:bg-deepSpace900"
        />
        <p
          className={`text-errorRed transition-opacity duration-[500] ${isError ? "" : "hidden"
            }`}
        >
          All Fields are required!
        </p>
      </div>
    </Modal>
  );
};

export default SaveSearchModal;
