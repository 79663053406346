import IntercomApp from "components/Intercom/Intercom";
import TrackingComponent from "components/TrackingCode/TrackingCode";
import { TooltipProvider } from "context/TooltipContext";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { DecodedUser } from "hooks/useDecodeToken";
import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TagManager from 'react-gtm-module';
import Router from "routes";
import { handleDarkModeChange } from "store/slices/themeSlice";
import isPaymentPath from "utils/isPaymentPath";
import { GetAccessToken } from "utils/localStorage";


const tagManagerArgs = {
  gtmId: 'GTM-K3QMPNS'
}
/* const TRACKING_ID = "GTM-K3QMPNS"; // YOUR_OWN_TRACKING_ID */

TagManager.initialize(tagManagerArgs)

const App = () => {
  const [userDetails, setUserDetails] = useState<DecodedUser | null>(null);
  const [isReg, setIsReg] = useState(false)
  const { accessToken } = useAppSelector((state) => state.user.result);
  const dispatch = useAppDispatch()
  const [cookie] = useCookies(['darkMode']);
  const isLoggedIn = accessToken || GetAccessToken();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const journeyIncomplete = userDetails?.journey_completed.toLowerCase() === "false"
  useEffect(() => {
    // if (userDetails) {
    //   initDataLayer(userDetails);
    // }

    setIsReg(journeyIncomplete && !isPaymentPath() && !isAffiliate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])


  useEffect(() => {
    if (isLoggedIn) {
      setUserDetails(jwt(isLoggedIn))
    } else {
      dispatch(handleDarkModeChange(!isLoggedIn || isReg ? false : cookie.darkMode === "true"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isReg])

  return (
    <TooltipProvider>
      <TrackingComponent />
      <IntercomApp />
      <Router />
    </TooltipProvider>
  )
};
export default App;


