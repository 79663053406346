import Tooltip from "components/Tooltip";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useHover from "hooks/useHover";
import useWindowDimensions from "hooks/useWindowDimensions";
import { FC, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { GetAccessToken } from "utils/localStorage";
import QuickSettings from "./QuickSettings";
import SidebarSubmenu from "./SidebarSubmenu";

export interface SidebarSubmenuProps {
  path: string;
  title: string;
  locked?: boolean;
  translate: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  submenu?: SidebarSubmenuProps[];
}
interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  IconHovered?: React.FC<React.SVGProps<SVGSVGElement>>;
  path: string;
  title: string;
  translate: string;
  submenu?: SidebarSubmenuProps[];
  quickSettings?: boolean;
  isActive?: boolean;
  handleCustomClick?: () => void;
  width?: string;
  isOpen?: boolean;
  handleChange?: () => void;
  handleClose?: () => void;
  iconStyles: string;
  smIconStyles: string;
  redirect?: string;
  store?: string;
}
const SidebarItemContent: FC<Props> = ({
  Icon,
  IconHovered,
  handleCustomClick,
  isOpen,
  handleChange,
  handleClose,
  title,
  translate,
  path,
  submenu,
  width,
  quickSettings,
  iconStyles,
  smIconStyles,
  redirect,
  store
}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const user = useDecodeToken()
  const { height } = useWindowDimensions();
  const isSmallSidebar = height < 800
  const { pathname } = useLocation();
  const { t } = useTranslation(['home']);
  const isActive = pathname.includes(path);
  const iconClassName = `${isHovered || isActive || isOpen ? "fill-primaryPurple dark:fill-purple500 " : "fill-darkBlue dark:fill-grey100"
    } ${isSmallSidebar ? smIconStyles : iconStyles}`;

  const hideItemNotification = user.package_name.includes("LT") || user.package_name.includes("EnterpriseY") || user.package_name.includes("StandardY")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['CAT']);
  const { accessToken } = useAppSelector((state) => state.user.result);

  const [ignoreClose, setIgnoreClose] = useState(false)
  const token = accessToken || GetAccessToken();
  const handleClick = () => {
    if (redirect) {
      setCookie("CAT", token,
        {
          domain: process.env.REACT_APP_DOMAIN_URL,
        });
      window.location.href = redirect

      return
    }
    if ((submenu && submenu.length > 0) || quickSettings) {
      handleChange && handleChange();
    } else {
      if (handleCustomClick) {
        handleCustomClick();
      }

      return
    }
  };


  return (
    <div
      className={`${isSmallSidebar ? "w-[26px] h-[26px]" : "w-9 h-9"} flex items-center justify-center rounded-full relative 
        border-transparent border-2 focus:outline-none ${isOpen
          ? "border-primaryPurple pointer-events-none dark:border-purple500"
          : "cursor-pointer hover:border-primaryPurple dark:hover:border-purple500"
        }`}
      ref={hoverRef}
      data-tooltip-id={title}
      onClick={handleClick}
    >
      {IconHovered && (isHovered || isActive) ? (
        <IconHovered className={`fill-primaryPurple dark:fill-purple500 ${isSmallSidebar ? smIconStyles : iconStyles}`} />
      ) : (
        <Icon className={iconClassName} />
      )}
      {isOpen && submenu && (
        <SidebarSubmenu
          submenu={submenu}
          width={width}
          handleClose={() => handleClose && handleClose()}
          store={store}
        />
      )}
      {isOpen && quickSettings && (
        <QuickSettings handleClose={() => !ignoreClose && handleClose && handleClose()} setIgnoreClose={setIgnoreClose} />
      )}
      {!isOpen ? <Tooltip place="right" title={t(translate)} anchorId={title} /> : ""}
    </div>
  );
};

const SidebarItem: FC<Props> = (props) =>
  props.submenu || props.quickSettings || props.path.includes("logout") || props.redirect ? (
    <SidebarItemContent {...props} />
  ) :
    (
      <Link to={props.path}>
        <SidebarItemContent {...props} />
      </Link>
    );

export default SidebarItem;
