import Enterprise from "./Enterprise";
import Pro from "./Pro";
import ProPlus from "./ProPlus";

interface Props {
  planType: string;
}

const PlanFeatures = ({ planType }: Props) => (
  <>
    {planType.toLowerCase() === "starterm" ||
    planType.toLowerCase() === "startery" ||
    planType.toLowerCase() === "prolt" ? (
      <Pro />
    ) : planType.toLowerCase() === "standardm" ||
      planType.toLowerCase() === "standardy" ||
      planType.toLowerCase() === "propluslt" ? (
      <ProPlus />
    ) : (
      <Enterprise />
    )}
  </>
);

export default PlanFeatures;
