import HandEmoji from "assets/images/hand-emoji.png";
import Button from "components/Button";
import { FC } from "react";
import { PATH } from "routes/path";


interface Props {
    date?: string
}
const CancelSuccess: FC<Props> = ({ date }) => (
    <div className="flex flex-col items-center w-full" >
        <img src={HandEmoji} alt="" className="text-center mb-4 w-10 h-10" />
        <h4 className="text-xl font-semibold mb-2.5 text-center">We’ve proceeded with your cancelation!</h4>
        <p className="text-center text-lg font-medium leading-[28px] mb-[47px]">
            Thank you for trying ZIK Analytics. <br />
            You’ll be able to continue using the software until your renewal date <span className="text-primaryPurple dark:text-purple500">{date || "-"}</span>
        </p>
        <Button title="My Dashboard" className="max-w-[250px] bg-darkBlue text-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:text-lilac400 dark:border-lilac400 dark:bg-deepSpace900 dark:hover:bg-paradiseBlue dark:hover:border-paradiseBlue dark:hover:text-paradiseBlue hover:!text-darkBlue font-bold" titleClassName="!text-base font-700" height="h-16" redirect={PATH.dashboard.root} isLink />
    </div>
)

export default CancelSuccess