import { saleDateRangeArr, sourceSites } from "assets/data/filters";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Modal from "components/Modal";
import SelectFolder from "components/Modal/SelectFolder";
import Select from "components/Select";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import { FC, ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useEditMultiBulkMutation,
  useSaveBulkMutation
} from "store/api/bulkScannerApi";
import { queryTags } from "store/api/queryTags";
import { quickSettingsApi, useGetSettingsQuery } from "store/api/quickSettingsApi";
import { Option } from "types/common";
import { BulkMultiScannerList } from "types/services/bulkScanner";

interface Props {
  isEdit?: boolean;
  helpers: ReturnType<useModal>;
  selectedUsernames?: string[];
  selectedMultiBulk?: BulkMultiScannerList;
}

const initialFilterState = {
  minPrice: 0,
  minProfit: 0,
  minSales: 0,
  sourceSite: "1",
  dateRange: saleDateRangeArr[0].value,
  isOnlyPrime: false,
};

const MultiScannerFilterCard = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <div className="max-w-[180px] w-full">
    <h6 className="text-center text-sm font-bold">{title}</h6>
    {children}
  </div>
);

const AddMultiScanner: FC<Props> = ({
  helpers,
  selectedUsernames,
  selectedMultiBulk,
}) => {
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const [filters, setFilters] = useState(initialFilterState);
  const dispatch = useAppDispatch()
  const [saveBulk, { isLoading, isSuccess, isError }] = useSaveBulkMutation();
  const [
    editBulk,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError },
  ] = useEditMultiBulkMutation();
  const { data: settings } = useGetSettingsQuery()
  const onFolderSelect = (newVal: Option | null) => setSelectedFolder(newVal);

  const onModalClose = () =>
    helpers.close();

  useEffect(() => {
    if (isSuccess || editSuccess) {
      helpers.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, editSuccess]);

  useEffect(() => {
    if (isError || editError) {
      toast.error("Something went wrong");
    }
  }, [isError, editError]);

  const handleScan = async () => {
    if (
      selectedFolder === null
    ) {
      toast.error(
        "Something went wrong, please make sure that all fields are filled out properly and try again."
      );
    } else {
      if (selectedMultiBulk) {
        editBulk({
          siteiD: Number(filters.sourceSite),
          minPrice: filters.minPrice,
          maxPrice: 0,
          minSales: filters.minPrice,
          isPrime: filters.isOnlyPrime,
          minProfit: filters.minProfit,
          maxProfit: 0,
          folderId: Number(selectedFolder?.value),
          dayRange: Number(filters.dateRange),
          statusText: selectedMultiBulk?.statusText || "",
          sellerNames: selectedMultiBulk?.sellerNames,
          multiBulkScanRequestId: selectedMultiBulk?.multiBulkScanRequestId,
          status: selectedMultiBulk?.status,
          dateRequested: selectedMultiBulk?.dateRequested,
          userId: 0,
        });
      } else {
        await saveBulk({
          sellerNames: selectedUsernames || [],
          siteID: filters.sourceSite,
          minPrice: filters.minPrice?.toString(),
          maxPrice: "0",
          minSales: filters.minSales?.toString(),
          isPrime: filters.isOnlyPrime || false,
          minProfit: filters.minProfit?.toString(),
          maxProfit: "0",
          folderid: selectedFolder?.value || "",
          dayRange: filters.dateRange,
          userId: 0,
        })
        dispatch(quickSettingsApi.util.invalidateTags([
          queryTags.quickSettings.getSettings
        ]))
      }
    }
  };

  const handleFilterChange = (
    newVal: number | string | boolean,
    field: keyof typeof initialFilterState
  ) => {
    setFilters((prev) => ({ ...prev, [field]: newVal }));
  };

  useEffect(() => {
    if (!selectedMultiBulk && settings) {
      const { result } = settings
      setFilters({
        sourceSite: result.multiSourceSite?.toString() || "1",
        minPrice: result.multiMinimumPrice,
        minSales: result.multiMinimumSales,
        isOnlyPrime: result.multiPrime,
        minProfit: result.multiMinimumProfit,
        dateRange: result.multiDayRange?.toString() || result.competitorDay?.toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, helpers.isOpen])

  useEffect(() => {
    if (selectedMultiBulk) {
      setSelectedFolder({
        text: selectedMultiBulk.folderName,
        value: selectedMultiBulk.folderId.toString(),
      });
      setFilters({
        sourceSite: selectedMultiBulk.siteiD.toString(),
        minPrice: selectedMultiBulk.minPrice,
        minSales: selectedMultiBulk.minSales,
        isOnlyPrime: selectedMultiBulk.isPrime,
        minProfit: selectedMultiBulk.minProfit,
        dateRange: selectedMultiBulk.dayRange.toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMultiBulk]);

  return (
    <Modal
      helpers={{ ...helpers, close: onModalClose }}
      title="Use the filters below to create your scan request"
      titleClassName="mb-[38.5px]"
      cardClassName="pt-[29px] pb-[27px] px-12 h-[100%] max-w-[100%] border-secondary"
      buttonCloseClassName="top-[18px] right-[24px] border-0 w-5 h-5"
      maxWidth="max-w-[927px]"
      overlay
    >
      <div className="flex flex-col gap-5 ">
        <div className="flex flex-wrap justify-center max-md:items-center gap-x-[35px] gap-y-5">
          <MultiScannerFilterCard title="Minimum Price" >
            <MinMaxFilter
              value={{ min: filters.minPrice }}
              inputWidth="w-16"
              hideMax
              allowNegative
              name="minPrice"
              handleChange={(newVal) => handleFilterChange(newVal, "minPrice")}
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Minimum Profit">
            <MinMaxFilter
              value={{ min: filters.minProfit }}
              inputWidth="w-16"
              hideMax
              allowNegative
              name="minProfit"
              handleChange={(newVal) => handleFilterChange(newVal, "minProfit")}
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Minimum Sales">
            <MinMaxFilter
              value={{ min: filters.minSales }}
              inputWidth="w-16"
              hideMax
              allowNegative
              name="minSales"
              handleChange={(newVal) => handleFilterChange(newVal, "minSales")}
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Folder">
            <SelectFolder
              hideAddNewFolder
              type="eBay"
              cutTitle={11}
              cutLongOption={14}
              btnClassName="w-full"
              className="!min-w-[180px] max-w-[180px]"
              selectedFolder={selectedFolder}
              onFolderSelect={onFolderSelect}
              isOpen={helpers.isOpen}
              selectFirstOption
            />
          </MultiScannerFilterCard>
        </div>

        <div className="flex items-center justify-center mx-12 flex-wrap max-md:items-center max-md:justify-around gap-x-[35px] gap-y-5">
          <MultiScannerFilterCard title="Source Website">
            <Select
              options={sourceSites}
              value={filters.sourceSite}
              handleChange={(newVal) =>
                handleFilterChange(newVal, "sourceSite")
              }
            />
          </MultiScannerFilterCard>
          <MultiScannerFilterCard title="Date Range">
            <Select
              options={saleDateRangeArr}
              value={filters.dateRange}
              handleChange={(newVal) => handleFilterChange(newVal, "dateRange")}
            />
          </MultiScannerFilterCard>
          <div className="flex items-center">
            <FilterCheckbox
              options={[
                {
                  text: filters.sourceSite === "4" ? "Sold by Walmart" : "Prime Only",
                  value: "only-prime",
                },
              ]}
              className={filters.sourceSite === "4" ? "min-w-[180px]" : "min-w-[158px]"}
              handleChange={() =>
                handleFilterChange(!filters.isOnlyPrime, "isOnlyPrime")
              }
              value={filters.isOnlyPrime ? "only-prime" : ""}
            />
          </div>
        </div>
        <Button
          title="Create Scan"
          color="smoothGreen"
          className="max-w-[9rem] mx-auto mt-3"
          height="h-9"
          loading={isLoading || editLoading}
          handleClick={handleScan}
        />
      </div>
    </Modal>
  );
};

export default AddMultiScanner;
