import { formatNumber } from "utils/formatCurrency";

export enum ErrorCodes {
  alreadyAddedToQueue = "AlreadyAddedToQueue",
  alreadyToQueue = "AddedToQueue",
  notPossible = "NotPossible",
  userNotFound = "UserNotFound",
  noSales = "nosales",
  usernameChange = "UsernameChange",
  limitExeeded = "LimitExceeded",
  noApi = "NoApi",
  trialLimitExceeded = "TrialLimitExceeded",
  notExist = "notExist",
  itemAlreadyAdded = "ItemAlreadyAdded",
  success = "success",
  Success = "Success",
  ok = "ok",
  empty = "Empty",
  error = "error",
  same = "Same",
  fetchError = "FETCH_ERROR",
  alreadyAddedAsStore = "Already Added As Store",
  storeNotPossible = "Not Possible",
  storeLimitReached = "Store Limit Reached",
  storeDoesntExist = "Store doesnt Exist",
}

export const ErrorMsgs = {
  [ErrorCodes.alreadyAddedToQueue]: "AlreadyAddedToQueue",
  [ErrorCodes.alreadyToQueue]: "AddedToQueue",
  [ErrorCodes.notPossible]: `Due to large volume of items in store, we are unable to fetch results for users with more than ${formatNumber(
    100000
  )} items.`,
  [ErrorCodes.userNotFound]: "User doesn't exist",
  [ErrorCodes.noSales]: "The seller had no sales for the selected period.",
  [ErrorCodes.usernameChange]: (username: string) =>
    `This user has changed eBay username, please search competitor under the new username: ${username}`,
  [ErrorCodes.itemAlreadyAdded]: (folderName: string) =>
    `Folder "${folderName}" already contains this product`,
  [ErrorCodes.limitExeeded]: "Error 10014. Try refreshing page.",
  [ErrorCodes.noApi]:
    "Something went wrong, please try scanning the seller again. (Error code: 1024)",
  [ErrorCodes.trialLimitExceeded]: "TrialLimitExceeded",
  [ErrorCodes.notExist]: "Seller doesn't exist",
  [ErrorCodes.empty]:
    "No products matches your search criteria. Try changing filters.",
  [ErrorCodes.error]: "Something went wrong",
};
