import Loading from "components/Loading";
import { useGetRemainingUsesQuery } from "store/api/bannersApi";

interface Props {
    fontSize?: string;
    counterFontsize?: string;
    className?: string;
}
const PricingUsesCounter = ({ fontSize, counterFontsize, className }: Props) => {
    const { data, isLoading } = useGetRemainingUsesQuery("FLASH50");

    return (
        <div className={`mt-[8px] mb-[14px] w-[197px] h-[46px] bg-secondary rounded-[8px] flex items-center justify-center border-[2px] border-white shadow-[0px_6px_8px_-6px_rgba(20,_23,_26,_0.06),_0px_8px_16px_-6px_rgba(20,_23,_26,_0.04)] ${className || ""}`}>
            <div className={`flex items-center justify-center gap-[8px] leading-[24px] dark:text-darkBlue ${fontSize ? fontSize : "text-[16px]"}`}>
                Only
                {isLoading ?
                    (<Loading fill="darkBlue" width={20} height={20} />)
                    :
                    (<div className={`font-bold leading-[28px] underline ${counterFontsize ? counterFontsize : "text-[20px]"}`}>{data?.result && data.result <= 1 ? 1 : data?.result}</div>)
                }

                Spots left!
            </div>
        </div>
    )
}

export default PricingUsesCounter