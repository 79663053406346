import { LANDING_PAGE_LINKS } from "config/constants";
import { useTranslation } from "react-i18next";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";
import LandingPages from "../components/LandingPages";
import MyPaypalEmail from "../components/MyPaypalEmail";
import TotalEarnings from "../components/TotalEarnings";
import UrlCopy from "../components/UrlCopy";
import Campaigns from "../lists/AffiliateManager/Campaigns";
import Referrals from "../lists/AffiliateManager/Referrals";

const Earnings = () => {
  const { data } = useGetEarningStatsQuery();
  const { t } = useTranslation(['home']);

  return (
    <div className="grid grid-cols-[2fr_1fr] items-start gap-5.5 max-[1220px]:grid-cols-1 dark:text-grey100">
      <div className="flex gap-5.5 flex-wrap">
        <div className="grid grid-cols-2 gap-5.5 w-full max-[800px]:grid-cols-1">
          <TotalEarnings />
          <MyPaypalEmail />
        </div>
        <div className="flex flex-col w-full gap-5.5">
          <Campaigns />
          <Referrals />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <LandingPages />
        <UrlCopy
          title={t('MyReferralUrl')}
          url={`${LANDING_PAGE_LINKS.home}?ReferrerId=${data?.result.userId}`}
        />
        <UrlCopy
          title="Course Referral URL"
          url={`${LANDING_PAGE_LINKS.dropshippingCourse}?ReferrerId=${data?.result.userId}`}
        />
      </div>
    </div>
  );
};

export default Earnings;
