import { noOfEntries } from "assets/data/filters";
import Search from "components/Search";
import Select from "components/Select";

// Hooks

// Icons
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { referralCols } from "assets/tableColumns/referrals";
import InfoTooltip from "components/Card/InfoTooltip";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { useTranslation } from "react-i18next";
import { useGetReferredUserQuery } from "store/api/affiliateManagerApi";

const tableStyles: TableStyles = {
  px: "px-0",
  minWidth: "min-w-[700px]",
  style: "mb-10 ",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[49px]",
  },
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[77px]",
  },
};

const tableConfig = {
  pagination: true,
  search: true,
};

const Referrals = () => {
  const { data, isLoading } = useGetReferredUserQuery();
  const { t } = useTranslation(['home']);
  const { table } = useTable({
    columns: referralCols,
    data: data?.result || [],
    initialPageSize: 50,
    dataDeps: [data],
    ...tableConfig,
  });

  return (
    <div className="px-4 py-5.5 border-2 border-neutral2 rounded-[10px] h-full dark:border-grey700 dark:bg-grey800">
      <div className="flex items-center gap-[20px] mb-[15px]">
        <div className="font-bold ml-7">{t('Referrals')}</div>
        <InfoTooltip title="A list of all your referrals " />
      </div>

      <div className="flex items-end justify-between mb-3 max-sm:flex-col max-sm:items-center max-sm:gap-3">
        <Select
          options={noOfEntries}
          value={table.getState().pagination.pageSize.toString()}
          Icon={Menu}
          btnClassName="min-w-[12rem]"
          handleChange={(value) => table.setPageSize(Number(value))}
        />

        <Search
          search={table.getState().globalFilter}
          handleChange={table.setGlobalFilter}
          maxWidth="18.8125rem"
          className="w-[18.8125rem] border-darkBlue"
          size="sm"
          htmlFor="table-search"
        />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isLoading} />

      <Pagination table={table} labeled />
    </div>
  );
};

export default Referrals;
