import { LANDING_APP_URL } from "config/config";
import { useAppDispatch } from "hooks/useAppDispatch";
import Processing from "pages/Loading/processing";
import Upgrade from "pages/Payment/upgrade";
import RegistrationPaymentCompeted from "pages/RegistrationFunnel/steps/RegistrationPaymentCompleted";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import {
  useLazyGetLatestSubscriptionQuery,
  useLazyPayoneerSubscriptionQuery,
  useLazyPaypalSubscriptionQuery
} from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { gtmStartTrial, gtmSubscriptionPurchase } from "utils/googleTagManager";
import { SetAccessToken } from "utils/localStorage";

interface Props {
  isPaypal?: boolean;
  isPayoneer?: boolean;
  isUpgrade?: boolean;
  isCreditCard?: boolean;
  isDowngrade?: boolean;
  isChanged?: boolean;
}

const PurchaseComplete = ({
  isPaypal,
  isPayoneer,
  isUpgrade,
  isCreditCard,
  isChanged,
  isDowngrade
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get("INVOICE_ID") || "";
  const errorCode = searchParams.get("ERROR") || "";

  const code = searchParams.get("code") || "";
  const state = searchParams.get("state") || "";
  const registration = !isUpgrade && !isDowngrade && !isChanged;
  useEffect(() => {
    // Check if the "state" parameter contains the word "course"
    if (state && state.includes('course')) {
      // If "course" is found, construct the new URL
      const coursePayment = `${LANDING_APP_URL}AnalyticsSuccess/PayoneerConsent?` + window.location.href.split('?')[1];

      // Redirect the user to the new URL
      window.location.href = coursePayment;
    }
  }, []);


  const [getLatestSubscription, { isFetching: lastSubFetching }] = useLazyGetLatestSubscriptionQuery();

  const [paypalSubscription, { isFetching: paypalFetching, }] =
    useLazyPaypalSubscriptionQuery();

  const [payoneerSubscription, { isFetching: payoneerFetching }] =
    useLazyPayoneerSubscriptionQuery();

  const goToDashboard = () => {
    if (!registration) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  };

  const getLastSub = () => {
    getLatestSubscription(null).unwrap().then(data => {
      if (data) {
        const transactionValue = Number(data.result.transaction_value);
        if (transactionValue > 1) {
          // call subscription_purchase event 
          gtmSubscriptionPurchase(data, isUpgrade, isDowngrade);
        }
        else {
          // call start_trial event
          gtmStartTrial(data);
        }
      }
      goToDashboard();
    });
  }

  useEffect(() => {
    if (isPaypal) {
      paypalSubscription({
        invoiceId: invoiceId,
        errorCode: errorCode,
      })
        .unwrap()
        .then((data) => {
          if (data.result.message === "AlreadyTrial") {
            SetAccessToken(data.result.claims);
            dispatch(setUserAccessToken(data.result.claims));
            navigate(PATH.alreadyTrial)
          }
          else {
            SetAccessToken(data.result.claims);
            dispatch(setUserAccessToken(data.result.claims));
            getLastSub();
          }

        }).catch((err) => {
          if (err.data.message?.includes("Exclusive Coupon")) {
            navigate(PATH.alreadyTrial + `?CouponUsed=true`)
          }
        });


    }

    if (isPayoneer && state && !state.includes('course')) {
      payoneerSubscription({
        code: code,
        state: state,
      })
        .unwrap()
        .then((data) => {
          if (data.result.message === "AlreadyTrial") {
            SetAccessToken(data.result.claims);
            dispatch(setUserAccessToken(data.result.claims));
            navigate(PATH.alreadyTrial)

          }
          else {
            SetAccessToken(data.result.claims);
            dispatch(setUserAccessToken(data.result.claims));
            getLastSub();
          }

        }).catch((err) => {
          if (err.data.message.includes("Exclusive Coupon")) {
            navigate(PATH.alreadyTrial + `?CouponUsed=true`)
          }
        });
    }

    if (isUpgrade || isCreditCard || isChanged || isDowngrade) {
      if (!isChanged) {
        getLastSub();
      }
      else {
        goToDashboard();
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {paypalFetching || payoneerFetching ? (
        <Processing />
      ) :
        registration ? (<RegistrationPaymentCompeted loading={lastSubFetching} />) : (
          <Upgrade isUpgrade={isUpgrade} isChanged={isChanged} isDowngrade={isDowngrade} isRegistration={registration} />
        )}
    </>
  );
};

export default PurchaseComplete;
