import { createApi } from "@reduxjs/toolkit/query/react";
import {
  GetAliGrowthReq,
  GetAliGrowthRes,
  GetBestSellingItemsReq,
  GetBestSellingItemsRes,
  PostZikProRequest,
  PostZikProResponse,
} from "types/services/zikPro";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const zikProApi = createApi({
  reducerPath: "zikPro",
  baseQuery,
  endpoints: (builder) => ({
    getCompetitorCount: builder.query<PostZikProResponse, PostZikProRequest>({
      query: (params) => ({
        url: queryKeys.zikPro.getCompetitionCount,
        method: "GET",
        params,
      }),
    }),
    getBestSellingItems: builder.query<
      GetBestSellingItemsRes,
      GetBestSellingItemsReq
    >({
      query: (params) => ({
        url: queryKeys.zikPro.getBestSellingItems,
        method: "GET",
        params,
      }),
    }),
    getAliGrowth: builder.query<GetAliGrowthRes, GetAliGrowthReq>({
      query: (body) => ({
        url: queryKeys.zikPro.getAliGrowth,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetCompetitorCountQuery,
  useGetAliGrowthQuery,
  useGetBestSellingItemsQuery,
} = zikProApi;
