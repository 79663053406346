import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";

const Enterprise = () => (
  <div className="flex flex-col gap-[10px] w-[246px] mx-auto">
    <div className="font-bold text-[14px] text-center">
      All the features from PRO+, and:
    </div>
    <div className="flex gap-3 text-[14px]">
      <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
      <div className="font-medium">
        <div className="text-primaryPurple dark:fill-purple500">Autopilot</div>
        <div className="text-[12px]">
          Automatically find dropshipping products from AliExpress,<br className="hidden max-[1060px]:block max-[965px]:hidden" /> Amazon, and
          Walmart
        </div>
      </div>
    </div>
    <div className="flex gap-3 text-[14px]">
      <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
      <div className="font-medium">
        <div className="text-primaryPurple dark:text-purple500">Turbo Scanner</div>
        <div className="text-[12px]">
          Automatically discover the best selling dropship items from Amazon and
          Walmart
        </div>
      </div>
    </div>
  </div>
);

export default Enterprise;
