import { plansComparison } from "assets/data/plans/plans";
import Scrollbar from "components/Scrollbar";
import { useState } from "react";
import { PlansType, TSelectedPlan } from "types/plans";
import PlanComparisonFooter from "./PlanComparisonFooter";
import PlansComparisonItem from "./PlansComparisonItem";
import ShowFeatureToggle from "./ShowFeatureToggle";

interface Props {
  plans: PlansType;
  currentPlan: string;
  hasFooter?: boolean;
  isTrial?: boolean;
  paymentStatus?: number;
  showCheckout?: () => void;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  contStyles?: string
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  isFooterDark?: boolean,
  footerBg?: string
  fromNoSubscription?: boolean;
}

const PlansComparison = ({
  plans,
  currentPlan,
  isTrial,
  paymentStatus,
  showCheckout,
  hasFooter,
  handleSelectPlan,
  selectedPlan,
  isFooterDark,
  contStyles,
  fromRegistration = false,
  footerBg,
  fromNoSubscription
}: Props) => {
  const flexStyles = (plan?: "pro" | "pro-plus" | "enterprise") => `flex items-center font-bold justify-center ${plan && plan !== selectedPlan ? "max-[965px]:hidden" : ""} ${plan ? "max-[965px]:col-span-2" : "max-[965px]:col-span-4"}`
  const [showFeature, setShowFeature] = useState(true);
  const handleToggleShowFeature = () => {
    setShowFeature(!showFeature)
  }

  return (
    <>
      {
        selectedPlan && <ShowFeatureToggle showFeature={showFeature} handleToggleShowFeature={handleToggleShowFeature} />
      }

      {
        showFeature && <Scrollbar className={`overflow-x-scroll ${selectedPlan ? "" : "max-[1024px]:hidden"} w-full`}>
          <div className={`max-w-[1220px] w-full mt-[15px] 0 ${contStyles || ""}`}>
            <div className="shadow-shadow3 z-10 rounded-[15px] overflow-hidden relative z-[20]">
              <div className="grid grid-cols-4 max-[965px]:grid-cols-6 h-[49px] rounded-t-[15px] bg-darkBlue text-white">
                <div className={flexStyles()}>Quick Look</div>
                <div className={flexStyles("pro")}>Pro</div>
                <div className={flexStyles("pro-plus")}>Pro+</div>
                <div className={flexStyles("enterprise")}>Enterprise</div>
              </div>
              {plansComparison.map((item, i) => (
                <PlansComparisonItem
                  key={i}
                  item={item}
                  selectedPlan={selectedPlan}
                  hasBottomDivider={plansComparison.length - 1 !== i}
                />
              ))}
            </div>
            {hasFooter && (
              <PlanComparisonFooter
                selectedPlan={selectedPlan}
                plans={plans}
                currentPlan={currentPlan}
                showCheckout={showCheckout}
                isTrial={isTrial}
                isFooterDark={isFooterDark}
                paymentStatus={paymentStatus}
                handleSelectPlan={handleSelectPlan}
                fromRegistration={fromRegistration}
                footerBg={footerBg}
                fromNoSubscription={fromNoSubscription}
              />
            )}
          </div>
        </Scrollbar>
      }



    </>

  );
}

export default PlansComparison;
