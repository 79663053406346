import useFilters from "hooks/useFilters";

import Button from "components/Button";

// Icons
import { countryList } from "assets/data/countryList";
import { ReactComponent as Profile } from "assets/icons/profile.svg";
import LabeledInput from "components/Input/LabeledInput";
import Select from "components/Select";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateUserDetailsMutation } from "store/api/quickSettingsApi";
import { GetUserDetailsRes } from "types/services/user";
import { SetUserDetails } from "utils/localStorage";

interface Props {
  data?: GetUserDetailsRes;
}
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  country: 230,
  address: "",
  phoneNumber: "",
};

const AccountDetails: FC<Props> = ({ data }) => {
  const { filters, handleFilterChange, setFilters } = useFilters(initialState);
  const [updateUser, { isLoading }] = useUpdateUserDetailsMutation();
  const { t } = useTranslation(['home']);

  useEffect(() => {
    if (data) {
      setFilters({
        firstName: data.result.firstName || "",
        lastName: data.result.lastName || "",
        email: data.result.email || "",
        country: Number(data.result.countryId) || 0,
        address: data.result.address || "",
        phoneNumber: data.result.phoneNumber || "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSave = () => {
    updateUser({
      address: filters.address,
      countryId: Number(filters.country),
      phoneNumber: filters.phoneNumber,
      userId: data?.result.userId || 0,
    })
      .unwrap()
      .then((payload) => {
        if (payload.result === true) {
          toast.success("User information Saved.");
          SetUserDetails({
            userId: data?.result.userId || 0,
            firstName: data?.result.firstName || "",
            lastName: data?.result.lastName || "",
            email: data?.result.email || "",
            countryId: data?.result.countryId || null,
            roleName: data?.result.roleName || "",
          });
        }
      });
  };

  return (
    <div className="p-6 pb-[31px] bg-neutral2 rounded-[10px] max-w-[560px] w-full max-[1080px]:max-w-full  dark:bg-grey800 dark:border-2 dark:border-grey700">
      <div className="flex items-center gap-[26px] font-bold text-darkBlue dark:text-grey100">
        <Profile width={17} className="fill-darkBlue dark:fill-grey100" />
        <div>{t('AccountDetails')}</div>
      </div>
      <div className="grid grid-cols-2 gap-[52px] mt-[30px] max-xl:grid-cols-1 max-xl:gap-[19px] max-lg:grid-cols-2 max-[660px]:grid-cols-1">
        <div className="flex flex-col gap-[19px]">
          <LabeledInput
            value={filters.firstName}
            disabled
            handleChange={(newVal) => handleFilterChange(newVal, "firstName")}
            autoComplete="off"
            placeholder="John"
            name="firstName"
            labelClassName="font-bold dark:text-lilac400"
            label={t("FirstName")} />

          <LabeledInput
            value={filters.lastName}
            disabled
            handleChange={(newVal) => handleFilterChange(newVal, "lastName")}
            placeholder="Wick"
            autoComplete="off"
            labelClassName="font-bold dark:text-lilac400"
            label={t("LastName")}
          />

          <LabeledInput
            value={filters.email}
            disabled
            handleChange={(newVal) => handleFilterChange(newVal, "email")}
            placeholder="johnwick02@gmail.com"
            autoComplete="off"
            type="email"
            labelClassName="font-bold dark:text-lilac400"
            label={t("Email")} />

          <div className="flex flex-col gap-3.5">
            <label className="text-darkBlue dark:text-grey100 font-bold">Country</label>
            <Select
              btnClassName="w-full !h-[31px]"
              options={countryList}
              value={filters.country.toString() || ""}
              handleChange={(newVal) => handleFilterChange(newVal, "country")} />
          </div>
        </div>

        <div className="flex flex-col gap-[19px]">
          <LabeledInput
            value={filters.address}
            handleChange={(newVal) => handleFilterChange(newVal, "address")}
            placeholder="Your Address"
            autoComplete="off"
            name="address"
            labelClassName="font-bold dark:text-lilac400"
            label={t("Address")} />

          <LabeledInput
            value={filters.phoneNumber}
            handleChange={(newVal) => newVal.length < 15 ? handleFilterChange(newVal, "phoneNumber") : null}
            type="number"
            placeholder="+1 123123123"
            name="phone-number"
            labelClassName="font-bold dark:text-lilac400"
            label={t("PhoneNumber")} />

          <Button
            title="Save"
            type="submit"
            handleClick={onSave}
            loading={isLoading}
            height="h-11"
            className="max-w-[229px] ml-auto mt-[25px] max-[660px]:w-full bg-white dark:bg-deepSpace900"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
