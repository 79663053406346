export enum MARKET_PLACES {
  ebay = "ebay.com",
  ebayUk = "ebay.co.uk",
  ebayDe = "ebay.de",
  ebayCa = "ebay.ca",
  ebayAu = "ebay.com.au",
  ebayFr = "ebay.fr",
  ebayIt = "ebay.it",
  ebayEs = "ebay.es",
  ebayMy = "ebay.com.my",
  shopify = "shopify",
  woocommerce = "woocommerce",
  none = "none",
}

export enum BUSINESS_MODEL {
  dropshipping = "dropshipping",
  arbitrage = "arbitrage",
  wholesale = "wholesale",
  reseller = "reseller",
  brandOwner = "brand_owner",
  none = "none",
}

export enum SOURCE_MARKET_PLACES {
  amazon = "amazon.com",
  amazonUk = "amazon.co.uk",
  amazonAu = "amazon.co.au",
  walmart = "walmart",
  aliexpress = "aliexpress",
  alibaba = "alibaba",
  ebay = "ebay",
  none = "none",
}
export enum SHIPPED_MODEL {
  unitedStates = "us",
  china = "cn",
  germany = "de",
  unitedKingdom = "uk",
  australia = "au",
  canada = "ca",
  france = "fr",
  italy = "it",
  spain = "es",
  malaysia = "my",
  none = "none",
}
