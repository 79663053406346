import Button from "components/Button";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useLazyValidateCouponQuery } from "store/api/managePaymentApi";
import Input from ".";

interface Props {
  handleApplyCoupon: (newVal: string) => void;
  selectedPlan: string;
  preloadCoupon?: string;
  fromRegistration?: boolean
  couponValidating?: (validating: boolean) => void;
  handleRefetchUpgrade?: () => void;
}

const CheckoutCouponInput = ({
  handleApplyCoupon,
  selectedPlan,
  preloadCoupon,
  fromRegistration,
  couponValidating,
  handleRefetchUpgrade
}: Props) => {
  const { pathname } = useLocation()
  const isUpcampaign = pathname.includes(PATH.managementPanel.upcampaign)
  const [defaultCoupon, setDefaultCoupon] = useState<string | null>(null)
  const [cookies] = useCookies(['ReferrerId']);
  const [coupon, setCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("")
  const [couponToggle, setCouponToggle] = useState(false)
  const [validateCoupon, { isFetching }] =
    useLazyValidateCouponQuery();

  const onDefaultCouponChange = (val: string) => {
    setDefaultCoupon(val)
    setCoupon(val)
    setAppliedCoupon(val);
    setCouponToggle(true)
  }

  useEffect(() => {
    onDefaultCouponChange(preloadCoupon || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadCoupon])

  useEffect(() => {
    if (isUpcampaign && (selectedPlan === "StarterY" || selectedPlan === "StandardY" || selectedPlan === "EnterpriseY")) {
      onDefaultCouponChange("SUCCESS24")
    }
  }, [isUpcampaign, selectedPlan])

  useEffect(() => {
    const { ReferrerId } = cookies
    if (ReferrerId && fromRegistration) {
      const val = ReferrerId === "138070" ? "MORF2021" : ReferrerId === "166215" ? "MEIRPC" : ReferrerId === "216205" ? "THAER2021" : ""
      if (val !== "") {
        onDefaultCouponChange(val)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.ReferrerId])

  useEffect(() => {

    if (defaultCoupon !== null) {
      couponValidating?.(true)
      validateCoupon({
        couponName: coupon,
        subscriptionType: selectedPlan,
      })
        .unwrap()
        .then((data) => {
          if (data?.result === true) {
            handleApplyCoupon(coupon);
          } else {
            setCoupon("");
            setAppliedCoupon("");
            handleRefetchUpgrade?.()
            handleApplyCoupon("")
          }
          couponValidating?.(false)
        })
        .catch((error) => {
          toast.error(error.data.message);
          setCoupon("");
          setAppliedCoupon("")
          handleApplyCoupon("");
          handleRefetchUpgrade?.();
          setCouponToggle(false);
          couponValidating?.(false)
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCoupon]);

  const applyCoupon = () => {
    if (coupon === null || coupon?.trim().length === 0) {
      handleApplyCoupon("");
    }
    couponValidating?.(true)

    validateCoupon({
      couponName: coupon,
      subscriptionType: selectedPlan,
    })
      .unwrap()
      .then((data) => {

        if (data?.result === true) {
          handleApplyCoupon(coupon);
          setAppliedCoupon(coupon)
          couponValidating?.(false)
        } else {
          handleApplyCoupon("");
          setAppliedCoupon("")
          couponValidating?.(false)
        }
      })
      .catch((error) => {
        toast.error(error.data.message);
        setCoupon("");
        setAppliedCoupon("")
        handleApplyCoupon("");
        couponValidating?.(false)
      });
  };

  return (
    <div className="max-sm:max-w-[335px] max-sm:w-full max-sm:mx-auto flex flex-col">
      <button className={`font-normal cursor-pointer select-none sm:text-left text-sm ${fromRegistration ? "mb-[3px]" : "mb-[9px]"} ${couponToggle ? "text-darkBlue dark:text-grey100" : "text-neutral3"}`} onClick={() => { setCouponToggle(!couponToggle) }}>
        Got a Coupon?
      </button>
      <div className={`items-center max-sm:mt-1 ${fromRegistration ? "gap-5" : "gap-2.5"} ${couponToggle ? "flex max-sm:flex-col" : "hidden"}`}>
        <Input
          className={` h-9 w-full px-[10px] py-2 max-sm:px-1 dark:bg-deepSpace900 ${fromRegistration ? " max-w-[176px]" : "max-w-[250px] rounded-[7px]"}`}
          eraseClassName={"!top-1/2 -translate-y-1/2"}
          inputClassName="max-sm:pr-0"
          value={coupon}
          erase
          variant={fromRegistration ? "outlined" : "contained"}
          name="coupon"
          placeholder="Add it here"
          handleChange={(newVal) => setCoupon(newVal)}
        />
        <Button
          loading={isFetching}
          className={`max-w-[103px] w-full dark:bg-deepSpace900 ${appliedCoupon === coupon && appliedCoupon !== "" ? "invisible max-sm:hidden" : ""}`}
          height="h-9"
          title="Apply"
          handleClick={applyCoupon}
        />
      </div>
    </div >
  );
};

export default CheckoutCouponInput;
