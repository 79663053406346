import HelpVideo from "components/Modal/HelpVideo";
import useModal from "hooks/useModal";
import { FC } from "react";
import HeaderIcon, { HeaderVariants } from "./Icon";

interface Props {
  videos: {
    title: string;
    source: string;
  }[];
  className?: string;
  iconClassName?: string;
  setIgnoreClose?: (close: boolean) => void;

}

const HeaderHelpVideo: FC<Props> = ({ videos, className, iconClassName, setIgnoreClose }) => {
  const modal = useModal();

  const handleClick = () => {
    setIgnoreClose && setIgnoreClose(true);
    modal.open()
  }
  
  return (
    <>
      <HeaderIcon
        variant={HeaderVariants.video}
        handleClick={handleClick}
        tooltipTitle="Watch the tool guide"
        className={className}
        iconClassName={iconClassName}
      />
      <HelpVideo helpers={modal} videos={videos} setIgnoreClose={setIgnoreClose} />
    </>
  );
};

export default HeaderHelpVideo;
