import { lifetime, monthly, yearly } from "assets/data/plans/plans";
import { enterprise, proPlus } from "assets/data/plans/proToolsPlans";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import { PlansType } from "types/plans";
import { makeQueryParams } from "../utils/makeQueryParams";

const usePlans = (planType: string, noSetParams?: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isUpcampaign = pathname.includes(PATH.managementPanel.upcampaign);
  const selectedPlan = (type: string) =>
    type === "monthly"
      ? monthly
      : type === "yearly"
      ? {
          ...yearly,
          planList: yearly.planList.map((item) => ({
            ...item,
            planDiscountPrice: isUpcampaign
              ? item.upcampaignPrice
              : item.planDiscountPrice,
          })),
        }
      : type === "proplus"
      ? proPlus
      : type === "enterprise"
      ? enterprise
      : lifetime;

  const [plans, setPlans] = useState<PlansType>(selectedPlan(planType));

  useEffect(() => {
    if (searchParams.has("plan")) {
      setPlans(selectedPlan(searchParams.get("plan") || "monthly"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handlePlanChange = (type: string) => {
    setPlans(selectedPlan(type));
    if (!noSetParams) {
      setSearchParams(
        makeQueryParams("", {
          plan: type,
        })
      );
    }
  };

  return {
    plans,
    handlePlanChange,
  };
};

export default usePlans;
