import Card from "components/Card";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useGetCampaignGraphQuery } from "store/api/affiliateManagerApi";
import { formatDate } from "utils/formatTime";

import CenteredLoading from "components/Loading/CenteredLoading";
import { THEME_COLORS } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType
} from "recharts/types/component/DefaultTooltipContent";
import { formatNumber } from "utils/formatCurrency";

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  valueLabel: string;
  valueType?: "percentage" | "number" | "currency";
}
const CustomRechartsTooltip: FC<CustomTooltipProps> = (data) => {
  const { active, payload, label } = data;

  const value = payload && payload[0]?.value ? payload[0].value.toString() : "";
  if (active && payload && payload.length) {
    return (
      <div>
        <div className="custom-tooltip relative text-darkBlue dark:text-grey100 bg-white px-3 py-3 border-2 border-darkBlue rounded-lg outline-none text-xs dark:bg-deepSpace900 dark:border-paradiseBlue">
          <p className="label font-medium z-10">
            Clicks - {formatNumber(value)}
          </p>
          <p className="label font-medium z-10">
            Registered - {formatNumber(payload[0]?.payload?.registered || 0)}
          </p>
          <p className="z-10">{label}</p>
        </div>
      </div>
    );
  }

  return null;
};

interface Props {
  campaignId: string;
}

const CampaignGraph: FC<Props> = ({ campaignId }) => {
  const { data, isFetching } = useGetCampaignGraphQuery(campaignId);
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);

  return (
    <Card className="mx-8 text-xs p-4 pl-2" bordered rounded="rounded-xl">
      <h4 className="text-sm font-bold ml-12 mb-6">Graphic Chart</h4>
      <div className=" h-[300px]">
        <CenteredLoading loading={isFetching}>
          <ResponsiveContainer width="100%">
            <AreaChart
              className="product-graph-chart"
              data={data?.result.map((item) => ({
                date: formatDate(item.campaignDate, "MM-DD"),
                clicks: item.clicks,
                registered: item.registered,
              }))}
              innerRadius={100}
              margin={{
                top: 10,
                right: 8,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid
                stroke={THEME_COLORS[isDarkMode ? "deepSpace900" : "secondary"]}
                strokeWidth="2"
                rx={25}
                ry={250}
                vertical={false}
                radius="40px"
              />
              <XAxis
                dataKey="date"
                strokeWidth={0}
                style={{
                  fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                  textAnchor: "middle",
                }}
                interval="preserveStartEnd"
                tickLine={false}
                tickMargin={11}
              />
              <YAxis
                strokeWidth={0}
                tickMargin={16}
                style={{
                  fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],

                }}
              />
              <Tooltip
                content={<CustomRechartsTooltip valueLabel="Clicks" />}
                cursor={false}
                wrapperStyle={{ outline: "none" }}
              />

              <Area
                type="monotone"
                dataKey="clicks"
                activeDot={{
                  style: {

                    stroke: THEME_COLORS[isDarkMode ? "deepSpace900" : "darkBlue"],
                    zIndex: "10",
                  },
                }}
                unit="date"
                strokeWidth={2}
                stroke="#150D3F"
                fill="#66E7D7"
              />
              <Area
                type="monotone"
                dataKey="registered"
                activeDot={{
                  style: {
                    stroke: THEME_COLORS[isDarkMode ? "deepSpace900" : "darkBlue"],
                    zIndex: "10",
                  },
                }}
                opacity={0.6}
                unit="date"
                strokeWidth={2}
                stroke={THEME_COLORS[isDarkMode ? "deepSpace900" : "primaryPurple"]}
                fill="#6831FF"
              />
            </AreaChart>
          </ResponsiveContainer>
        </CenteredLoading>
      </div>
    </Card>
  );
};

export default CampaignGraph;
