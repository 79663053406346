import { landingPages } from "assets/data/ManagementPanel/affiliateManager";
import InfoTooltip from "components/Card/InfoTooltip";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  link?: string;
  title: string;
}

const LandingPage: FC<Props> = ({ link, title }) => (
  <div className="flex justify-between text-sm">
    <div dangerouslySetInnerHTML={{ __html: title }} />
    <Link
      className="underline text-electricBlue"
      target="_blank"
      to={link || ''}
      rel="noreferrer"
    >
      Preview
    </Link>
  </div>
);

const LandingPages = () => (
  <div className="px-8 py-5.5 border-2 border-neutral2 rounded-10 w-full dark:border-grey700 dark:bg-grey800">
    <div className="flex items-center gap-[20px]  mb-[15px]">
      <div className="font-bold ml-7">Landing Pages</div>
      <InfoTooltip title="Choose a page that potential customers can “land” on when they click through your affiliate link" />
    </div>
    <div className="flex flex-col gap-[7px]">
      {landingPages.map((item) => (
        <LandingPage {...item} key={item.title} />
      ))}
    </div>
  </div>
);

export default LandingPages;
