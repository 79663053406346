import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Journey } from "assets/images/journey.svg";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import SocialIcons from "components/SocialIcons";
import Toaster from "components/Toast";
import { LANDING_APP_URL } from "config/config";
import { GOOGLE_CAPTCHA_KEY } from "config/constants";
import RegistrationFunnelNavbar from "pages/RegistrationFunnel/Navbar";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useRegisterUserMutation, useSavePotentialUserMutation } from "store/api/onboardingApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { gtmRegisterSubmit, gtmRegisterSubmitAttempt } from "utils/googleTagManager";
import { SetAccessToken, SetUserDetails } from "utils/localStorage";
import validator from 'validator';
import { object, string } from "yup";
import ModalFunnelStartWithZikCheckmark from "./components/ModalFunnelStartWithZikCheckmark";
import { RegistrationImageText as Text } from "./components/RegistrationImageText";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  IsAffiliate: boolean;
  refferId: number;
  campaignId: string;
  Ga: string;
  clickidgclid: string;
  gclid: string;
  fbc: string;
  fbp: string;
  ampDeviceId: string;
  ampGetSessionId: string;
}
const zikFeatures = ["Never miss a profitable trend again", "Find the best niches and products", "Dominate your market"]

let registerSchema = object({
  firstName: string().required("First Name is Required").max(400, "First Name is too long!"),
  lastName: string().required("Last Name is Required").max(400, "Last Name is too long!"),
  email: string().required("Email is Required").max(200, "Email is invalid").email("Email is invalid"),
  password: string()
    .required("Password is Required")
    .min(5, "Password must be between 5 and 20 characters!")
    .max(20, "Password must be between 5 and 20 characters!")
});
const Registration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(registerSchema),
  });
  const [cookies] = useCookies(['rege', 'ReferrerId', 'CampaignId', '_ga', 'clickid_gclid', '_gclid', '_fbc', '_fbp', 'amp_deviceId', 'amp_getSessionId']);
  const email = cookies.rege
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const referredId = cookies.ReferrerId;
  const campaignId = cookies.CampaignId;
  const ga = cookies._ga;
  const { clickid_gclid } = cookies;
  const gclid = cookies._gclid;
  const fbc = cookies._fbc;
  const fbp = cookies._fbp;
  const { amp_deviceId } = cookies;
  const { amp_getSessionId } = cookies;

  useEffect(() => {
    if (email) {
      setValue("email", email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const [registerUser, { isLoading, error }] = useRegisterUserMutation();
  const [savePotentialUser, { isLoading: savePotUserLoading }] = useSavePotentialUserMutation();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaErr, setRecatchaErr] = useState("");
  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error?.status === 400) {
        toast.error("Wrong Email or Password!");
        reset();
      }
      else {
        toast.error("An error occured!");
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const saveUser = async (values: FormValues) => {
    //some extra validations
    var unsafeName = validator.isEmail(values.firstName) || validator.isURL(values.firstName);
    if (unsafeName) {
      toast.error("First Name is not valid");
      return;
    }
    var unsafeLastName = validator.isEmail(values.lastName) || validator.isURL(values.lastName);
    if (unsafeLastName) {
      toast.error("Last Name is not valid");
      return;
    }

    registerUser(values)
      .unwrap()
      .then((data) => {
        if (data.result.tokens.accessToken) {
          gtmRegisterSubmit(data.result.userInfo.email, data.result.userId);
          navigate(PATH.dashboard.myDashboard);
          SetAccessToken(data.result.tokens.accessToken);
          SetUserDetails(data.result.userInfo);
          dispatch(setUserAccessToken(data.result.tokens.accessToken));
        }
      }).catch((error) => {
        toast.error(error.data);
      })
  }

  const onCaptchaChange = (value: string | null) => {
    if (value !== "" || value !== null) {
      setRecatchaErr("");
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const recaptchaValue = recaptchaRef.current?.getValue();
    if (recaptchaValue === "" || recaptchaValue === null) {
      setRecatchaErr("Captcha is required");

      return;
    }
    values.IsAffiliate = isAffiliate === true;
    values.refferId = referredId || 0;
    values.campaignId = campaignId || "";
    values.Ga = ga || "";
    values.clickidgclid = clickid_gclid || "";
    values.ampDeviceId = amp_deviceId || "";
    values.ampGetSessionId = amp_getSessionId || "";
    values.fbc = fbc || "";
    values.fbp = fbp || "";
    values.gclid = gclid || "";


    if (cookies.rege == null) {
      const savePotentialData = {
        email: values.email,
        Ga: values.Ga,
        clickidgclid: values.clickidgclid,
        gclid: values.gclid,
        fbc: values.fbc,
        fbp: values.fbp,
        ampDeviceId: values.ampDeviceId,
        ampGetSessionId: values.ampGetSessionId,
      };

      await savePotentialUser(savePotentialData).unwrap()
        .then(async () => {
          await saveUser(values);
        }).catch((error) => {
          toast.error(error.data);
        })
    }
    else {
      await saveUser(values);
    }
  };
  const isEmailReadOnly = !email || email === null || email?.trim() === "" ? false : true

  const inputClassName = "h-[44px] max-md:h-[48px] border-2 py-[5px] px-[17px] rounded-[7px] bg-white text-base";
  const inputContClassName = "flex flex-col gap-[5px]"
  const checkboxStyles = "bg-teal_A400 border border-solid border-teal_A400_01 flex h-[25px] items-center justify-center p-1.5 rounded-[12px] w-[25px]"
  const labelStyles = "text-lg font-medium leading-[28px] max-md:text-[14px] max-sm:leading-[24px]"

  return (
    <div className="h-screen overflow-y-auto max-lg:bg-white">
      <div className="bg-white py-[24px] px-[32px] sm:px-[32px] lg:hidden w-full left-0">
        <RegistrationFunnelNavbar handleBack={() => { }} step={0} isRegistration />
      </div>
      <div className="flex max-lg:bg-white max-sm:mt-[15px] max-lg:mt-[25px]">
        <div className="w-[52%] flex lg:justify-center lg:items-center lg:h-[100vh] max-lg:h-fit max-lg:w-full my-auto lg:py-10 max-md:mx-6 max-sm:py-0">
          <div className="mx-auto max-w-[365px] max-sm:max-w-[330px]">
            <Text
              className="leading-[28px] font-bold text-gray_900 text-[20px] md:hidden text-center"
              as="h1"
            >
              When eCommerce Works!
            </Text>
            <h5 className="text-[28px] leading-[36px] font-medium mb-[20px] max-md:text-[16px] max-md:text-center">
              Start with ZIK Analytics!
            </h5>

            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="flex flex-col gap-[15px] max-lg:max-w-full max-md:gap-[10px]"
            >
              <div className={inputContClassName}>
                <label htmlFor="firstName" className={labelStyles}>First Name</label>
                <ControlledInput<FormValues>
                  className={inputClassName}
                  fontSize="text-base"
                  placeholder="Type your first name"
                  name="firstName"
                  register={register}
                  type="text"
                  variant="contained"
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                />
              </div>
              <div className={inputContClassName}>
                <label htmlFor="lastName" className={labelStyles}>Last Name</label>
                <ControlledInput<FormValues>
                  className={inputClassName}
                  placeholder="Type your last name"
                  name="lastName"
                  fontSize="text-base"
                  register={register}
                  type="text"
                  variant="contained"
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                />
              </div>
              <div className={inputContClassName}>
                <label htmlFor="email" className={labelStyles}>Email</label>
                <ControlledInput<FormValues>
                  className={`${inputClassName} ${isEmailReadOnly ? "!bg-[#F6F6F6] !border-[#CACACA]" : ""}`}
                  placeholder="Enter your Email"
                  name="email"
                  fontSize="text-base"
                  register={register}
                  type="text"
                  variant="contained"
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                  defaultValue={email}
                  readOnly={isEmailReadOnly}
                />
              </div>

              <div className={inputContClassName}>
                <label htmlFor="password" className={labelStyles}>Password</label>
                <ControlledInput
                  type="password"
                  className={`${inputClassName}`}
                  placeholder="Password"
                  name="password"
                  fontSize="text-base"
                  register={register}
                  variant="contained"
                  togglePassword
                  inputClassName="autofill:bg-transparent"
                  errors={errors}
                />
              </div>

              <div className="flex flex-col justify-center items-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={GOOGLE_CAPTCHA_KEY}
                  onChange={onCaptchaChange}
                />
                {recaptchaErr !== "" && <div className="text-errorRed text-xs mt-2 ">{recaptchaErr}</div>}
              </div>


              <div className="text-[14px] leading-[20px] text-neutral3 text-center">
                By signing up, you agree to our <Link className="text-electricBlue underline" to={`${LANDING_APP_URL}terms-and-conditions/`}>Terms & Conditions</Link> and acknowledge that we will process your personal data in accordance with our <Link className="text-electricBlue underline" to={`${LANDING_APP_URL}/privacy-policy/`}>Privacy Policy</Link>
              </div>

              <Button
                type="submit"
                title={isAffiliate === true ? "Enroll for free" : "Next"}
                loading={isLoading || savePotUserLoading}
                color="smoothGreen"
                variant="outlined"
                titleClassName="!text-base font-bold text-smoothGreen group-hover:text-darkBlue"
                height="h-14"
                className="w-full h-[44px] max-md:h-[48px] mx-auto my-[24px] max-md:my-[20px] group bg-darkBlue !border-darkBlue hover:!border-smoothGreen text-bold"
                handleClick={gtmRegisterSubmitAttempt}
              />
            </form>
            <div className="flex justify-center items-center">
              <SocialIcons />
            </div>


          </div>
        </div>

        <div className="w-[48%] bg-white fixed h-full top-0 right-0 max-lg:hidden">
          <div className=" bg-white_A700 flex flex-col gap-[50px] h-full justify-center  max-w-[481px] mx-auto">
            <Text
              className="leading-[44px] text-gray_900 md:!text-[36px]"
              as="h1"
              variant="h1"
            >
              When eCommerce Works!
            </Text>
            <Journey />

            <div className="flex flex-col gap-[15px]">
              {zikFeatures.map((feature, i) => (
                <div className="flex flex-row gap-2.5 items-center" key={i}>
                  <ModalFunnelStartWithZikCheckmark className={checkboxStyles} />
                  <Text
                    className="font-medium text-gray_900 sm:text-justify sm:w-auto"
                    as="h3"
                    variant="h3"
                  >
                    {feature}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
      <Toaster />
    </div>
  );
};
export default Registration;
