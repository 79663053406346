import { useAppSelector } from "hooks/useAppSelector";
import { DecodedUser } from "hooks/useDecodeToken";
import jwt from "jwt-decode";
import DashboardLayout from "layouts/dashboard";
import Academy from "pages/AcademyAndTutorials/Academy/Index";
import Tutorials from "pages/AcademyAndTutorials/Tutorials/Index";
import AffiliateCreateAccount from "pages/AffiliateCreateAccount";
import { AutoDs } from "pages/Autods";
import BulkScannerItems from "pages/BulkScannerItems";
import CategoryResearch from "pages/CategoryResearch";
import CategoryResearchResults from "pages/CategoryResearch/Results";
import CompetitorResearchFilters from "pages/CompetitorResearch";
import CompetitorResearchAliexpress from "pages/CompetitorResearch/Aliexpress";
import CompetitorResearchEbay from "pages/CompetitorResearch/Ebay";
import EndTrial from "pages/Loading/EndTrial";
import Processing from "pages/Loading/processing";
import YorayReset from "pages/Loading/YorayReset";
import Login from "pages/Login";
import ResetPassword from "pages/Login/ResetPassword";
import Logout from "pages/logout";
import Maintenance from "pages/Maintenance";
import Billing from "pages/ManagementPanel/Billing";
import AlreadyTrial from "pages/ManagementPanel/components/ManagePayments/AlreadyTrial";
import PurchaseComplete from "pages/ManagementPanel/components/ManagePayments/PurchaseComplete";
import PurchaseFailed from "pages/ManagementPanel/components/ManagePayments/PurchaseFailed";
import ManagementPanel from "pages/ManagementPanel/Index";
import AffiliateManager from "pages/ManagementPanel/tabs/AffiliateManager";
import MyCompetitors from "pages/MyCompetitors";
import MyProducts from "pages/MyProducts";
import MyProductsItem from "pages/MyProducts/Product";
import NewDashboard from "pages/NewDashboard";
import NoSubscription from "pages/NoSubscription";
import NotFound from "pages/NotFound";
import Upgrade from "pages/Payment/upgrade";
import ProductResearchFilters from "pages/ProductResearch";
import ProductResearchAliexpress from "pages/ProductResearch/Aliexpress";
import ProductResearchEbay from "pages/ProductResearch/Ebay";
import QuickSettings from "pages/QuickSettings/Index";
import Registration from "pages/Registration";
import StoreAnalytics from "pages/StoreAnalytics";
import TitleBuilder from "pages/TitleBuilder";
import TitleBuilderResults from "pages/TitleBuilder/Results";
import AutopilotScan from "pages/ZikPro/AutopilotScan";
import ZikPro from "pages/ZikPro/index";
import TurboScannerItem from "pages/ZikPro/TurboScannerItem";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { GetAccessToken } from "utils/localStorage";

const handleRedir = (newRoute: string) => {
  if (newRoute.includes("redir")) return newRoute
  else return `${PATH.login}?redir=${newRoute}`
}

const Router = () => {
  const { accessToken } = useAppSelector((state) => state.user.result);
  const isLoggedIn = accessToken || GetAccessToken();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const isTest = environment === "dev" || environment === "beta" || environment === "pre-prod"
    ? true : false;
  const { pathname, search } = useLocation()
  useGetSettingsQuery(undefined, { skip: !isLoggedIn });
  const [userDetails, setUserDetails] = useState<DecodedUser | null>(null)

  useEffect(() => {
    if (isLoggedIn) {
      setUserDetails(jwt(isLoggedIn))
    }
  }, [isLoggedIn])


  const authRoutes = [
    {
      path: PATH.login,
      children: [{ path: PATH.login, element: <Login /> }],
    },
    {
      path: PATH.registration,
      element: <Registration />,
    },
    {
      path: PATH.affiliateCreateAccount,
      element: <AffiliateCreateAccount />,
    },
    {
      path: PATH.requestPassword,
      element: <ResetPassword isRequest={true} />,
    },
    {
      path: PATH.resetPassword,
      element: <ResetPassword isReset={true} />,
    },
    {
      path: PATH.maintenance,
      element: <Maintenance />
    },
    isTest ? {
      path: PATH.yorayppccreset,
      element: <YorayReset />
    } : {},
    isTest ? {
      path: PATH.endTrial,
      element: <EndTrial />
    } : {},
    {
      path: PATH.yorayppccreset,
      element: <YorayReset />
    },
    {
      path: PATH.endTrial,
      element: <EndTrial />
    },
    {
      path: PATH.managementPanel.billing,
      element: <Navigate to={handleRedir(PATH.managementPanel.billing)} />,
    },
    {
      path: PATH.managementPanel.upcampaign,
      element: <Navigate to={handleRedir(PATH.managementPanel.upcampaign)} />,
    },
    {
      path: "*",
      element: <Navigate to={handleRedir(pathname + search)} />,
    },
    {
      path: "/",
      element: <Login />
    },
    {
      path: PATH.purchaseComplete.payoneer,
      element: <PurchaseComplete isPayoneer={true} />,
    },
  ];

  const noSubscriptionRoutes = [
    {
      path: PATH.alreadyTrial,
      element: <AlreadyTrial />
    },
    {
      path: PATH.root,
      element: <NoSubscription />
    },
    {
      path: PATH.maintenance,
      element: <Maintenance />
    },
    {
      path: "*",
      element: <NoSubscription />,
    },
    {
      path: PATH.purchaseComplete.payoneer,
      element: <PurchaseComplete isPayoneer={true} />,
    },
  ]

  const protectedRoutes = [
    {
      path: PATH.root,
      element: <Navigate to={PATH.dashboard.root} />,
    },
    {
      path: PATH.maintenance,
      element: <Maintenance />
    },
    {
      path: PATH.logout,
      element: <Logout />,
    },
    {
      path: PATH.index.failure,
      element: <PurchaseFailed />
    },
    {
      path: PATH.login,
      element: <Navigate to={PATH.dashboard.root} />,
    },
    {
      path: PATH.registration,
      element: <Navigate to={PATH.dashboard.root} />,
    },
    {
      path: PATH.auth.autods,
      element: <AutoDs />,
    },
    {
      path: PATH.upgrade,
      element: <Upgrade isUpgrade={false} />,
    },
    {
      path: PATH.processing,
      element: <Processing />,
    },
    {
      path: PATH.alreadyTrial,
      element: <AlreadyTrial />,
    },
    {
      path: PATH.root,
      element: <DashboardLayout />,
      children: [
        {
          path: PATH.dashboard.root,
          element: <NewDashboard />,
        },
        {
          path: PATH.productResearch.root,
          element: <ProductResearchFilters />,
        },
        {
          path: PATH.productResearch.ebay,
          element: <ProductResearchEbay />,
        },
        {
          path: PATH.productResearch.aliexpress,
          element: <ProductResearchAliexpress />,
        },
        {
          path: PATH.competitorResearch.root,
          element: <CompetitorResearchFilters />,
        },
        {
          path: PATH.competitorResearch.aliexpress,
          element: <CompetitorResearchAliexpress />,
        },
        {
          path: PATH.competitorResearch.ebay,
          element: <CompetitorResearchEbay />,
        },
        {
          path: PATH.categoryResearch.root,
          element: <CategoryResearch />,
        },
        {
          path: PATH.categoryResearch.results,
          element: <CategoryResearchResults />,
        },
        {
          path: PATH.titleBuilder.root,
          element: <TitleBuilder />,
        },
        {
          path: PATH.titleBuilder.results,
          element: <TitleBuilderResults />,
        },
        {
          path: PATH.zikPro.root,
          element: <ZikPro />,
        },
        {
          path: PATH.zikPro.bulkScanner.items,
          element: <BulkScannerItems />,
        },
        {
          path: PATH.zikPro.turboScanner.items,
          element: <TurboScannerItem />,
        },
        {
          path: PATH.zikPro.autopilotscan.item,
          element: <AutopilotScan />,
        },
        {
          path: PATH.myCompetitors,
          element: <MyCompetitors />,
        },
        {
          path: PATH.myProducts.root,
          element: <MyProducts />,
        },
        {
          path: PATH.myProducts.item,
          element: <MyProductsItem />,
        },
        {
          path: PATH.academy,
          element: <Academy />,
        },
        {
          path: PATH.tutorials,
          element: <Tutorials />,
        },
        {
          path: PATH.quickSettings.root,
          element: <QuickSettings />,
        },
        {
          path: PATH.managementPanel.root,
          element: <ManagementPanel />,
        },
        {
          path: PATH.managementPanel.billing,
          element: <Billing />
        },
        {
          path: PATH.managementPanel.upcampaign,
          element: <Billing />
        },
        {
          path: PATH.store.item,
          element: <StoreAnalytics />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />
    },
    {
      path: PATH.purchaseComplete.paypal,
      element: <PurchaseComplete isPaypal={true} />,
    },
    {
      path: PATH.purchaseComplete.payoneer,
      element: <PurchaseComplete isPayoneer={true} />,
    },
    {
      path: PATH.purchaseComplete.upgrade,
      element: <PurchaseComplete isUpgrade={true} />,
    },
    {
      path: PATH.purchaseComplete.downgrade,
      element: <PurchaseComplete isDowngrade={true} />,
    },
    {
      path: PATH.purchaseComplete.changed,
      element: <PurchaseComplete isChanged={true} />,
    },
    {
      path: PATH.purchaseComplete.creditcard,
      element: <PurchaseComplete isCreditCard={true} />,
    },
  ];

  const affiliateRoutes = [
    {
      path: PATH.managementPanel.affiliateManager,
      element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
      path: PATH.root,
      element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
      path: PATH.login,
      element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
      path: PATH.logout,
      element: <Logout />,
    },
    {
      path: PATH.affiliateCreateAccount,
      element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
      path: PATH.root,
      element: <DashboardLayout />,
      children: [
        {
          path: PATH.managementPanel.root,
          element: <AffiliateManager />,
        },
        {
          path: PATH.managementPanel.affiliateManager,
          element: <AffiliateManager />,
        },
        {
          path: PATH.quickSettings.root,
          element: <QuickSettings />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />
    },

  ];


  return useRoutes(isLoggedIn ? (isAffiliate ? affiliateRoutes : (userDetails?.package_name === "" && userDetails?.journey_completed.toLowerCase() === "true") ? noSubscriptionRoutes : protectedRoutes) : authRoutes);
};

export default Router;


