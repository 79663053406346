import { yupResolver } from "@hookform/resolvers/yup";
import EarEmoji from "assets/images/ear-emoji.png";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSaveFeedbackMutation } from "store/api/onboardingApi";
import { object, string } from "yup";

interface Props {
    contStyles: string;
    goNextStep: () => void
}
interface FormValues {
    feedback: string
}

let schema = object({
    feedback: string().required("Please provide feedback"),
});


const GetFeedback: FC<Props> = ({ contStyles, goNextStep }) => {
    const [saveFeedback, { isLoading }] = useSaveFeedbackMutation()
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormValues> = async (values) => {
        saveFeedback(values.feedback).unwrap().then(goNextStep).catch(() => toast.error("Something went wrong"))
    }

    return (
        <form className={`${contStyles} !mb-0`}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off">
            <div className="w-full">
                <img src={EarEmoji} alt="" className="w-[45px] h-[45px] max-sm:w-[45px] max-sm:h-[45px] max-lg:w-[55px] max-lg:h-[55px] mx-auto mb-[25px] max-lg:mb-[22px]" />
                <h2 className="text-xl font-bold text-center mb-2.5 max-lg:mb-[38px] max-lg:text-2xl max-sm:text-xl">
                    How did your hear about us?
                </h2>
                <div className="w-full mb-[53px] max-lg:mb-[76px]">
                    <ControlledInput<FormValues>
                        placeholder="Type here"
                        name="feedback"
                        register={register}
                        erase
                        getValues={watch}
                        clearInputValue={() => setValue("feedback", "")}
                        className="h-[47px] max-lg:h-[57px] !pb-0"
                        fontSize="text-base"
                        errorClassName="absolute"
                        errors={errors}
                        maxLength={80}
                    />
                </div>
            </div>
            <Button title="Next" height="h-16 max-sm:h-14" className={`max-w-[200px] max-lg:max-w-[350px] mb-0`} type="submit" loading={isLoading} />
        </form>
    )
}

export default GetFeedback