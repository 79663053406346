import { FC } from "react";

import InfoTooltip from "components/Card/InfoTooltip";
import Checkbox from "components/Input/Checkbox";

interface Props {
  options?: {
    className?: string, value: string;
    text: string;
    toolTip?: string;
  }[];
  value: string | string[] | any;
  multiselect?: boolean;
  handleChange: (newValue: string | number) => void;
  className?: string;
  optionClassName?: string
  optionLabelClassName?: string
}

const FilterCheckbox: FC<Props> = ({ options, value, multiselect, handleChange, className, optionClassName, optionLabelClassName }) => (
  <div className={`flex flex-col gap-[7px] ${className || ""}`}>
    {options?.map((option) => {
      const isChecked = multiselect
        ? Boolean(value?.includes(option.value))
        : option.value === value;

      return (
        <div className={`flex items-center gap-[5px] ${option.className || ""} ${optionClassName || ""}`}
          key={option.value.toString()}
        >
          <label
            htmlFor={option.value.toString()}
            className={`flex items-center text-sm justify-between cursor-pointer border-2 rounded-full pl-5 py-[6.5px] pr-2 w-full dark:border-transparent ${isChecked
              ? "dark:bg-lilac400 bg-darkBlue border-darkBlue text-white dark:text-deepSpace900"
              : `bg-neutral2 border-neutral2 dark:hover:bg-purple500 dark:hover:border-purple500 hover:bg-primaryPurple hover:border-primaryPurple hover:text-white max-lg:hover:bg-neutral2
              max-lg:hover:border-neutral2 max-lg:hover:text-darkBlue dark:bg-deepSpace800 dark:border-deepSpace800 dark:text-lilac400`
              } ${optionLabelClassName || ""}`}
          >
            <p>{option.text}</p>
            <Checkbox
              checked={isChecked}
              value={option.value}
              id={option.value}
              checkboxClassName={isChecked ? "hover:border-darkBlue dark:border-purple500" : "dark:!bg-deepSpace800 hover:dark:border-deepSpace800"}
              handleChange={() => handleChange(option.value)}
            />
          </label>
          {option.toolTip &&
            <InfoTooltip title={option.toolTip} />
          }

        </div>
      );
    })}
  </div>
);
export default FilterCheckbox;
