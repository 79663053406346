import React from "react";
import InvoiceContent from "./InvoiceContent";
import InvoicePaymentContent from "./InvoicePaymentContent";

interface Props {
  id: string;
  invoiceType: string | null;
  paymentType?: string | null;
}
const InvoiceToPrint = React.forwardRef(
  (props: Props, ref: React.LegacyRef<HTMLDivElement>) => (
    <div ref={ref} className="bg-white mx-auto px-6 pt-6">
      {
        props.invoiceType === "payment" ?
          (<InvoicePaymentContent id={props.id} paymentType={props.paymentType} />)
          :
          (
            <InvoiceContent id={props.id} />
          )
      }
    </div>
  )
);

export default InvoiceToPrint;
