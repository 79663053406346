import Loading from "components/Loading";
import Tooltip from "components/Tooltip";
import { FC, ReactNode, useId } from "react";
import { Link, To } from "react-router-dom";

interface Props {
  className?: string;
  color?: keyof typeof colors;
  title: string;
  loading?: boolean;
  tooltipTitle?: ReactNode | string;
  tooltipBg?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  StartIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  variant?: "outlined" | "contained";
  handleClick?: (value: any) => void;
  height?: string;
  type?: "submit" | "button" | "reset";
  isLink?: boolean;
  disabled?: boolean;
  iconClassName?: string;
  loadingTitle?: string;
  noHover?: boolean;
  titleClassName?: string;
  loadingClassName?: string;
  contClassName?: string
  redirect?: To;
  target?: "_blank" | "_parent" | "_self" | "_top";
  value?: any;
}

interface ContentProps extends Props {
}

const colors = {
  primaryPurple: {
    contained: "",
    outlined:
      "text-darkBlue dark:text-lilac400 border-darkBlue hover:text-white hover:bg-primaryPurple dark:hover:bg-purple500 dark:hover:border-purple500 hover:border-primaryPurple dark:border-lilac400",
    icon: "fill-darkBlue dark:fill-lilac400 group-hover:fill-white dark:group-hover:fill-lilac400",
    iconContained: "fill-white ",
    loadingClassName: "fill-primaryPurple dark:fill-purple500",
  },
  darkBlue: {
    contained: "text-darkBlue dark:text-lilac400 border-darkBlue text-white bg-darkBlue",
    outlined:
      "text-darkBlue dark:text-lilac400 border-darkBlue hover:text-white hover:bg-darkBlue",
    icon: "fill-darkBlue dark:fill-lilac400 group-hover:fill-white dark:group-hover:fill-lilac400",
    iconContained: "fill-white",
    loadingClassName: "fill-darkBlue dark:fill-lilac400",
  },
  smoothGreen: {
    contained: "",
    outlined:
      "text-darkBlue dark:text-lilac400 border-smoothGreen hover:text-white dark:hover:text-darkBlue hover:bg-smoothGreen dark:border-paradiseBlue dark:hover:bg-paradiseBlue",
    icon: "fill-smoothGreen dark:fill-paradiseBlue group-hover:fill-white dark:group-hover:fill-deepSpace900",
    iconContained: "fill-white",
    loadingClassName: "fill-smoothGreen dark:fill-paradiseBlue",
  },
  disabledGrey: {
    contained: "bg-neutral3 border-neutral3 text-white",
    outlined:
      "text-darkBlue dark:text-lilac400 border-neutral2 bg-neutral2 text-neutral3",
    icon: "fill-neutral3",
    iconContained: "fill-white",
    loadingClassName: "fill-neutral3",
  }
};

const ButtonContent: FC<ContentProps> = (props) => {
  const anchorId = useId();
  const {
    title,
    color = "primaryPurple",
    variant = "outlined",
    StartIcon,
    loading,
    tooltipTitle,
    tooltipBg,
    Icon,
    iconClassName,
    loadingTitle,
    loadingClassName,
    titleClassName,
    contClassName,
  } = props;

  const iconStyles = `${iconClassName || ""}  ${colors[color][variant === "contained" ? "iconContained" : "icon"]}`;

  return (
    <div
      className={`w-full h-full justify-center flex flex-row items-center gap-3.5 ${contClassName || ""}`}
      data-tooltip-id={anchorId}
    >
      {loading ? (
        <div className="flex items-center gap-2">
          <p className={`text-sm font-bold ${titleClassName || ""}`}>
            {loadingTitle}
          </p>
          <Loading
            fill={`${colors[color].loadingClassName}e ${loadingClassName || ""}`}
          />
        </div>
      ) : (
        <>
          {StartIcon ? <StartIcon className={iconStyles} /> : ""}
          <p className={`text-sm font-medium ${titleClassName || ""}`}>{title}</p>
          {Icon ? <Icon className={iconStyles} /> : ""}
        </>
      )}
      {tooltipTitle && (
        <Tooltip
          title={tooltipTitle}
          anchorId={anchorId}
          bg={tooltipBg || "bg-darkBlue"}
        />
      )}
    </div>
  );
};
const Button: FC<Props> = (props) => {
  const {
    className,
    color = "primaryPurple",
    variant = "outlined",
    loading,
    disabled,
    height,
    type,
    redirect,
    target,
    isLink,
    handleClick,
    value,
  } = props;
  const style = `btn ${disabled ? colors.disabledGrey.contained + " " + className : colors[color][variant] + " " + className} ${height || "h-[51px]"
    } border-2 rounded-full w-full group`;

  return isLink ? (
    <Link to={redirect || ""} target={target} className={style}>
      <ButtonContent {...props} />
    </Link>
  ) : (
    <button
      className={style}
      value={value}
      onClick={() => {
        handleClick?.(value);
      }}
      disabled={disabled || loading}
      type={type || "button"}
    >
      <ButtonContent {...props} />
    </button>
  );
};

export default Button;
