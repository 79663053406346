import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as Check } from "assets/icons/checkmark.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";
import { PlanComparisonItemType } from "types/plans";


interface Props {
  item: PlanComparisonItemType;
  hasBottomDivider: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
}

const getIcon = (icon: string) => {
  switch (icon) {
    case "dashboard":
      return <Logo className="w-[14px]" />;
    case "product":
      return <ProductResearch className="w-[12px] fill-primaryPurple dark:fill-purple500" />;
    case "competitor":
      return <CompetitorResearch className="fill-primaryPurple dark:fill-purple500 w-[14px]" />;
    case "category":
      return <CategoryResearch className="fill-primaryPurple dark:fill-purple500 w-[14px]" />;
    case "titleBuilder":
      return <TitleBuilder className="fill-primaryPurple dark:fill-purple500 w-[14px]" />;
    case "itemFinder":
      return <ItemFinder className="fill-primaryPurple dark:fill-purple500 w-[14px]" />;
    case "proTools":
      return <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[14px]" />;
    default:
      return null;
  }
};

const includeInPlan = (include: boolean) =>
  include ? (
    <div className="w-6 h-6 bg-smoothGreen dark:bg-paradiseBlue rounded-full flex justify-center items-center">
      <Check className="fill-white" />
    </div>
  ) : (
    <div className="w-6 h-6 bg-secondary rounded-full flex justify-center items-center">
      <Close className="fill-white" />
    </div>
  );

const PlansComparisonItem = ({ item, selectedPlan, hasBottomDivider }: Props) => (
  <>
    <div className="bg-white">
      <div className="grid grid-cols-4 max-[965px]:grid-cols-6">
        <div className="bg-white px-[8px] py-2.5 max-[965px]:col-span-4">
          <div className="flex items-center gap-[5px] mb-[5px]">
            <div className="w-6 h-6 flex justify-center items-center">
              <div>{getIcon(item.icon)}</div>
            </div>

            <div className="text-sm font-bold text-darkBlue">
              {item.title}
            </div>
          </div>
          <div
            className="text-[12px] font-medium"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>

        <div className={`bg-neutral1 flex justify-center items-center md:border-r-2 border-secondary max-[965px]:col-span-2 ${selectedPlan === "pro" ? "" : "max-[965px]:hidden"}`}>
          {includeInPlan(item.pro)}
        </div>
        <div className={`bg-neutral1 flex justify-center items-center md:border-r-2 border-secondary  max-[965px]:col-span-2 ${selectedPlan === "pro-plus" ? "" : "max-[965px]:hidden"}`}>
          {includeInPlan(item.proPlus)}
        </div>
        <div className={`bg-neutral1 flex justify-center items-center max-[965px]:col-span-2 ${selectedPlan === "enterprise" ? "" : "max-[965px]:hidden"}`}>
          {includeInPlan(item.enterprise)}
        </div>
      </div>
      {hasBottomDivider && (
        <div className="h-[2px] ml-[7px] bg-secondary rounded-full w-[calc(100%-7px)]" />
      )}
    </div>
  </>
);

export default PlansComparisonItem;
