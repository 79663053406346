import Card from "components/Card";
import AffiliateTabs from "components/SettingsTabs/Index";
import AffiliateTab from "components/SettingsTabs/SettingsTab";
import Toaster from "components/Toast";
import useTabs from "hooks/useTabs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AdvertisingChannels from "../affiliateTabs/AdvertisingChannels";
import Earnings from "../affiliateTabs/Earnings";
import PaymentHistory from "../affiliateTabs/PaymentHistory";
import ReferralStatistics from "../affiliateTabs/ReferralStatistics";
import Invoice from "../components/Invoice";

enum TABS {
  earnings = "earnings",
  referralStatistics = "referral-statistics",
  paymentHistory = "payment-history",
  advertisingChannels = "advertising-channels",
}

const tabContent = {
  [TABS.earnings]: {
    content: <Earnings />,
  },
  [TABS.referralStatistics]: {
    content: <ReferralStatistics />,
  },
  [TABS.paymentHistory]: {
    content: <PaymentHistory />,
  },
  [TABS.advertisingChannels]: {
    content: <AdvertisingChannels />,
  },
};

const AffiliateManager = () => {
  const { activeTab, onTabChange } = useTabs<TABS>(TABS.earnings, true);
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [paymentType, setPaymentType] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {  
    if (searchParams.get("invoice")) {    
      setInvoiceId(searchParams.get("invoice"));
      setPaymentType(searchParams.get("paymentType"))
    } else {
      setInvoiceId(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("invoice")]);
  return (
    <>
    
    {invoiceId && process.env.REACT_APP_ENVIRONMENT === 'affiliate'? (
      <Invoice id={invoiceId} paymentType={paymentType} />
    ) : (
      <Card className="pt-[25px] pb-7 px-[30px]">
      <AffiliateTabs
        className="max-w-[622px] w-full m-auto mb-[22px] max-[991px]:flex-col max-[991px]:rounded-[10px] h-[50px] p-[2px]"
        tabs={
          <>
            <AffiliateTab
              title="Earnings"
              isActive={activeTab === TABS.earnings}
              handleClick={() => onTabChange(TABS.earnings)}
              divider
              dividerClassName="max-[991px]:hidden"
              className="h-[46px] max-w-[124px]"
            />

            <AffiliateTab
              title="Referral Statistics"
              isActive={activeTab === TABS.referralStatistics}
              handleClick={() => onTabChange(TABS.referralStatistics)}
              divider
              dividerClassName="max-[991px]:hidden"
              className="h-[46px]  max-w-[158px]"
            />

            <AffiliateTab
              title="Payment History"
              isActive={activeTab === TABS.paymentHistory}
              handleClick={() => onTabChange(TABS.paymentHistory)}
              divider
              dividerClassName="max-[991px]:hidden"
              className="h-[46px] max-w-[152px]"
            />

            <AffiliateTab
              title="Advertising Channels"
              isActive={activeTab === TABS.advertisingChannels}
              handleClick={() => onTabChange(TABS.advertisingChannels)}
              className="h-[46px] max-w-[184px]"
            />
          </>
        }
      />

      {tabContent[activeTab].content}
      <Toaster />
    </Card>
    )}
    </>
  );
};

export default AffiliateManager;
