import Button from "components/Button";
import { PlansType, TSelectedPlan } from "types/plans";
import {
  getPlanSuffix,
  isCurrentPlan,
  isDowngrade,
  isUpgrade
} from "utils/planUtils";

interface Props {
  plans: PlansType;
  currentPlan: string;
  paymentStatus?: number;
  isTrial?: boolean;
  showCheckout?: () => void;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  isFooterDark?: boolean
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  footerBg?: string;
  fromNoSubscription?: boolean;
}

const PlanComparisonFooter = ({
  plans,
  currentPlan,
  isTrial,
  paymentStatus,
  selectedPlan,
  isFooterDark,
  showCheckout,
  handleSelectPlan,
  fromRegistration = false,
  footerBg,
  fromNoSubscription = false
}: Props) => (
  <div className="max-w-[1220px] w-full">
    <div className={`grid grid-cols-4 max-[965px]:grid-cols-1`}>
      <div />


      {plans.planList.map((plan, i) =>
        plan.type === currentPlan ? (
          <div key={i} className="relative">
            <div className="bg-secondary px-[61px] pt-[28px] pb-[31px] rounded-b-[15px] text-sm font-medium w-[calc(100%-2px)]">
              This is your Current Plan
            </div>
            {plan.type.includes("Enterprise") ? (
              <div className="absolute bg-secondary  w-full h-[50px] bottom-[100%] left-[-2px]" />
            ) : ""}
          </div>
        ) : (
          <div key={i} className={` ${plan.planId !== selectedPlan ? "max-[965px]:hidden" : "max-[965px]:border-2 max-[965px]:border-t-0 max-[965px]:border-smoothGreen max-[965px]:rounded-b-[15px] max-[965px]:max-w-[262px] max-[965px]:w-full max-[965px]:mx-auto"}`}>
            <div
              className={`${isFooterDark ? "bg-darkBlue" : (plan.type.includes("Standard") ||
                plan.type.includes("ProPlus")) ? "bg-neutral1" : footerBg ? footerBg : "bg-neutral2"} rounded-b-[15px] py-2 w-[calc(100%-2px)] mx-auto md:px-2 max-[965px]:px-0 relative ${(plan.type.includes("Standard") ||
                  plan.type.includes("ProPlus")) &&
                "border-[2px] border-t-0 border-smoothGreen"
                } ${plan.planId === selectedPlan ? " max-[965px]:border-none" : ""}`}
            >
              {plan.popularBadge && (
                <div className={`max-w-[81px] w-full h-[22px] capitalize text-[10px] rounded-full flex items-center justify-center font-medium absolute right-[19px] max-[1125px]:right-[10px] top-[13px] ${isFooterDark ? "bg-white text-primaryPurple dark:text-purple500" : " bg-darkBlue text-white "}`}>
                  most popular
                </div>
              )}

              <div className={`${isFooterDark ? "text-white" : "text-darkBlue"}  text-center font-bold text-xl uppercase`}>
                {plan.planName}
              </div>
              <div className={`${isFooterDark ? "text-white font-medium" : "text-darkBlue font-bold"} flex justify-center items-center `}>
                {plan.isDiscount ? <span>${plan.planDiscountPrice}</span> : <span>${plan.planPrice}</span>}

                <span>{getPlanSuffix(plan.type)}</span>
              </div>
              {plans.period === "monthly" && (
                <span className={` ${isFooterDark ? "text-neutral3" : "text-darkBlue "} block text-[10px] text-center font-medium`}>
                  for 1st month
                </span>
              )}
              {isDowngrade(currentPlan, plan.type) ? (
                <Button
                  className={`max-w-[206px] mt-[7px] w-full flex mx-auto border-smoothGreen bg-transparent  hover:bg-smoothGreen hover:border-smoothGreen`}
                  titleClassName="!font-bold uppercase group-hover:text-white text-white"
                  color="smoothGreen"
                  height="h-9"
                  title={
                    isTrial
                      ? "Change"
                      : fromRegistration ? "Start $1 Trial Now"
                        : paymentStatus === 0
                          ? "Pay now"
                          : "Upgrade now"
                  }
                  handleClick={() => {
                    handleSelectPlan?.({
                      type: plan.type,
                      planName: plan.planName,
                      planPrice: plan.planPrice,
                      skip: false
                    });
                    showCheckout && showCheckout()
                  }}
                />
              ) : isCurrentPlan(currentPlan, plan.type) ? (
                <div className="max-w-[210px] w-full mx-auto">
                  <div className="mb-[15px] text-center text-electricBlue text-[14px] font-medium">
                    This is your Current Plan
                  </div>
                  <div className="h-[2px] w-full bg-secondary" />
                </div>
              ) : isUpgrade(currentPlan, plan.type) ? (
                <div>
                  <Button
                    className={`camelcase font-bold max-w-[206px] mt-[7px] flex w-full mx-auto border-smoothGreen  hover:border-smoothGreen ${(plan.type.toLowerCase().includes("standard") ||
                      plan.type.toLowerCase().includes("proplus")) || !isFooterDark ?
                      "!bg-smoothGreen hover:!text-darkBlue" : "bg-transparent hover:bg-smoothGreen "
                      }`}
                    height="h-9"
                    color="smoothGreen"
                    titleClassName={`!font-bold uppercase group-hover:text-white text-white`}
                    title={
                      isTrial
                        ? "Change"
                        : fromRegistration ? "Start $1 Trial Now"
                          : paymentStatus === 0
                            ? "Pay now"
                            : "Upgrade now"
                    }
                    handleClick={() => {
                      handleSelectPlan?.({
                        type: plan.type,
                        planName: plan.planName,
                        planPrice: plan.planPrice,
                        skip: false
                      });
                      showCheckout && showCheckout()
                    }}
                  />
                  {fromRegistration && plan.skipAmout && <div className="flex justify-center items-center ">
                    <button
                      className="text-sm cursor-pointer text-electricBlue hover:text-primaryPurple dark:hover:text-purple500 underline"
                      onClick={() => {
                        handleSelectPlan?.({
                          type: plan.type,
                          planName: plan.planName,
                          planPrice: plan.planPrice,
                          skip: true
                        });
                        showCheckout && showCheckout()
                      }}
                    >
                      Skip Trial and Save ${plan.skipAmout}
                    </button>
                  </div>}
                </div>
              ) : null}

              {isFooterDark ? (
                <div className="absolute bg-darkBlue  w-full h-[50px] bottom-[100%] left-0" />
              ) : ""}
            </div>
          </div>
        )
      )}
    </div>
  </div>
);

export default PlanComparisonFooter;
