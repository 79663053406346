import Button from "components/Button";
import { useState } from "react";

interface Props {

    handleSubmit: (val: string) => void;
}

const Other = ({ handleSubmit }: Props) => {
    const [inputVal, setInputVal] = useState("");

    const handleChange = (event: any) => {
        setInputVal(event.target.value);
    }
    return (
        <div className="flex flex-col items-center">
            <h3 className="text-center text-xl font-semibold">Please, tell us why you are leaving.</h3>
            <p className="text-center text-xl mb-[9px]">Your feedback help us improve!</p>

            <div className="max-w-[475px] w-full mb-[32px]">
                <input placeholder="Type here" onChange={handleChange} className="w-full h-[56px] placeholder:text-neutral3 text-[16px] py-[10px] border-b-[3px] border-secondary hover:border-primaryPurple focus:border-primaryPurple" />
            </div>
            <Button title="Submit" height="h-16" className="border-darkBlue text-darkBlue max-w-[214px]" titleClassName="!text-base font-medium" handleClick={() => { handleSubmit(inputVal) }} />

        </div>
    )
}

export default Other