import Button from "components/Button";
import Card from "components/Card";
import PricingUsesCounter from "components/Plans/PricingUsesCounter";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import PaymentHistory from "pages/ManagementPanel/lists/ManagePayments/PaymentHistory";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { handleBootIntercom } from "store/slices/intercomSlice";
import { formatDate } from "utils/formatTime";
import { getPlanFullSuffix, getPlanName } from "utils/planUtils";
import CancelationFunnel from "../CancelationFunnel";
interface Props {
  currentPlan: string;
  paymentMethod: string;
  paymentStatus: number;
  renewalDate: string;
  isCanceled?: boolean;
  isTrial?: boolean;
}

const Index = ({
  currentPlan,
  paymentMethod,
  paymentStatus,
  renewalDate,
  isCanceled,
  isTrial,
}: Props) => {
  const [cookie] = useCookies(["upCampaign"])
  const modal = useModal()
  const { t } = useTranslation(['home']);
  const user = useDecodeToken()
  const dispatch = useAppDispatch();
  const isLTplan = getPlanFullSuffix(currentPlan) === "Lifetime";
  const navigate = useNavigate();
  const hideSpecialOffer = user.package_name.includes("LT") || user.package_name.includes("EnterpriseY") || user.package_name.includes("StandardY")
  const goToBilling = () => navigate(PATH.managementPanel[cookie.upCampaign === "true" ? "upcampaign" : "billing"])

  return (
    <>
      <div className={`flex gap-3 mb-6 flex-col lg:flex-row ${paymentStatus === 0 || isCanceled ? "dark:text-grey600" : "dark:text-grey100"}`}>
        <Card className="max-sm:p-4 px-[52px] py-[35px] grow basis-0 border-[2px] border-secondary dark:border-transparent" bg="dark:bg-grey800 bg-white">
          <div className="font-bold mb-[10px]">My Subscription</div>
          <div className="text-[28px] mt-[45px] mb-[37px] ">
            <span className="font-bold uppercase">{getPlanName(currentPlan)} </span>
            <span className="font-medium lowercase">/ {getPlanFullSuffix(currentPlan)}</span>
          </div>
          <div className="flex items-center justify-between max-[1250px]:flex-col max-[1250px]:items-start max-[1250px]:gap-2.5">
            <div>
              <div className="text-[14px]">
                <span className="font-bold">{t('RenewalDate')} :</span>{" "}
                {formatDate(renewalDate, "DD MMM YYYY")}
              </div>
              <div className="text-[14px]">
                <span className="font-bold">{t('PaymentMethod')} : </span>
                <span className="capitalize">
                  {paymentMethod.replace("_", " ").toLowerCase()}
                </span>
              </div>
              <div className="text-[14px]">
                <span className="font-bold">{t('SubscriptionStatus')} : </span>
                {isCanceled === undefined ? "" : paymentStatus === 0 || isCanceled ? (
                  <span className="text-errorRed">{t('Canceled')}</span>
                ) : (
                  <span className="text-success">Active</span>
                )}
              </div>
            </div>

          </div>
        </Card>

        <Card className="px-[52px] py-[35px] max-sm:p-4 grow basis-0 border-2 border-secondary dark:border-0  bg-[linear-gradient(226deg,_#9E7EF6_0%,_#6831FF_39.76%,_#6831FF_69.11%,_#BEA6FF_100%)]">
          <p className="text-[20px] leading-[28px] text-white mb-[16px]">Grab 50% Off Yearly Plans<br></br>
            Limited 200 sign-ups</p>
          <div className="mb-[53px]">
            <PricingUsesCounter />
          </div>

          {/*    <p className=" bg-darkBlue dark:bg-lilac400 dark:text-deepSpace900 p-2.5 rounded-[10px] w-fit text-white text-[20px] font-bold mb-[15px]">
            {hideSpecialOffer ? "Upgrade your Plan to Unlock NEW Features" :
              <span className="flex items-center gap-2.5">
                <span>
                  Upgrade your Plan to Unlock NEW Features
                </span> <img src={Gift} alt="Gift" className="w-[26px] h-[26px]" />
              </span>}


          </p> */}
          {/* {!isLTplan ?
            (<div className="text-white text-[20px] mb-[67px] font-normal dark:text-grey100">

              Save <span className="font-bold">up to 2 Months</span> with our <span className="font-bold">Yearly plan</span>
            </div>) : (<div className="text-white text-[20px] mb-[67px] font-normal" />)
          } */}

          <div className="flex items-center max-sm:flex-col gap-[35px]">
            <Button
              title={
                isTrial
                  ? "CHANGE NOW"
                  : paymentStatus === 0
                    ? "PAY NOW"
                    : "UPGRADE NOW"
              }
              color="smoothGreen"
              titleClassName="dark:text-lilac400 dark:!font-medium !text-base !font-bold group-hover:text-darkBlue text-white"
              height="h-[60px]"
              className={`!max-w-[193px] bg-smoothGreen dark:bg-deepSpace900
              shadow-[0px_0px_1px_0px_#8FFFF2,0px_0px_2px_0px_#8FFFF2,0px_0px_7px_0px_#8FFFF2,0px_0px_14px_0px_#8FFFF2,0px_0px_24px_0px_#8FFFF2,0px_0px_43px_0px_#8FFFF2] dark:shadow-[0px_0px_1.861px_0px_#20E2CC,0px_0px_3.723px_0px_#20E2CC,0px_0px_13.029px_0px_#20E2CC,_0px_0px_26.058px_0px_#20E2CC,0px_0px_44.671px_0px_#20E2CC,0px_0px_78.174px_0px_#20E2CC]`}
              handleClick={goToBilling}
            />
            {paymentStatus === 0 || isCanceled ? "" :
              <Button
                color="smoothGreen"
                title={isLTplan ? "Talk To Support" : "Cancel"}
                titleClassName="text-white dark:text-darkBlue dark:font-bold group-hover:text-darkBlue"
                className="max-w-[183px] border-white dark:!border-lilac400 dark:hover:!border-paradiseBlue bg-transparent hover:border-white dark:bg-lilac400  dark:hover:bg-paradiseBlue"
                height="h-[60px]"
                handleClick={() => isLTplan ? dispatch(handleBootIntercom(true)) : modal.open()}
              />
            }
          </div>

        </Card>
      </div>
      {paymentStatus === 0 || isCanceled ? "" :
        <CancelationFunnel helpers={modal} onUpgrade={goToBilling} />
      }
      <Toaster />
      <PaymentHistory />
    </>
  )
};

export default Index;
