import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";

const planFeatures = [{
  title: "Market Insights",
  Icon: <Logo className="w-[17px]" />
}, {
  title: "Product Research",
  Icon: <ProductResearch className="w-[15px] fill-primaryPurple dark:fill-purple500" />,
}, {
  title: "Competitor Research",
  Icon: <CompetitorResearch className="fill-primaryPurple dark:fill-purple500 w-[15px]" />
},
{
  title: "Category Research",
  Icon: <CategoryResearch className="fill-primaryPurple dark:fill-purple500 w-[15px]" />
},
{
  title: "Title Builder",
  Icon: <TitleBuilder className="fill-primaryPurple  dark:fill-purple500 w-[15px]" />
},
{
  title: "Item Finder",
  Icon:
    <ItemFinder className="fill-primaryPurple dark:fill-purple500 w-[15px]" />
},
]
const Pro = () => (
  <div className="flex flex-col gap-[3px] w-[185px] mx-auto">
    <div className="font-bold text-sm text-center">Key Benefits</div>
    {planFeatures.map((item, idx) => (
      <div className="flex gap-[5px] items-center" key={idx}>
        <div className="flex h-[30px] w-[30px] items-center justify-center">
          {item.Icon}
        </div>
        <p className="font-medium text-sm">{item.title}</p>
      </div>
    ))}
  </div>
);

export default Pro;
