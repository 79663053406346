import DebitCardActive from "assets/images/payment-methods/debit-card-active.png";
import DebitCard from "assets/images/payment-methods/debit-card.png";
import PayoneerActive from "assets/images/payment-methods/payoneer-active.png";
import Payoneer from "assets/images/payment-methods/payoneer.png";
import PayPalActive from "assets/images/payment-methods/paypal-active.png";
import PayPal from "assets/images/payment-methods/paypal.png";
import { useAppSelector } from "hooks/useAppSelector";

interface Props {
  type: "cc" | "pp" | "py";
  tab: string;
  handleClick: (type: string) => void;
}


const paymentMethods = {
  pp: {
    default: PayPal,
    active: PayPalActive,
    imgClassName: "w-[85px] h-[54px] max-sm:w-[81px] max-sm:h-[50px]",
  },
  py: {
    default: Payoneer,
    active: PayoneerActive,
    imgClassName: "w-[100px] h-[67px] max-sm:w-[101px] max-sm:h-[68px]",
  },
  cc: {
    default: DebitCard,
    active: DebitCardActive,
    imgClassName: "w-[25px] h-[25px] max-sm:w-8 max-sm:h-8",
  }

}

const PaymentTab = ({ type, handleClick, tab }: Props) => {
  const img = paymentMethods[type]
  const isActive = tab === type;
  const { isDarkMode } = useAppSelector((state) => state.themeSlice)

  return (
    <div
      className={`flex justify-center items-center transition-300 transition-all ${tab === "" ? "max-sm:h-14" : "max-sm:h-[45px]"}
      ${type === "cc" ? "min-w-[160px]" : ""} max-w-[173px] h-[45px] w-full rounded-10 border-2 cursor-pointer  
      ${isActive ? "bg-darkBlue text-white border-darkBlue  dark:bg-grey100 dark:text-deepSpace900 dark:border-grey100" : `${tab !== "" ? "max-sm:hidden" : "max-sm:max-w-[335px]"}
       hover:h-[70px]  hover:bg-secondary border-secondary bg-neutral1 dark:bg-deepSpace800 dark:border-deepSpace800 text-darkBlue dark:text-grey100 hover:shadow-[0px_4px_50px_10px_rgba(96,122,154,0.15)]`}`}
      onClick={() => {
        handleClick(type);
      }}
    >
      <div className="flex justify-center items-center gap-[5px]">
        <img src={img[isActive ? isDarkMode ? "default" : "active" : isDarkMode ? "active" : "default"]} alt="" className={paymentMethods[type].imgClassName} />
        {type === "cc" && (
          <div className="text-xs font-bold max-sm:text-[10px] max-sm:font-medium ">Credit or Debit Card</div>
        )}
      </div>
    </div >
  )
};

export default PaymentTab;
