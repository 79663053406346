const KEYS = {
  ACCESS: "access",
  REFRESH: "refresh",
  USER: "user",
  AUTO_DS_PAGE: "autoDsPage",
  SAVED_CITY: "savedCity",
  SAVED_LANG: "savedLang",
  HIDE_BANNER: "hideAdvancedToolsBanner",
  NY_BANNER: "nyBanner",
  NY_NOTIFICATION: "nyNotification",
  DARK_MODE: "darkMode",
};

export enum HISTORY_KEYS {
  prEbaySearch = "prEbaySearch",
  prAliSearch = "prAliSearch",
  crEbaySearch = "crEbaySearch",
  crAliSearch = "crAliSearch",
}

export const GetNyBanner = () =>
  localStorage.getItem(KEYS.NY_BANNER) === "true" ? true : false;
export const HideNyBanner = () => localStorage.setItem(KEYS.NY_BANNER, "true");
export const GetHideBanner = () =>
  localStorage.getItem(KEYS.HIDE_BANNER) === "true" ? true : false;

export const HideBanner = () => localStorage.setItem(KEYS.HIDE_BANNER, "true");

export const GetSavedCity = () => localStorage.getItem(KEYS.SAVED_CITY);

export const SetSavedCity = (newCity: string) =>
  localStorage.setItem(KEYS.SAVED_CITY, newCity);

export const GetSavedLang = () => localStorage.getItem(KEYS.SAVED_LANG);

export const SetSavedLang = (newLang: string) =>
  localStorage.setItem(KEYS.SAVED_LANG, newLang);

export const SetAccessToken = (token: string) =>
  localStorage.setItem(KEYS.ACCESS, token);

export const GetAccessToken = () => localStorage.getItem(KEYS.ACCESS);

export const SetRefreshToken = (token: string) =>
  localStorage.setItem(KEYS.REFRESH, token);

export const GetRefreshToken = () => localStorage.getItem(KEYS.REFRESH);

export const SetUserDetails = (user: object) =>
  localStorage.setItem(KEYS.USER, JSON.stringify(user));

export const SetAutoDsPage = (page: string) =>
  localStorage.setItem(KEYS.AUTO_DS_PAGE, page);

export const GetAutoDsPage = () => localStorage.getItem(KEYS.AUTO_DS_PAGE);

export const RemoveAutoDsPage = () =>
  localStorage.removeItem(KEYS.AUTO_DS_PAGE);

export const GetUserDetails = () => localStorage.getItem(KEYS.USER);

// Search History
export const GetHistory = (type: HISTORY_KEYS) =>
  JSON.parse(localStorage.getItem(HISTORY_KEYS[type]) || "[]");

export const SetHistory = (type: HISTORY_KEYS, newSearch: string) => {
  const list = [newSearch, ...GetHistory(type)];
  localStorage.setItem(HISTORY_KEYS[type], JSON.stringify(list));
};

export const RemoveHistoryItem = (
  type: HISTORY_KEYS,
  newSearch?: string,
  all?: boolean
) => {
  const list = GetHistory(type).filter((item: string) => newSearch !== item);
  localStorage.setItem(HISTORY_KEYS[type], JSON.stringify(all ? [] : list));
};
