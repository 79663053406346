
import { ReactComponent as LogoWithText } from "assets/icons/logo-with-text.svg";
import { ReactComponent as LoginPurple } from "assets/illustrations/login-purple.svg";
import { ReactComponent as LoginSmoothGreen } from "assets/illustrations/login-smooth-green.svg";
import { ReactComponent as LoginWhite } from "assets/illustrations/login-white.svg";
import Toaster from "components/Toast";
import { LANDING_PAGE_LINKS } from "config/constants";
import { FC, ReactNode } from "react";
interface Props {
    children: ReactNode;
}
const LoginLayout: FC<Props> = ({ children }) => (
    <>
        <div className="flex">
            <div className="flex flex-col max-lg:w-[56.5%] max-md:w-full w-[49.2%] py-[50px] max-xl:pt-3 relative z-10 bg-white min-h-screen h-full">
                <div className="flex max-sm:mb-[15px] max-md:mb-[56px] xl:pl-[70px] max-xl:pt-[30px] max-xl:justify-center max-w-[700px] w-full mx-auto"
                >
                    <a href={LANDING_PAGE_LINKS.root} className="cursor-pointer">

                        <LogoWithText className="max-sm:w-[156.5px]" />
                    </a>
                </div>
                <div className="flex flex-1 items-center justify-center max-md:items-start">
                    {children}
                </div>

            </div>
            <div className="w-[50.8%] max-lg:w-[43.5%] max-md:hidden overflow-hidden fixed top-0 right-0 h-full">
                <div className="">
                    <LoginPurple className="absolute -top-[100px] left-[15%] max-lg:-rotate-[180deg] max-lg:-left-[140px] max-lg:top-[40px]" />
                    <LoginSmoothGreen className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-lg:right-0 max-xl:w-[781px] max-lg:left-[52px] max-lg:translate-y-0 max-lg:translate-x-0 max-lg:-top-[150px]" />
                    <LoginWhite className="absolute right-[80px] bottom-[40px] fill-white max-lg:-left-[10%] max-lg:right-0 max-lg:-bottom-[80px] max-xl:bottom-[-40px]" />
                </div>
                <div className="h-full flex items-center justify-center font-bold">
                    <h1 className="text-[48px] z-10">
                        When <br /> eCommerce <br />
                        Works!
                    </h1>
                </div>
            </div>
        </div>
        <Toaster />
    </>

)

export default LoginLayout