import { createSlice } from "@reduxjs/toolkit";
import { crApi } from "store/api/crApi";
import {
  crAliData,
  crAliFilters,
  crEbayData,
  crEbayFilters,
} from "store/initialValues/cr";
import {
  PostCrAliRequest,
  PostCrAliResponse,
  PostCrEbayRequest,
  PostCrEbayResponse,
} from "types/services/cr";

export interface CrFilterChangePayload {
  marketplace: "aliFilters" | "ebayFilters";
  field: keyof typeof crAliFilters | keyof typeof crEbayFilters;
  newVal: string | number | null;
}

export const initialState = {
  aliFilters: crAliFilters as PostCrAliRequest,
  ebayFilters: crEbayFilters as PostCrEbayRequest & {
    searchedSelectedPeriod: number;
  },
  ebayData: { result: crEbayData, success: false } as PostCrEbayResponse,
  aliData: { result: crAliData, success: false } as PostCrAliResponse,
};

const crSlice = createSlice({
  name: "crSlice",
  initialState,
  reducers: {
    handleCrFilterChange: (
      state,
      { payload }: { payload: CrFilterChangePayload }
    ) => {
      // @ts-ignore
      state[payload.marketplace][payload.field] = payload.newVal;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      crApi.endpoints.crEbay.matchFulfilled,
      (state, { payload }) => {
        state.ebayData = payload;

        return state;
      }
    );
    builder.addMatcher(
      crApi.endpoints.crAli.matchFulfilled,
      (state, { payload }) => {
        state.aliData = payload;

        return state;
      }
    );
  },
});

export const { handleCrFilterChange } = crSlice.actions;

export default crSlice.reducer;
