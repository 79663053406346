import { FC } from "react";
import { formatCurrency, formatPercent } from "utils/formatCurrency";

interface Props {
  title: string;
  amount: number;
  isPercentage?:boolean;
}
export const EarningsRow: FC<Props> = ({ title, amount,isPercentage }) => (
  <div className="flex justify-between !text-sm">
    <h6>{title}</h6> <p className="font-bold">{isPercentage ? formatPercent(amount) : formatCurrency(amount)}</p>
  </div>
);
export const EarningsRowNumeral: FC<Props> = ({ title, amount }) => (
  <div className="flex justify-between !text-sm">
    <h6>{title}</h6> <p className="font-bold">{amount}</p>
  </div>
);

//export default EarningsRow;
