import Card from "components/Card";
import Search from "components/Search";
import usePagination from "hooks/usePagination";
import { FC, useEffect, useState } from "react";

// Icons
import { crEbayCols } from "assets/tableColumns/crEbay";
import IconFinder from "components/IconFinder";
import ScrollToTop from "components/ScrollToTop";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { useAppSelector } from "hooks/useAppSelector";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { useCrEbayMutation } from "store/api/crApi";
import { crEbayFilters } from "store/initialValues/cr";
import { CrEbayList } from "types/services/cr";
import { IconFinderItem } from "types/services/iconFinder";

interface Props {
  handleFilterChange: (
    field: keyof typeof crEbayFilters,
    newVal: string | number
  ) => void;
}

enum SortDir {
  asc = "asc",
  des = "des",
}
interface Sort {
  direction: SortDir;
  column: string;
}

const initialSort = {
  direction: SortDir.asc,
  column: "",
};

const tableStyles = {
  px: "px-0",
  minWidth: "min-w-[1100px]",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
  },
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[72px]",
  },
};

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

const CompetitorResearchEbayList: FC<Props> = ({ handleFilterChange }) => {
  const [sort, setSort] = useState<Sort>(initialSort);
  const [competitors, setCompetitors] = useState({});
  const [competitorsAsc, setCompetitorsAsc] = useState<boolean | null>(null);
  const { ebayData, ebayFilters: filters } = useAppSelector(
    (state) => state.crSlice
  );
  const [sellerList, setSellerList] = useState<CrEbayList[]>([])

  const modal = useModal();
  const [selectedItem, setSelectedItem] =
    useState<IconFinderItem>(initialSelectedItem);
  const { data: list, currency, recordsFiltered } = ebayData.result;
  const pagination = usePagination(recordsFiltered || 0, 50);
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const [postEbayFilters] = useCrEbayMutation();

  useEffect(() => {
    if (sort.column !== "") {
      postEbayFilters({
        ...filters,
        sortColumn: sort.column,
        sortColumnDirection: sort.direction,
      });
    }

    handleFilterChange("sortColumn", sort.column);
    handleFilterChange("sortColumnDirection", sort.direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const handleCountChange = (count: number, id: string) => {
    // @ts-ignore
    setCompetitors(prev => ({ [id]: count, ...prev }))
  }
  const handleSort = (column: string) => {
    if (column === "competitorCount") {
      setCompetitorsAsc(prev => prev === null || prev === true ? false : true);
    } else {
      setSort((prev) => ({
        column,
        direction:
          prev.column === column
            ? prev.direction === SortDir.des
              ? SortDir.asc
              : SortDir.des
            : SortDir.asc,
      }));
    }

  };

  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem(item);
  };

  useEffect(() => {
    if (list && list.length > 0) {
      setSellerList(list)
    } else {
      setSellerList([])
    }
  }, [list])

  useEffect(() => {
    if (delayedValue !== filters.searchText) {
      handleFilterChange("searchText", delayedValue);
      pagination.goto(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedValue]);

  useEffect(() => {
    if (pagination.current !== filters.start) {
      handleFilterChange("start", pagination.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current]);
  useEffect(() => {
    if (competitorsAsc === true || competitorsAsc === false) {
      // @ts-ignore
      const newL = sellerList.map((item) => (({ ...item, competitorCount: competitors[item.itemID] || 0 })))
        .sort((a, b) => competitorsAsc ? b.competitorCount - a.competitorCount : a.competitorCount - b.competitorCount)
      setSellerList(newL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitorsAsc])

  const { table } = useTable<CrEbayList>({
    columns: crEbayCols(currency, filters, onBoxClick, handleCountChange, handleSort),
    dataDeps: [sellerList],
    data: sellerList || [],
    initialPageSize: 50,
    sort: true,
    columnDeps: [filters.searchedSelectedPeriod, competitorsAsc],
  });

  return (
    <Card className="w-full pt-4 pb-3  px-4 " shadow={false}>
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between mb-6">
        <Search
          search={immediateValue}
          handleChange={onChange}
          size="sm"
          htmlFor="tableSearch"
          maxWidth="18.85rem"
        />
        <Pagination pagination={pagination} className="mr-2" />
      </div>
      <Table<CrEbayList> table={table} styles={tableStyles} sort={competitorsAsc === null ? { direction: competitorsAsc ? "des" as SortDir : "asc" as SortDir, column: "competitorCount" } : sort} />
      <Pagination pagination={pagination} className="mt-2" labeled />
      <ScrollToTop scrollTo={584} />
      <IconFinder helpers={modal} item={selectedItem} currency={currency} thirdParty={true} />
    </Card>
  );
};

export default CompetitorResearchEbayList;
