import InfoTooltip from "components/Card/InfoTooltip";
import useHover from "hooks/useHover";
import { FC, useEffect, useState } from "react";

interface Props {
  value: {
    min?: string | number | null;
    max?: string | number | null;
  };
  name: string;
  handleChange: (newValue: number | string, type: "min" | "max") => void;
  inputWrapperClassName?: string;
  inputWidth?: string;
  inputGap?: string;
  hideMax?: boolean;
  hideMin?: boolean;
  allowNegative?: boolean;
  className?: string;
  customInputWrapperStyles?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: string;
  infoTitle?: string;
  titleWrapperMaxWidth?: string;
  tooltipMaxWidth?: string;
  iconClassName?: string;
  isInvalid?: boolean
  maxLimit?: number;
  isFromTo?: boolean
  disabled?: boolean
}

const MinMaxFilter: FC<Props> = ({
  value,
  className,
  inputWidth,
  inputGap,
  name,
  allowNegative,
  inputWrapperClassName,
  hideMax,
  hideMin,
  customInputWrapperStyles,
  isFromTo,
  handleChange,
  Icon,
  title,
  infoTitle,
  tooltipMaxWidth,
  titleWrapperMaxWidth,
  iconClassName,
  isInvalid,
  maxLimit,
  disabled,
}) => {
  const htmlFor = (type: "min" | "max") => name + type;
  const [minFocusRef, minFocused] = useHover<HTMLInputElement>("focus");
  const [maxFocusRef, maxFocused] = useHover<HTMLInputElement>("focus");
  const [max, setMax] = useState(value.max != null && value.max !== "" ? Number(value.max) : "")
  const [min, setMin] = useState(value.min != null && value.min !== "" ? Number(value.min) : "")

  const inputWrapperStyles = (isFocused: boolean) => `flex flex-col items-center gap-1 px-[6.75px] relative after:content-[''] 
  after:absolute after:bottom-[17px] after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%-16px)] after:h-[2px]
    ${disabled ? "after:bg-grey100 text-grey100" : isInvalid ? "after:bg-errorRed text-errorRed" :
      `${isFocused ?
        `after:bg-primaryPurple text-primaryPurple dark:after:bg-purple500 dark:text-purple500` :
        "after:bg-darkBlue text-darkBlue hover:text-primaryPurple dark:text-grey100 after:rounded-full dark:after:bg-grey100 dark:hover:text-purple500"} after:bg-darkBlue hover:after:bg-primaryPurple dark:hover:after:bg-purple500`} 
    ${customInputWrapperStyles || ""}`;
  const inputStyles = `outline-none text-center pt-2 ${inputWidth || "w-full"
    } bg-transparent`;

  useEffect(() => {
    setMax(value.max != null && value.max !== "" ? Number(value.max) : "")
  }, [value.max])
  useEffect(() => {
    setMin(value.min != null && value.min !== "" ? Number(value.min) : "")
  }, [value.min])

  return (
    <div
      className={`flex flex-col items-center justify-center gap-2 w-full relative ${className}`}
    >
      {(Icon || title) && (
        <div
          className={`flex gap-3 justify-between w-full items-center h-6 ${titleWrapperMaxWidth || "max-w-[160px]"
            }`}
        >
          {Icon && <Icon className={`${iconClassName || ""} dark:fill-grey100 fill-darkBlue`} />}
          {title && <h4 className="text-sm font-bold dark:text-grey100">{title}</h4>}
          {infoTitle && (
            <InfoTooltip
              title={infoTitle}
              maxWidth={tooltipMaxWidth}
              place="left"
            />
          )}
        </div>
      )}
      <div
        className={`flex items-center ${inputGap || "gap-5"} justify-center ${inputWrapperClassName || ""
          }`}
      >
        {!hideMin && <div
          className={inputWrapperStyles(minFocused)}
        >
          <input
            value={min}
            type="number"
            ref={minFocusRef}
            placeholder="0"
            disabled={disabled}
            onKeyDown={(e) =>
              ["e", "E", "+", !allowNegative && "-"].includes(e.key) &&
              e.preventDefault()
            }
            id={htmlFor("min")}
            className={inputStyles}
            onChange={(e) =>
              (allowNegative ? true : Number(e.target.value) >= 0 && maxLimit ? Number(e.target.value) <= maxLimit : true) &&
              handleChange(e.target.value ? Number(e.target.value) : "", "min")
            }
          />
          <label
            className="text-xs"
            htmlFor={htmlFor("min")}
          >
            {isFromTo ? "from" : "min"}
          </label>
        </div>
        }
        {!hideMax && (
          <div
            className={inputWrapperStyles(maxFocused)}
          >
            <input
              value={max}
              type="number"
              disabled={disabled}
              onKeyDown={(e) =>
                ["e", "E", "+", !allowNegative && "-"].includes(e.key) &&
                e.preventDefault()
              }
              ref={maxFocusRef}
              placeholder="0"
              className={inputStyles}
              id={htmlFor("max")}
              onChange={(e) => {
                (allowNegative ? true : Number(e.target.value) >= 0 && maxLimit ? Number(e.target.value) <= maxLimit : true) &&
                  handleChange(e.target.value ? Number(e.target.value) : "", "max")
              }
              }
            />
            <label
              className="text-xs"
              htmlFor={htmlFor("max")}
            >
              {isFromTo ? "to" : "max"}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default MinMaxFilter;
