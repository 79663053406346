import { ReactComponent as Checkmark } from "assets/images/img_checkmark.svg";
import React from "react";

type ModalFunnelStartWithZikCheckmarkProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{}>;

const ModalFunnelStartWithZikCheckmark: React.FC<
  ModalFunnelStartWithZikCheckmarkProps
> = () => (
  <>
    <Checkmark className="" />
  </>
);

ModalFunnelStartWithZikCheckmark.defaultProps = {};

export default ModalFunnelStartWithZikCheckmark;
