import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AddCampaignRes,
  ChangePaypalRes,
  EarningStatsRes,
  GenerativeInvoiceRes,
  GetCampaignGraphRes,
  GetCampaignsRes,
  GetPaidReqRes,
  InvoiceDetailsRes,
  PaymentDetailsRes,
  ReferralStats,
  ReferredUserRes,
  RequestPayoutReq,
  RequestPayoutRes,
} from "types/services/affiliateManager";
import { AddCampaignReq } from "../../types/services/affiliateManager";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const affiliateManagerApi = createApi({
  reducerPath: "affiliateManagerApi",
  baseQuery,
  tagTypes: [
    queryTags.affiliateManager.campaigns,
    queryTags.affiliateManager.earningStats,
  ],
  endpoints: (builder) => ({
    getInvoiceDetails: builder.query<InvoiceDetailsRes, null>({
      query: () => ({
        url: queryKeys.affiliateManager.getUserInvoiceDetails,
        method: "GET",
      }),
    }),
    getReferralStats: builder.query<ReferralStats, void>({
      query: () => ({
        url: queryKeys.affiliateManager.getReferralStats,
        method: "GET",
      }),
    }),
    getEarningStats: builder.query<EarningStatsRes, void>({
      query: () => ({
        url: queryKeys.affiliateManager.getEarningStats,
        method: "GET",
      }),
      providesTags: [queryTags.affiliateManager.earningStats],
    }),
    getReferredUser: builder.query<ReferredUserRes, void>({
      query: () => ({
        url: queryKeys.affiliateManager.getReferredUser,
        method: "GET",
      }),
    }),
    getPaidRequests: builder.query<GetPaidReqRes, void>({
      query: () => ({
        url: queryKeys.affiliateManager.getPaidRequests,
        method: "GET",
      }),
    }),
    getUnPaidRequests: builder.query<GetPaidReqRes, void>({
      query: () => ({
        url: queryKeys.affiliateManager.getUnPaidRequests,
        method: "GET",
      }),
    }),
    getCampaigns: builder.query<GetCampaignsRes, void>({
      query: () => ({
        url: queryKeys.affiliateManager.getCampaigns,
        method: "GET",
      }),
      providesTags: [queryTags.affiliateManager.campaigns],
    }),
    getCampaignGraph: builder.query<GetCampaignGraphRes, string>({
      query: (campaignId) => ({
        url: queryKeys.affiliateManager.getCampaignGraph,
        params: { campaignId },
        method: "GET",
      }),
      providesTags: [queryTags.affiliateManager.campaigns],
    }),
    generateInvoice: builder.query<GenerativeInvoiceRes, string>({
      query: (requestId) => ({
        url: queryKeys.affiliateManager.generateInvoice,
        params: { requestId },
        method: "GET",
      }),
    }),
    getPaymentDetails: builder.query<PaymentDetailsRes, string>({
      query: (requestId) => ({
        url: queryKeys.affiliateManager.getPaymentDetails,
        params: { requestId },
        method: "GET",
      }),
    }),
    addCampaign: builder.mutation<AddCampaignRes, AddCampaignReq>({
      query: (body) => ({
        url: queryKeys.affiliateManager.addCampaign,
        method: "POST",
        body,
      }),
      invalidatesTags: [queryTags.affiliateManager.campaigns],
    }),
    requestPayout: builder.mutation<RequestPayoutRes, RequestPayoutReq>({
      query: (body) => ({
        url: queryKeys.affiliateManager.requestPayout,
        method: "POST",
        body,
      }),
    }),
    changePaypal: builder.mutation<ChangePaypalRes, string>({
      query: (email) => ({
        url: queryKeys.affiliateManager.changePaypal,
        method: "PATCH",
        params: { email },
      }),
      invalidatesTags: [queryTags.affiliateManager.earningStats],
    }),
  }),
});

export const {
  useGetInvoiceDetailsQuery,
  useGetReferralStatsQuery,
  useGetEarningStatsQuery,
  useGetReferredUserQuery,
  useGetPaidRequestsQuery,
  useGetUnPaidRequestsQuery,
  useGetCampaignsQuery,
  useGetCampaignGraphQuery,
  useGenerateInvoiceQuery,
  useGetPaymentDetailsQuery,
  useAddCampaignMutation,
  useRequestPayoutMutation,
  useChangePaypalMutation,
} = affiliateManagerApi;
