import CutLongTitle from "components/CutLongTitle";
import Loading from "components/Loading";
import { FC, ReactNode } from "react";
import { fotmatNumberWithComma } from "utils/formatNumbers";
import Card from "..";
import InfoTooltip from "../InfoTooltip";

interface Props {
  className?: string;
  iconClassName?: string;
  title: ReactNode | string;
  value: string | number;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  info?: string;
  isSmall?: boolean;
  isLoading?: boolean;
  valueClassName?: string;
  cutLongTitle?: number;
  titleClassName?: string;
  contClassName?: string;
}

const StatsCard: FC<Props> = ({
  className,
  title,
  Icon,
  value,
  isLoading,
  info,
  isSmall,
  iconClassName,
  valueClassName,
  titleClassName,
  cutLongTitle,
  contClassName,
}) => (
  <Card
    bordered
    className={`w-full px-5 pt-4 pb-6 text-darkBlue dark:text-grey100 ${isSmall ? "h-[7.375rem]" : "h-[8.69rem]"
      } ${className || ""}`}
  >
    <div className={`w-full h-full rounded-md flex justify-between flex-col ${contClassName}`}>
      <div className="flex justify-between items-center ">
        <Icon className={`fill-darkBlue dark:fill-grey100 ${iconClassName || ""}`} />
        <h4 className={`text-sm font-bold ${titleClassName || ""}`}>{title}</h4>
        <div>{info ? <InfoTooltip title={info} /> : ""}</div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-full mt-7">
          <Loading fill="fill-primaryPurple dark:fill-purple500" />
        </div>
      ) : (
        <h5
          className={`text-center dark:text-paradiseBlue ${isSmall ? "text-2xl" : "text-4xl font-bold"
            } ${valueClassName || ""}`}
        >
          {cutLongTitle ? <CutLongTitle title={value.toString()} length={cutLongTitle} /> : fotmatNumberWithComma(value)}
        </h5>
      )}
    </div>
  </Card>
);

export default StatsCard;
