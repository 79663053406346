export const helpVideos = {
  pr: [
    {
      title: "eBay Product Research Tutorial",
      source:
        "https://player.vimeo.com/video/888029846?h=6327329044&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Product Research - Title Analytics tutorial",
      source:
        "https://player.vimeo.com/video/891042977?h=867b8e79b8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Product Research - Price Analytics tutorial",
      source:
        "https://player.vimeo.com/video/894474429?h=c36b2035e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  prAli: [
    {
      title: "AliExpress Product Research tutorial",
      source:
        "https://player.vimeo.com/video/891034927?h=b371fe580c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  cr: [
    {
      title: "Competitor Research Tutorial",
      source:
        "https://player.vimeo.com/video/891032806?h=f08968bd51&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  crAli: [
    {
      title: "Competitor Research - AliExpress Tutorial",
      source:
        "https://player.vimeo.com/video/888026227?h=ce9fb4bb13&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  category: [
    {
      title: "Category Research tutorial",
      source:
        "https://player.vimeo.com/video/894060477?h=feb6e800a6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  titleBuilder: [
    {
      title: "",
      source: "https://player.vimeo.com/video/751142400?h=7f3130ec22",
    },
  ],
  bulkScanner: [
    {
      title: "Bulk scanner tutorial",
      source:
        "https://player.vimeo.com/video/891046040?h=af66314f6b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  bestSellingItems: [
    {
      title: "500 best selling eBay items tutorial",
      source:
        "https://player.vimeo.com/video/891034155?h=be724f57f2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  aliGrowth: [
    {
      title: "Ali Growth Scanner Tutorial",
      source:
        "https://player.vimeo.com/video/891047656?h=9c85098182&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  turboScanner: [
    {
      title: "Turbo scanner tutorial - Amazon",
      source:
        "https://player.vimeo.com/video/891037694?h=6479cf9b73&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Turbo scanner tutorial - Walmart",
      source:
        "https://player.vimeo.com/video/894070070?h=999d525b2e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  autopilot: [
    {
      title: "Autopilot tutorial - Amazon",
      source:
        "https://player.vimeo.com/video/888015987?h=f177ddcb54&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Autopilot tutorial - AliExpress",
      source:
        "https://player.vimeo.com/video/888025538?h=cc0506246b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Autopilot tutorial - Walmart",
      source:
        "https://player.vimeo.com/video/888026962?h=d6899bf255&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  myCompetitors: [
    {
      title: "How to save competitor to watchlist tutorial",
      source:
        "https://player.vimeo.com/video/890224482?h=2c2a977e8e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  myProducts: [
    {
      title: "How to create new folders tutorial",
      source:
        "https://player.vimeo.com/video/891049766?h=6a11f5ce7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "How to upload items to lister tools - CSV file - tutorial",
      source:
        "https://player.vimeo.com/video/894474409?h=f5ecb0dcda&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  quickSettings: [
    {
      title: "Quick settings tutorial",
      source:
        "https://player.vimeo.com/video/888622730?h=0d5903daea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "How to set up Breakeven tutorial",
      source:
        "https://player.vimeo.com/video/888615525?h=7fc01690cb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  settings: [
    {
      title: "",
      source: "https://player.vimeo.com/video/916638199?h=cf665cdbb7",
    },
  ],
  savedSearches: [
    {
      title: "How to save product research searches tutorial",
      source:
        "https://player.vimeo.com/video/894067653?h=0ff5c7fd16&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  affiliateManagement: [
    {
      title: "How to become an affiliate - Tutorial",
      source:
        "https://player.vimeo.com/video/894474445?h=dbf1937299&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  managePayments: [
    {
      title: "How to upgrade subscription tutorial",
      source:
        "https://player.vimeo.com/video/888611939?h=3e35c142e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  storeAnalytics: [
    {
      title: "How to connect your store Tutorial",
      source:
        "https://player.vimeo.com/video/891050194?h=831e929475&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Store Analytics statistics - Tutorial",
      source:
        "https://player.vimeo.com/video/894063319?h=cc666cf046&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  manualScanner: [
    {
      title: "Manual Scanner – Item finder tutorial – Amazon",
      source:
        "https://player.vimeo.com/video/894074283?h=ce05c0ea2b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Manual Scanner - AliExpress Tutorial",
      source:
        "https://player.vimeo.com/video/894075491?h=6dfca4539c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Manual Scanner - Walmart Tutorial",
      source:
        "https://player.vimeo.com/video/894077335?h=f924ee5be4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Manual Scanner Alibaba Tutorial",
      source:
        "https://player.vimeo.com/video/894078389?h=f6bb7d3594&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
};
