import { Cell, flexRender } from "@tanstack/react-table";
import { TableBodyStyles } from "./Table";

interface Props<T extends object> {
  cell: Cell<T, unknown>;
  isFirstCol: boolean;
  isLastCol: boolean;
  styles?: TableBodyStyles;
  previousCellExtracted?: boolean;
}

const TableBodyCell = <T extends object>({
  cell,
  isFirstCol,
  styles,
  isLastCol,
  previousCellExtracted,
}: Props<T>) => {
  const { columnDef } = cell.column;
  const { meta } = columnDef;

  const tdMainStyles = `pb-2 px-0 
    ${previousCellExtracted ? "pl-2" : ""} 
    ${meta?.extract ? "pr-2" : ""}
  `;

  const tdStyles = `flex items-center border-y-2 border-secondary  dark:border-deepSpace800  dark:bg-black900
    ${meta?.className || ""}
    ${isFirstCol || previousCellExtracted ? "border-l-2 rounded-l-10" : ""} 
    ${isLastCol || meta?.extract ? "border-r-2 rounded-r-10" : ""}
    ${styles?.rowHeight || "h-[60px]"}
    ${styles?.tb || ""}
  `;

  const tdContStyles = `border-secondary flex items-center w-full px-1 dark:border-deepSpace800
    ${meta?.align || "justify-center"}
    ${isLastCol || meta?.hideBorder ? "" : "border-r-2"}
    ${styles?.cont || ""}
    ${styles?.contHeight || "h-[36px]"}
  `;

  return (
    <td
      className={tdMainStyles}
      style={{
        minWidth: columnDef?.minSize,
        ...(columnDef?.maxSize ? { maxWidth: columnDef?.maxSize } : {}),
      }}
    >
      <div className={tdStyles}>
        <div className={tdContStyles}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      </div>
    </td>
  );
};

export default TableBodyCell;
