import { BLUESNAP_SCRIPT } from "config/config";
import useDecodeToken from "hooks/useDecodeToken";
import useExternalScript from "hooks/useExternalScript";
import { useEffect } from "react";
import {
  useGetUserPlanQuery,
  useLazyGetPfTokenQuery
} from "store/api/managePaymentApi";
import Index from "../components/ManagePayments/Index";

declare global {
  interface Window {
    gpfToken: any;
  }
}

const ManagePayments = () => {
  const [getPfToken] = useLazyGetPfTokenQuery();

  useEffect(() => {
    getPfToken(null)
      .unwrap()
      .then((data) => {
        window.gpfToken = data.message;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useExternalScript(BLUESNAP_SCRIPT, "");
  const user = useDecodeToken();
  const { data } = useGetUserPlanQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const currentPlan = user.package_name || "";
  const isTrial = /^true$/i.test(user.active_trail);
  const paymentMethod = data?.result?.paymentMethod || "";
  const paymentStatus = data?.result?.status || 0;
  const isCanceled = data?.result?.isCanceled;
  const renewalDate = data?.result?.nextPaymentDate || "N/A";

  return (
    <Index
      currentPlan={currentPlan}
      paymentMethod={paymentMethod}
      paymentStatus={paymentStatus}
      isCanceled={isCanceled}
      renewalDate={renewalDate}
      isTrial={isTrial}
    />
  );
};

export default ManagePayments;
