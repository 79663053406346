import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";

const ProPlus = () => (
  <div className="flex flex-col gap-[10px] w-[246px] mx-auto">
    <div className="font-bold text-[14px] text-center">
      All the features from PRO, and:
    </div>
    <div className="flex gap-3 text-[14px]">
      <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
      <div className="font-medium">
        <div className="text-primaryPurple dark:text-purple500">500 Best Selling eBay Items</div>
        <div className="text-[12px]">Find out who the best sellers are</div>
      </div>
    </div>
    <div className="flex gap-3 text-[14px]">
      <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
      <div className="font-medium">
        <div className="text-primaryPurple dark:text-purple500">Bulk Scanner</div>
        <div className="text-[12px]">
          Instantly find thousands<br className="hidden max-[1060px]:block max-[965px]:hidden" /> of Amazon and Walmart<br className="hidden max-[1060px]:block max-[965px]:hidden" /> dropship items
        </div>
      </div>
    </div>
    <div className="flex gap-3 text-[14px]">
      <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
      <div className="font-medium">
        <div className="text-primaryPurple dark:text-purple500">Ali Growth Scanner</div>
        <div className="text-[12px]">
          Easily gather data on trends coming from AliExpress
        </div>
      </div>
    </div>
  </div>
);

export default ProPlus;
