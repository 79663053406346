import { VIMEO_BASE, YOUTUBE_BASE } from "config/constants";

export const indexPlaylist = [
  {
    playlistTitle:
      "Product research training for eBay sellers that sell their own stock",
    videos: [
      {
        title: "How to Find The Best eBay Niches in 2022",
        source: `${YOUTUBE_BASE}/JvvY_9-W1aw`,
      },
      {
        title:
          "eBay Competitor Analysis | DOMINATE your Niche with these eBay Competitor Research",
        source: `${YOUTUBE_BASE}/59WG8qctE0Y`,
      },
      {
        title:
          "How to Sell Books on Ebay | Is this Book Selling on eBay for Profit?",
        source: `${YOUTUBE_BASE}/qr66KSUyZ9g`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title:
          "Using ZIK Analytics to Find & Buy Wholesale Liquidation Pallets to Flip on eBay",
        source: `${YOUTUBE_BASE}/dnFrETRzkyY`,
      },
      {
        title:
          "How to Sell Jewelry on eBay | Tips to Make Money on eBay Selling Jewellery",
        source: `${YOUTUBE_BASE}/B52IJNfUQiE`,
      },
      {
        title:
          "How to sell clothes on eBay QUICKLY| Find the BEST clothes to sell on eBay in 2022",
        source: `${YOUTUBE_BASE}/3IS8TILS6zQ`,
      },
    ],
  },
  {
    playlistTitle: "Product Research Training for eBay dropshippers",
    videos: [
      {
        title: "Automate your eBay Product Research in 2022 Push Button",
        source: `${YOUTUBE_BASE}/A-9NkzenlTc`,
      },
      {
        title: "How to Find Other Dropshippers that use Walmart & Homedepot",
        source: `${YOUTUBE_BASE}/V2-Z2P172EM`,
      },
      {
        title: "How to Find Other Dropshippers that use AliExpress",
        source: `${YOUTUBE_BASE}/V4DRQfvOHIU`,
      },
      {
        title:
          "High Ticket Dropshipping on eBay [Step By Step Tutorial] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/sNk_dnoyMdc`,
      },
      {
        title: "How to reach 100 sales a day dropshipping on eBay in 2022",
        source: `${YOUTUBE_BASE}/h0YnlStnIKo`,
      },
      {
        title:
          "How to make $500 a week on eBay Dropshipping in 2022 | eBay Business Plan",
        source: `${YOUTUBE_BASE}/RchPLS8uzzY`,
      },
      {
        title:
          "How to PREDICTABLY make $1000 Per DAY Dropshipping on eBay [Step By Step] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/DxivFbWvnuI`,
      },
      {
        title:
          "Find Profitable Products to Dropship from AliExpress to eBay UK",
        source: `${YOUTUBE_BASE}/AkqZuVwuJGQ`,
      },
      {
        title:
          "eBay Dropshipping in UK | How to find profitable items to sell from UK Suppliers",
        source: `${YOUTUBE_BASE}/_GRE14hqgME`,
      },
      {
        title:
          "Find Items To Dropship from AliExpress & CJ Dropshipping to eBay",
        source: `${YOUTUBE_BASE}/t62IlVs7_bQ`,
      },
      {
        title:
          "eBay Dropshipping | How To Find Profitable Items To Dropship on eBay",
        source: `${YOUTUBE_BASE}/PRxKdHUN_hM`,
      },
    ],
  },
  {
    playlistTitle:
      "Learn how to optimize your titles and get your listings to the top!",
    videos: [
      {
        title:
          "How To Build Perfect eBay Titles in 2022 | Keywords that will push your eBay Listing to the TOP",
        source: `${YOUTUBE_BASE}/FpNDUhgRIMM`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title: "eBay Listing Optimization From Scratch [UPDATED for 2021]",
        source: `${YOUTUBE_BASE}/cDyoA62QUxg`,
      },
    ],
  },
];

export const guides = [
  {
    playlistTitle: "Guides",
    videos: [
      {
        title: "How To Dropship on eBay in 2023 and Become Profitable",
        source: `${YOUTUBE_BASE}/6aZp8uxCa3Y`,
      },
      {
        title: "How to start eBay Dropshipping in 2023 from 0 to break even",
        source: `${YOUTUBE_BASE}/W37mGUxO9zE`,
      },
      {
        title: "eBay Product Research: A Complete Step-by-Step Guide",
        source: `${YOUTUBE_BASE}/Ft8AVinGOmA`,
      },
      {
        title:
          "10 TRENDING Products to Sell Online in 2023 (And How I found Them With THIS Little Known Hack",
        source: `${YOUTUBE_BASE}/Ep1HrfPL2Yg`,
      },
      {
        title: "How to Dropship High Priced Items on eBay [Step By Step]",
        source: `${YOUTUBE_BASE}/xopfXKx0lvo`,
      },
      {
        title:
          "Easiest way to increase eBay sales | Achieve $1000/Day in Sales",
        source: `${YOUTUBE_BASE}/bDkHTMCchi4`,
      },
      {
        title: "How to Make Money on eBay using AliExpress [$500 Per Week!]",
        source: `${YOUTUBE_BASE}/73AjMlEAhq8`,
      },
      {
        title: "Make $100 a day Dropshipping from Walmart to eBay [REVEALED]",
        source: `${YOUTUBE_BASE}/mglKtoIZIZM`,
      },
      {
        title:
          "eBay Title Builder Strategies to Increase eBay Sales and Traffic 📈",
        source: `${YOUTUBE_BASE}/s471hnjKrjM`,
      },
      {
        title: "How to reach 100 sales a day dropshipping on eBay in 2022",
        source: `${YOUTUBE_BASE}/h0YnlStnIKo`,
      },
      {
        title:
          "How to make $500 a week on eBay Dropshipping in 2022 | eBay Business Plan",
        source: `${YOUTUBE_BASE}/RchPLS8uzzY`,
      },
      {
        title:
          "How to PREDICTABLY make $1000 Per DAY Dropshipping on eBay [Step By Step] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/DxivFbWvnuI`,
      },
      {
        title: "How to ACTUALLY Sell on eBay in 2024 [$1000 / Day]",
        source: `${YOUTUBE_BASE}/aAUJayWfzW4`,
      },
    ],
  },
];

export const prStock = [
  {
    playlistTitle:
      "Product research training for eBay sellers that sell their own stock",
    videos: [
      {
        title: "How to Find The Best eBay Niches in 2022",
        source: `${YOUTUBE_BASE}/JvvY_9-W1aw`,
      },
      {
        title:
          "eBay Competitor Analysis | DOMINATE your Niche with these eBay Competitor Research",
        source: `${YOUTUBE_BASE}/59WG8qctE0Y`,
      },
      {
        title:
          "How to Sell Books on Ebay | Is this Book Selling on eBay for Profit?",
        source: `${YOUTUBE_BASE}/qr66KSUyZ9g`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title:
          "Using ZIK Analytics to Find & Buy Wholesale Liquidation Pallets to Flip on eBay",
        source: `${YOUTUBE_BASE}/dnFrETRzkyY`,
      },
      {
        title:
          "How to Sell Jewelry on eBay | Tips to Make Money on eBay Selling Jewellery",
        source: `${YOUTUBE_BASE}/B52IJNfUQiE`,
      },
      {
        title:
          "How to sell clothes on eBay QUICKLY| Find the BEST clothes to sell on eBay in 2022",
        source: `${YOUTUBE_BASE}/3IS8TILS6zQ`,
      },
    ],
  },
];

export const prDropshipper = [
  {
    playlistTitle: "Product research training for eBay Dropshippers",
    videos: [
      {
        title: "Automate your eBay Product Research in 2022 Push Button",
        source: `${YOUTUBE_BASE}/A-9NkzenlTc`,
      },
      {
        title: "How to Find Other Dropshippers that use Walmart & Homedepot",
        source: `${YOUTUBE_BASE}/V2-Z2P172EM`,
      },
      {
        title: "How to Find Other Dropshippers that use AliExpress",
        source: `${YOUTUBE_BASE}/V4DRQfvOHIU`,
      },
      {
        title:
          "High Ticket Dropshipping on eBay [Step By Step Tutorial] | eBay Business Plan",
        source: `${YOUTUBE_BASE}/sNk_dnoyMdc`,
      },
      {
        title:
          "eBay Product Research Guide | How we use eBay Analytics to find TRENDING eBay Products",
        source: `${YOUTUBE_BASE}/MHxf45kADuY`,
      },
      {
        title:
          "Find Profitable Products to Dropship from AliExpress to eBay UK",
        source: `${YOUTUBE_BASE}/AkqZuVwuJGQ`,
      },
      {
        title:
          "eBay Dropshipping in UK | How to find profitable items to sell from UK Suppliers",
        source: `${YOUTUBE_BASE}/_GRE14hqgME`,
      },
      {
        title:
          "Find Items To Dropship from AliExpress & CJ Dropshipping to eBay",
        source: `${YOUTUBE_BASE}/t62IlVs7_bQ`,
      },
      {
        title:
          "eBay Dropshipping | How To Find Profitable Items To Dropship on eBay",
        source: `${YOUTUBE_BASE}/PRxKdHUN_hM`,
      },
      {
        title:
          "Facebook Marketplace Dropshipping Automation - How to Automate FBMP with ZIK Autopilot | ZIK Autom8",
        source: `${YOUTUBE_BASE}/ov_spgUjoew`,
      },
      {
        title: "How to find Amazon to eBay Dropshippers?",
        source: `${YOUTUBE_BASE}/aoBiVksUStc`,
      },
      {
        title:
          " WATCH ME Find 1000+ products to Dropship on eBay in 20 seconds FLAT [Push Button]",
        source: `${YOUTUBE_BASE}/yq3V8BsQ4RU`,
      },
      {
        title:
          "The ZIK Analytics Autopilot & Turbo Scanner Features EXPLAINED GAMECHANGER! | ZIK Autom8",
        source: `${YOUTUBE_BASE}/HBQvsQq9Ojs`,
      },
    ],
  },
];

export const listingOptimisation = [
  {
    playlistTitle: "Listing optimization training",
    videos: [
      {
        title:
          "How To Build Perfect eBay Titles in 2022 | Keywords that will push your eBay Listing to the TOP",
        source: `${YOUTUBE_BASE}/FpNDUhgRIMM`,
      },
      {
        title:
          "eBay Algorithm in 2022 [REVEALED] | How to RANK on eBay search engine",
        source: `${YOUTUBE_BASE}/4CmB7e7hBYg`,
      },
      {
        title: "Listing On eBay Made Easy | Pro Tips Inside",
        source: `${YOUTUBE_BASE}/Sl2b4najPQ4`,
      },
    ],
  },
];

export const webinars = [
  {
    playlistTitle: "Webinars",
    videos: [
      {
        title: "How To Start eBay Dropshipping STEP BY STEP [FREE COURSE]",
        source: `${YOUTUBE_BASE}/uLRFMx-AxsM`,
      },
      {
        title:
          "How to find Profitable Products for eBay Dropshipping in 2023 [Monthly Webinar]",
        source: `${YOUTUBE_BASE}/2YoGE-aK0Qc`,
      },
      {
        title:
          "eBay Competitor Research - Find, Analyze, and Beat your Competition [Monthly Webinar]",
        source: `${YOUTUBE_BASE}/6kpC8ZpG_4c`,
      },
      {
        title:
          "How to increase your profits on eBay [$50,000 Strategy Revealed]",
        source: `${YOUTUBE_BASE}/96RTETlpHWM`,
      },
      {
        title:
          "How to Get More Traffic and Sales to Your eBay Listings [Monthly Webinar]",
        source: `${YOUTUBE_BASE}/09BnNshHGMs`,
      },
      {
        title: "How to Find Profitable Products to Dropship on eBay",
        source: `${YOUTUBE_BASE}/FCcq-VRbgso`,
      },
    ],
  },
];

export const tips = [
  {
    playlistTitle: "Tips and Tricks",
    videos: [
      {
        title:
          "Easiest way to increase eBay sales | Achieve $1000/Day in Sales",
        source: `${YOUTUBE_BASE}/bDkHTMCchi4`,
      },
      {
        title: "3 eBay Customer Service Hacks to WIN on eBay in 2022",
        source: `${YOUTUBE_BASE}/-nh780kmJZo`,
      },
      {
        title: "6 eBay Selling hacks you need to Fix Slow Sales",
        source: `${YOUTUBE_BASE}/jl9s7wka8-E`,
      },
    ],
  },
];

// Courses

export const dropshippingCourse = [
  {
    lesson: "lesson 1",
    videos: [
      {
        name: "Learn how the eBay algorithm works",
        source: `${YOUTUBE_BASE}/LfUxHgesL8U`,
      },
      {
        name: "Basic rules of trading goods and retails sales",
        source: `${YOUTUBE_BASE}/mmLXM_Kigqg`,
      },
    ],
  },
  {
    lesson: "lesson 2",
    videos: [
      {
        name: "Understand your sell through rate, successful listing percentage and average selling price",
        source: `${YOUTUBE_BASE}/8GcH5j3vIio`,
      },
    ],
  },
  {
    lesson: "lesson 3",
    videos: [
      {
        name: "Discover how to find suitable competitors on AliExpress",
        source: `${YOUTUBE_BASE}/N20_FRzYf1Y`,
      },
      {
        name: "Discover how to find suitable competitors on Amazon",
        source: `${YOUTUBE_BASE}/5XptDpxzZfc`,
      },
      {
        name: "Find relevant information from your competitors",
        source: `${YOUTUBE_BASE}/vcM0JVrw-1Q`,
      },
      {
        name: "Learn to use competitors to find high profit items",
        source: `${YOUTUBE_BASE}/FnTCR73wF1A`,
      },
    ],
  },
  {
    lesson: "lesson 4",
    videos: [
      {
        name: "How to uncover hot niches",
        source: `${YOUTUBE_BASE}/lUHcmmife_8`,
      },
      {
        name: "Finding best sellers within a niche",
        source: `${YOUTUBE_BASE}/zsDOybgTPMA`,
      },
      {
        name: "Discover hidden opportunities within niches",
        source: `${YOUTUBE_BASE}/kAx1AMcrHfY`,
      },
    ],
  },
  {
    lesson: "lesson 5",
    videos: [
      {
        name: "How to source items from suppliers",
        source: `${YOUTUBE_BASE}/2Q2sHsf86Ww`,
      },
      {
        name: "Using your intuition",
        source: `${YOUTUBE_BASE}/Zuq4uyvrtko`,
      },
      {
        name: "A full practice of finding items (from A to Z)",
        source: `${YOUTUBE_BASE}/Nl4tTMcMER4`,
      },
    ],
  },
  {
    lesson: "lesson 6",
    videos: [
      {
        name: "Master SEO Keywords – Long Tail & Generic",
        source: `${YOUTUBE_BASE}/cGWWgkjrW7g`,
      },
      {
        name: "In-depth title building practice",
        source: `${YOUTUBE_BASE}/mmv6dZMpny0`,
      },
      {
        name: "On Quick title building practice",
        source: `${YOUTUBE_BASE}/uBUeSBlZm-E`,
      },
      {
        name: "Optimizing the item specifics",
        source: `${YOUTUBE_BASE}/fSKQznC2-GA`,
      },
    ],
  },
  {
    lesson: "lesson 7",
    videos: [
      {
        name: "Use the Category research tool to find products and niches",
        source: `${YOUTUBE_BASE}/47en4I2bGi4`,
      },
    ],
  },
];

export const sellerCourse = [
  {
    lesson: "lesson 1",
    videos: [
      {
        name: "Introduction video",
        source: `${VIMEO_BASE}/658564456?h=7263163e9c`,
      },
    ],
  },
  {
    lesson: "lesson 2",
    videos: [
      {
        name: "Introduction to product research software",
        source: `${VIMEO_BASE}/745399237?h=d7033f4f96`,
      },
    ],
  },
  {
    lesson: "lesson 3",
    videos: [
      {
        name: "How to use the Category research to find, competitors, niche and product ideas",
        source: `${VIMEO_BASE}/745396888?h=03f1080187`,
      },
    ],
  },
  {
    lesson: "lesson 4",
    videos: [
      {
        name: "How to analyse niches",
        source: `${VIMEO_BASE}/745396950?h=438ae5c13a`,
      },
    ],
  },
  {
    lesson: "lesson 5",
    videos: [
      {
        name: "Find and Analyse your competitors",
        source: `${VIMEO_BASE}/745396928?h=93afcf3805`,
      },
    ],
  },
  {
    lesson: "lesson 6",
    videos: [
      {
        name: "Product Analyses - validate demand, competition, success rate and USP + sourcing exmaple",
        source: `${VIMEO_BASE}/745396965?h=b69c478e7a`,
      },
    ],
  },
  {
    lesson: "lesson 7",
    videos: [
      {
        name: "Scale your winning products",
        source: `${VIMEO_BASE}/745403614?h=3442da6726`,
      },
    ],
  },
  {
    lesson: "lesson 8",
    videos: [
      {
        name: "Reverse engineering to move dead stock",
        source: `${VIMEO_BASE}/745449764?h=33dafc2a05`,
      },
    ],
  },
];

// Tutorials

export const tutorialList = [
  {
    playlistTitle: "Market insights dashboard",
    id: "01",
    playlistTitleTranslation: "Market Insights Dashboard",

    videos: [
      {
        title: "Market insights dashboard",
        source: `https://player.vimeo.com/video/888024586?h=7184734418&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`,
        translation: "Market insights dashboard",
      },
      {
        title:
          "Market insights dashboard tutorial for amazon to eBay Dropshipping",
        source: `${VIMEO_BASE}/888607674?h=51ab9dee22`,
        translation:
          "Market insights dashboard tutorial for amazon to eBay Dropshipping",
      },
      {
        title:
          "Market insight dashboard tutorial for Walmart to eBay Dropshipping",
        source: `${VIMEO_BASE}/887994000?h=31ab2396eb`,
        translation:
          "Market insight dashboard tutorial for Walmart to eBay Dropshipping",
      },
      {
        title: "How to find HOT niches for your eBay Selling Business",
        source: `${VIMEO_BASE}/888609147?h=2217251a90`,
        translation: "How to find HOT niches for your eBay Selling Business",
      },
      {
        title:
          "Find the top eBay Sellers in just 1 click | Fast Competitor Research",
        source: `${VIMEO_BASE}/888613680?h=17ec3daab4`,
        translation:
          "Find the top eBay Sellers in just 1 click | Fast Competitor Research",
      },
      {
        title: "AliExpress to eBay Dropshipping | Easy Product Research Method",
        source: `${VIMEO_BASE}/888611174?h=0223284004`,
        translation:
          "AliExpress to eBay Dropshipping | Easy Product Research Method",
      },
      {
        title: "Top Trending eBay Products & HOW to Find them",
        source: `${VIMEO_BASE}/887995348?h=b7caededde`,
        translation: "Top Trending eBay Products & HOW to Find them",
      },
      {
        title: "Top Products to Sell on Shopify & HOW to Find them",
        source: `${VIMEO_BASE}/887997596?h=638161c737`,
        translation: "Top Products to Sell on Shopify & HOW to Find them",
      },
      {
        title: "Stay on Top of eCommerce Trends with Market Insights Calendar",
        source: `${VIMEO_BASE}/888027542?h=e56b459177`,
        translation:
          "Stay on Top of eCommerce Trends with Market Insights Calendar",
      },
      {
        title: "Find Winning Wholesale Products to Sell on eBay Based on Data",
        source: `${VIMEO_BASE}/890267997?h=c2e81beb7b`,
        translation:
          "Find Winning Wholesale Products to Sell on eBay Based on Data",
      },
    ],
  },
  {
    playlistTitle: "Competitor Research",
    id: "02",
    playlistTitleTranslation: "CompetitorResearch",
    videos: [
      {
        title: "Exact Item Finder",
        source: `${VIMEO_BASE}/888619472?h=d7f0299309`,
        translation: "Exact Item Finder",
      },
      {
        title: "Competitor Research - AliExpress Tutorial",
        translation: "Competitor Research - AliExpress Tutorial",
        source: `${VIMEO_BASE}/888026227?h=ce9fb4bb13`,
      },
      {
        title: "Competitor Research Tutorial",
        translation: "Competitor Research Tutorial",
        source: `${VIMEO_BASE}/891032806?h=f08968bd51`,
      },
      {
        title: "Competitor Research settings tutorial",
        translation: "Competitor Research settings tutorial",
        source: `${VIMEO_BASE}/888624076?h=9ac95bbef8`,
      },
      {
        title: "How to save competitor to watchlist tutorial",
        translation: "How to save competitor to watchlist tutorial",
        source: `${VIMEO_BASE}/890224482?h=2c2a977e8e`,
      },
    ],
  },
  {
    playlistTitle: "Product Research",
    id: "03",
    playlistTitleTranslation: "ProductResearch",
    videos: [
      {
        title: "AliExpress Product Research tutorial",
        translation: "AliExpress Product Research tutorial",
        source: `${VIMEO_BASE}/891034927?h=b371fe580c`,
      },
      {
        title: "eBay Product Research Tutorial",
        translation: "eBay Product Research Tutorial",
        source: `${VIMEO_BASE}/888029846?h=6327329044`,
      },
      {
        title: "How to save product research searches tutorial",
        translation: "How to save product research searches tutorial",
        source: `${VIMEO_BASE}/894067653?h=0ff5c7fd16`,
      },
      {
        title: "Product Research - Title Analytics tutorial",
        translation: "Product Research - Title Analytics tutorial",
        source: `${VIMEO_BASE}/891042977?h=867b8e79b8`,
      },
      {
        title: "Product Research - Price Analytics tutorial",
        translation: "Product Research - Price Analytics tutorial",
        source: `${VIMEO_BASE}/894474429?h=c36b2035e0`,
      },
    ],
  },
  {
    playlistTitle: "Category Research",
    id: "04",
    playlistTitleTranslation: "CategoryResearch",
    videos: [
      {
        title: "Category Research tutorial",
        translation: "Category Research tutorial",
        source: `${VIMEO_BASE}/894060477?h=feb6e800a6`,
      },
    ],
  },
  {
    playlistTitle: "Store Analytics",
    id: "05",
    playlistTitleTranslation: "StoreAnalytics",
    videos: [
      {
        title: "How to connect your store Tutorial",
        translation: "How to connect your store Tutorial",
        source: `${VIMEO_BASE}/891050194?h=831e929475`,
      },
      {
        title: "Store Analytics statistics - Tutorial",
        translation: "Store Analytics statistics - Tutorial",
        source: `${VIMEO_BASE}/894063319?h=cc666cf046`,
      },
    ],
  },
  {
    playlistTitle: "Manual scanner / Item finder",
    id: "06",
    playlistTitleTranslation: "ManualScanner",
    videos: [
      {
        title: "How to create new folders tutorial",
        translation: "How to create new folders tutorial",
        source: `${VIMEO_BASE}/891049766?h=6a11f5ce7d`,
      },
      {
        title: "Manual Scanner – Item finder tutorial – Amazon",
        translation: "Manual Scanner – Item finder tutorial – Amazon",
        source: `${VIMEO_BASE}/894074283?h=ce05c0ea2b`,
      },
      {
        title: "Manual Scanner - AliExpress Tutorial",
        translation: "Manual Scanner - AliExpress Tutorial",
        source: `${VIMEO_BASE}/894075491?h=6dfca4539c`,
      },
      {
        title: "Manual Scanner - Walmart Tutorial",
        translation: "Manual Scanner - Walmart Tutorial",
        source: `${VIMEO_BASE}/894077335?h=f924ee5be4`,
      },
      {
        title: "Manual Scanner Alibaba Tutorial",
        translation: "Manual Scanner Alibaba Tutorial",
        source: `${VIMEO_BASE}/894078389?h=f6bb7d3594`,
      },
    ],
  },
  {
    playlistTitle: "Advanced Tools",
    id: "07",
    playlistTitleTranslation: "Advanced Tools",
    videos: [
      {
        title: "Autopilot tutorial - Amazon",
        translation: "Autopilot tutorial - Amazon",
        source: `${VIMEO_BASE}/888015987?h=f177ddcb54`,
      },
      {
        title: "Autopilot tutorial - AliExpress",
        translation: "Autopilot tutorial - AliExpress",
        source: `${VIMEO_BASE}/888025538?h=cc0506246b`,
      },
      {
        title: "Autopilot tutorial - Walmart",
        translation: "Autopilot tutorial - Walmart",
        source: `${VIMEO_BASE}/888026962?h=d6899bf255`,
      },
      {
        title: "Turbo scanner tutorial - Amazon",
        translation: "Turbo scanner tutorial - Amazon",
        source: `${VIMEO_BASE}/891037694?h=6479cf9b73`,
      },
      {
        title: "Turbo scanner tutorial - Walmart",
        translation: "Turbo scanner tutorial - Walmart",
        source: `${VIMEO_BASE}/894070070?h=999d525b2e`,
      },
      {
        title: "500 Best selling ebay items tutorial",
        translation: "500 best selling ebay items tutorial",
        source: `${VIMEO_BASE}/891034155?h=be724f57f2`,
      },
      {
        title: "Bulk scanner tutorial",
        translation: "Bulk scanner tutorial",
        source: `${VIMEO_BASE}/891046040?h=af66314f6b`,
      },
      {
        title: "Ali Growth Scanner Tutorial",
        translation: "Ali Growth Scanner Tutorial",
        source: `${VIMEO_BASE}/891047656?h=9c85098182`,
      },
    ],
  },
  {
    playlistTitle: "Settings and Billing",
    id: "08",
    playlistTitleTranslation: "SettingsBilling",
    videos: [
      {
        title: "How to upgrade subscription tutorial",
        translation: "How to upgrade subscription tutorial",
        source: `${VIMEO_BASE}/888611939?h=3e35c142e1`,
      },
      {
        title: "How to download your invoices tutorial",
        translation: "How to download your invoices tutorial",
        source: `${VIMEO_BASE}/891048497?h=a5223c0f05`,
      },
      {
        title: "Quick settings tutorial",
        translation: "Quick settings tutorial",
        source: `${VIMEO_BASE}/888622730?h=0d5903daea`,
      },
    ],
  },
  {
    playlistTitle: "Others",
    id: "09",
    playlistTitleTranslation: "Others",
    videos: [
      {
        title: "How to become an affiliate - Tutorial",
        translation: "How to become an affiliate - Tutorial",
        source: `${VIMEO_BASE}/894474445?h=dbf1937299`,
      },
      {
        title: "How to set up Breakeven tutorial",
        translation: "How to set up Breakeven tutorial",
        source: `${VIMEO_BASE}/888615525?h=7fc01690cb`,
      },
      {
        title: "How to use Title builder tutorial",
        translation: "How to use Title builder tutorial",
        source: `${VIMEO_BASE}/887990979?h=f2e6e75fa0`,
      },
      {
        title: "How to zoom out tutorial",
        translation: "How to zoom out tutorial",
        source: `${VIMEO_BASE}/888616719?h=d0dda268b6`,
      },
    ],
  },
  {
    playlistTitle: "Product Research Integrations",
    id: "10",
    playlistTitleTranslation: "ProductResearchIntegrations",
    videos: [
      {
        title: "How to integrate AutoDS and ZIK",
        translation: "HowToIntegrateAutoDS",
        source: `${VIMEO_BASE}/894065462?h=6737a1bf26`,
      },
      {
        title: "How to integrate Salefreaks and ZIK",
        translation: "HowToSalefreaks",
        source: `${VIMEO_BASE}/891036546?h=10f64d5f44`,
      },
      {
        title: "How to upload products to AutoDS tutorial",
        translation: "How to upload products to AutoDS tutorial",
        source: `${VIMEO_BASE}/891039394?h=c45586d53a`,
      },
      {
        title: "How to upload items to lister tools - CSV file - tutorial",
        translation:
          "How to upload items to lister tools - CSV file - tutorial",
        source: `${VIMEO_BASE}/894474409?h=f5ecb0dcda`,
      },
    ],
  },
];
