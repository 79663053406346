import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Select from "components/Select";
import useFilters from "hooks/useFilters";

// Icons
import { saleDateRangeArr } from "assets/data/filters";
import { ReactComponent as Megaphone } from "assets/icons/megaphone.svg";
import { ReactComponent as Price } from "assets/icons/price.svg";
import { ReactComponent as ShippingLocaion } from "assets/icons/shipping-location.svg";
import { ReactComponent as Stacks } from "assets/icons/stacks.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as SandClock } from "assets/icons/time.svg";
import Toaster from "components/Toast";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useGetSettingsQuery,
  useUpdateCompetitorPrefMutation
} from "store/api/quickSettingsApi";
import { getCountryFlag } from "utils/getCountryFlag";

const sellerSite = [
  { text: "United States", value: ".com", flag: "us" },
  { text: "United Kingdom", value: ".co.uk", flag: "gb" },
  { text: "Canada", value: ".ca", flag: "ca" },
  { text: "Australia", value: ".au", flag: "au" },
  { text: "France", value: ".fr", flag: "fr" },
  { text: "Germany", value: ".de", flag: "de" },
  { text: "Italy", value: ".it", flag: "it" },
  { text: "Spain", value: ".es", flag: "es" },
  { text: "Malaysia", value: ".com.my", flag: "my" },
];

const competitorLocation = [
  { text: "United States", value: "US" },
  { text: "United Kingdom", value: "GB" },
  { text: "Canada", value: "CA" },
  { text: "Australia", value: "AU" },
  { text: "China", value: "CN" },
  { text: "France", value: "FR" },
  { text: "Germany", value: "DE" },
  { text: "Italy", value: "IT" },
  { text: "Spain", value: "ES" },
  { text: "Malaysia", value: "MY" },
];

const currencies = [
  { text: "British pound - £", value: "GBP" },
  { text: "USD - $", value: "USD" },
  { text: "AUD - $", value: "AUD" },
  { text: "EUR - €", value: "EUR" },
];

interface MinMax {
  min: number | null;
  max: number | null;
}

const initialState = {
  sellerSite: ".com",
  competitorLocation: "US",
  sellerCurrency: "GBP",
  competitorRange: "30",
  periodSales: {
    min: null,
    max: null,
  } as MinMax,
  totalSales: {
    min: null,
    max: null,
  } as MinMax,
  currentPrice: {
    min: null,
    max: null,
  } as MinMax,
};

const CompetitorSettings = () => {
  const { data } = useGetSettingsQuery();
  const { filters, handleFilterChange, setFilters } = useFilters(initialState);
  const [save, { isLoading }] = useUpdateCompetitorPrefMutation();
  const { t } = useTranslation(['home']);
  useEffect(() => {
    if (data) {
      setFilters({
        sellerSite: data.result.sellerSite,
        competitorLocation: (
          data.result.shippingLocation || ".com"
        ).toString(),
        sellerCurrency: data.result.sellerCurrency,
        competitorRange: data.result.competitorDay.toString(),
        periodSales: {
          min: data.result.competitorDaysMinSale,
          max: data.result.competitorDaysMaxSale,
        },
        totalSales: {
          min: data.result.competitorMinTotalSale,
          max: data.result.competiorMaxTotalSale,
        },
        currentPrice: {
          min: data.result.competitorMinPrice,
          max: data.result.competitorMaxPrice,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSave = () => {
    if (filters.periodSales.min !== null && filters.periodSales.max !== null && filters.periodSales.min > filters.periodSales.max) {
      toast.error("Max period sales must be greater than or equal to min period sales");

      return;
    } if (filters.totalSales.min !== null && filters.totalSales.max !== null && filters.totalSales.min > filters.totalSales.max) {
      toast.error("Max total sales must be greater than or equal to min total sales");

      return;
    }
    if (filters.currentPrice.min !== null && filters.currentPrice.max !== null && filters.currentPrice.min > filters.currentPrice.max) {
      toast.error("Max current price must be greater than or equal to current min price");

      return;
    }
    save({
      site: filters.sellerSite,
      competitionLocation: (filters.competitorLocation || ".com").toString(),
      currency: filters.sellerCurrency,
      dateRange: Number(filters.competitorRange),
      daySalesMin: filters.periodSales.min,
      daySalesMax: filters.periodSales.max,
      totalSalesMin: filters.totalSales.min,
      totalSalesMax: filters.totalSales.max,
      priceMin: filters.currentPrice.min,
      priceMax: filters.currentPrice.max,
      userId: 0,
    })
      .unwrap()
      .then((payload) => {
        if (payload) {
          toast.success("Competitor research changes successfully made.");
        }
      });
  };

  return (
    <>
      <div className="grid grid-cols-5 gap-8 gap-y-3.5 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
        <FiltersCard
          title={t('Marketplace')}
          Icon={Store}
          iconClassName="w-[14px]"
        >
          <Select
            options={sellerSite}
            img={getCountryFlag(sellerSite.find(item => item.value == filters.sellerSite)?.flag || "us")}
            btnClassName="w-full"
            value={filters.sellerSite}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "sellerSite");
            }}
          />
        </FiltersCard>

        <FiltersCard
          Icon={ShippingLocaion}
          title={t('ShippingLocation')}
          iconClassName="w-[18px]"
        >
          <Select
            options={competitorLocation}
            btnClassName="w-full"
            value={filters.competitorLocation}
            img={getCountryFlag(filters.competitorLocation === ".COM.AU" ? "AU" : filters.competitorLocation)}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "competitorLocation");
            }}
          />
        </FiltersCard>

        <FiltersCard
          title={t('StoreCurrency')}
          titleClassName="text-sm m-auto capitalize"
          className="row-span-3 max-[1000px]:row-span-4"
          Icon={Price}
          iconClassName="w-[14px]"
          infoTitle="The currency that the competitor uses "
        >
          <div className="flex flex-col gap-4.5 mt-[10px]">
            <FilterCheckbox
              options={currencies}
              value={filters.sellerCurrency}
              className="gap-[10px] w-full"
              handleChange={(newValue) => {
                handleFilterChange(newValue, "sellerCurrency");
              }}
            />
          </div>
        </FiltersCard>

        <FiltersCard
          title={t('SaleDateRange')}
          titleClassName="capitalize"
          Icon={SandClock}
          iconClassName="w-[12px]"
          infoTitle="Filter by competitor sales range period (30 days, 21, 14 & 7 days)"
        >
          <Select
            className="font-medium"
            options={saleDateRangeArr}
            btnClassName="w-full"
            value={filters.competitorRange}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "competitorRange");
            }}
          />
        </FiltersCard>

        <FiltersCard title={t('Period Sales')} Icon={Megaphone} iconClassName="w-[14px]" infoTitle="Select the desired timeframe you wish to see sales data for.">
          <MinMaxFilter
            name="periodSales"
            value={filters.periodSales}
            inputWidth="max-w-[64px]"
            handleChange={(newValue, type) =>
              handleFilterChange(newValue, "periodSales", type)
            }

          />
        </FiltersCard>

        <FiltersCard title={t('TotalSales')} Icon={Stacks} iconClassName="w-[11px]" infoTitle="Filter by the total amount of sales generated by competitor">
          <MinMaxFilter
            name="totalSales"
            value={filters.totalSales}
            inputWidth="max-w-[64px]"
            handleChange={(newValue, type) =>
              handleFilterChange(newValue, "totalSales", type)
            }
          />
        </FiltersCard>

        <FiltersCard title={t('CurrentPrice')} Icon={Price} iconClassName="w-[16px]" infoTitle="Filter by current price of product">
          <MinMaxFilter
            name="currentPrice"
            value={filters.currentPrice}
            inputWidth="max-w-[64px]"
            handleChange={(newValue, type) =>
              handleFilterChange(newValue, "currentPrice", type)
            }
          />
        </FiltersCard>
      </div>
      <div className="w-full flex justify-center mt-4">
        <Button
          title="Save"
          height="h-11"
          className="max-w-[230px] dark:bg-deepSpace900"
          handleClick={onSave}
          loading={isLoading}
        />
      </div>
      <Toaster />
    </>
  );
};

export default CompetitorSettings;
