import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as TogglePassword } from "assets/icons/password-view.svg";
import { ReactComponent as PasswordVisible } from "assets/icons/password-visible.svg";
import useHover from "hooks/useHover";
import { FC, FocusEventHandler, HTMLInputTypeAttribute, useState } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

export type InputProps = {
  value: string;
  type?: HTMLInputTypeAttribute;
  handleChange: (newVal: string) => void;
  placeholder?: string;
  erase?: boolean;
  className?: string;
  disabled?: boolean;
  name?: string;
  variant?: "outlined" | "contained";
  autoComplete?: string;
  togglePassword?: boolean;
  hasPercentSuffix?: boolean;
  fontSize?: string
  inputClassName?: string;
  eraseClassName?: string;
  defaultBorderColor?: string
  register?: UseFormRegister<FieldValues>;
  error?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>
  maxLength?: number
};

const Input: FC<InputProps> = ({
  value,
  placeholder,
  disabled,
  type,
  erase,
  name,
  variant = "outlined",
  className,
  autoComplete,
  fontSize,
  handleChange,
  togglePassword,
  defaultBorderColor,
  hasPercentSuffix,
  onBlur,
  inputClassName,
  eraseClassName,
  error,
  maxLength
}) => {
  const [inputType, setInputType] = useState(type);
  const [inputRef, inputFocused] = useHover<HTMLInputElement>("focus");
  const isOutlined = variant === "outlined";
  const isTyped = value?.length > 0
  const underlinedFocusedInputStyles = "after:bg-primaryPurple dark:after:bg-purple500 text-primaryPurple dark:text-purple500"
  const underlinedTypedDefaultInput = "after:bg-darkBlue dark:after:bg-lilac400 dark:text-lilac400"
  const outlinedLabelStyles = ` pb-[11px] after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded-full relative
  ${inputFocused ? underlinedFocusedInputStyles : disabled ? "after:bg-grey200" : `hover:after:bg-primaryPurple dark:hover:after:bg-purple500  ${isTyped ? underlinedTypedDefaultInput : "after:bg-secondary dark:after:bg-grey100"}`}`

  const labelStyles = `flex items-center relative ${className} ${disabled ? "" : "hover:text-primaryPurple dark:hover:text-purple500"} 
    ${isOutlined ? outlinedLabelStyles :
      `border-2 ${error ? "border-errorRed" : inputFocused ? "border-primaryPurple dark:border-purple500" : disabled ? "border-grey200" : defaultBorderColor || "border-secondary dark:border-grey100"}`} 
  `;

  const inputStyles = `w-full focus:outline-none  bg-transparent focus:bg-transparent font-normal  placeholder:font-normal ${disabled ? "text-grey200" : `dark:focus:text-purple500 ${isOutlined ? "" : "dark:text-grey100"}`}  ${erase ? "pr-6" : ""} ${fontSize || "text-sm"} ${inputClassName || ""}`;

  return (
    <label htmlFor={name} className={labelStyles}>
      <input
        value={value}
        autoComplete={autoComplete || "off"}
        type={inputType}
        ref={inputRef}
        name={name}
        id={name}
        onBlur={onBlur}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder || ""}
        className={inputStyles}
        maxLength={maxLength}
        onKeyDown={(e) => inputType === "number" && ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
      />
      {hasPercentSuffix && value !== "" && <div className="mr-auto text-[14px]">%</div>}
      {erase && (value || "").length > 0 ? (
        <button
          className={`cursor-pointer w-6 h-6 flex items-center group justify-center absolute right-0 top-[2px] bg-transparent ${eraseClassName}`}
          onClick={() => handleChange("")}
        >
          <Clear
            className={`group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-darkBlue ${isOutlined ? "dark:fill-lilac400" : "dark:fill-grey100"} w-3 h-3`}
          />
        </button>
      ) : (
        ""
      )}

      {togglePassword && (
        <div
          className="cursor-pointer"
          onClick={() =>
            setInputType(inputType === "password" ? "text" : "password")
          }
        >
          {inputType === "password" ? (
            <TogglePassword className="fill-neutral3 w-6 h-4" />
          ) : (
            <PasswordVisible className="fill-neutral3 w-6 h-4" />
          )}
        </div>
      )}
    </label>
  );
};

export default Input;
