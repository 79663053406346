import SellerGraph from "assets/images/selling-graph.png";
import { ReactComponent as Smiley } from "assets/images/smiley.svg";
import Button from "components/Button";
import Loading from "components/Loading";
import { useEffect, useState } from "react";
import { useGetFeedbackQuery, useGetOnBoardingQuestionQuery } from "store/api/onboardingApi";
import { GetUserDetails } from "utils/localStorage";
import GetFeedback from "./GetFeedback";

interface Props {
  onSetSeller: (seller: boolean) => void;
  loading: boolean;
  introStep: 0 | 1 | 2
  onIntroStepChange: (step: 0 | 1 | 2) => void
}
const Introduction = ({ onSetSeller, loading, introStep, onIntroStepChange }: Props) => {
  const { data, isLoading: questionLoading } = useGetOnBoardingQuestionQuery(1);
  const [yesOrNo, setYesOrNo] = useState<"yes" | "no" | null>(null)
  const [name, setName] = useState("");
  const { data: feedback } = useGetFeedbackQuery();

  const handleBtnClick = (seller: boolean) => {
    setYesOrNo(seller ? "yes" : "no");
    onSetSeller(seller)
  }
  useEffect(() => {
    const user = GetUserDetails();

    if (user !== null) {
      const userInfo = JSON.parse(user);
      setName(userInfo.firstName);
    }
  }, []);
  const stepContStyles = `flex flex-col max-sm:h-[60%] max-sm:justify-between mb-5 max-lg:mt-[120px] ${introStep === 2 ? "max-lg:mt-[56px] max-sm:mt-[76px]" : "max-sm:mt-[100px]"} max-sm:mt-[100px] items-center`

  return (
    <div className={`flex flex-col justify-start max-sm:h-fit w-full ${introStep === 0 ? "max-w-[426px] max-sm:h-[calc(100vh-65px)]" : introStep === 1 ? "max-w-[302px] max-lg:max-w-[353px] max-sm:h-[calc(100vh-65px)]" : "max-w-full"} mx-auto ${introStep === 2 ? "max-lg:mt-[0px] max-sm:mt-0" : "max-lg:mt-[0px]"}`}>
      {introStep === 0 ? (
        <div className={`${stepContStyles} !mb-0`}>
          <div>
            <Smiley className="mx-auto w-[55px] h-[55px] mb-[27px] max-lg:mb-5.5" />
            <h1 className="text-2xl leading-[32px] text-center font-bold text-darkBlue dark:text-grey100 mb-2.5 max-lg:mb-[38px]">
              Welcome, <span className="capitalize">{` ${name}!`}</span>
            </h1>
            <h2 className="text-base leading-[24px] text-center text-darkBlue dark:text-grey100 mb-[43px] max-lg:mb-[65px] max-sm:text-lg">
              You're a few steps away from the full ZIK experience.<br />
              Let's tailor your experience and activate your account!
            </h2>
          </div>
          <Button title="Let’s Go!" className="max-w-[200px] max-lg:max-w-[350px] mx-auto" height="h-16 max-sm:h-14" titleClassName="!text-base" handleClick={() => onIntroStepChange(1)} />
        </div>
      ) : introStep === 1 && (feedback?.message === null || feedback?.message === "") ? (
        <GetFeedback contStyles={stepContStyles} goNextStep={() => onIntroStepChange(2)} />

      ) : <>
        <div className={stepContStyles}>
          {questionLoading ? (
            <Loading fill="primaryPurple" />
          ) : (
            <div className="max-lg:mb-[118px]">
              <img src={SellerGraph} alt="" className="w-[45px] h-[45px] mx-auto mb-[35px] mt-[7px] max-lg:mb-5.5 max-sm:mb-[46px]" />
              <h1 className="mb-[25px] text-xl text-center font-bold text-darkBlue dark:text-grey100 max-lg:text-2xl max-lg:mb-5 max-sm:text-xl">
                {data?.result[0].questionLabel}
              </h1>
              <p className="lg:mb-[25px] text-sm text-center text-darkBlue dark:text-grey100 max-lg:text-lg max-sm:text-base">
                {data?.result[0].questionDescription}
              </p>
            </div>
          )}

          <div className="flex flex-row max-lg:flex-col max-lg:items-center justify-center space-between gap-[35px] max-lg:gap-5 w-full">
            <Button
              title="Yes"
              height="h-16 max-sm:h-14"
              titleClassName="!text-base"
              className="max-w-[200px] max-lg:max-w-[350px]"
              value={true}
              handleClick={handleBtnClick}
              loading={loading && yesOrNo === "yes"}
            />
            <Button
              title="No"
              height="h-16 max-sm:h-14"
              titleClassName="!text-base"
              className="max-w-[200px] max-lg:max-w-[350px]"
              value={false}
              handleClick={handleBtnClick}
              loading={loading && yesOrNo === "no"}
            />
          </div>
        </div>
      </>}
    </div>
  );
};

export default Introduction;
