import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import Button from "components/Button";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCancelSubscriptionMutation } from "store/api/managePaymentApi";
import { object, string } from "yup";
import CancelSuccess from "./CancelSuccess";
import CancelationEnterEmail from "./EnterEmail";
import Other from "./Other";
import CancellationQuestionnaire, { cancelationQuestionnaireOptions } from "./Questionnaire";
import SaveUptoHalf from "./SaveUptoHalf";


interface FormValues {
    email: string
}
let schema = object({
    email: string().email("Please provide valid email").required("Please provide your email!"),
});


interface Props {
    helpers: ReturnType<useModal>
    onUpgrade: () => void;
}
const CancelationFunnel: FC<Props> = ({ helpers, onUpgrade }) => {
    const [step, setStep] = useState(0)
    const [questionnaire, setQuestionnaire] = useState<string>("")
    const [cancelSubs, { isLoading, data }] = useCancelSubscriptionMutation();
    const [otherAnswer, setOtherAnswer] = useState<string>("");
    const onSelectOption = (newVal: string) => setQuestionnaire(newVal);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onCancelClick = () => setStep(prev => prev + 1)
    const onBackClick = () => {
        if (step === 2) {
            setStep(hasDiscount ? 1 : 0)
            setOtherAnswer("");
        } else if (step === 4) {
            setStep(0)
        }
        else {
            setStep(prev => prev - 1)
        }
    }

    const handleOtherAnswer = (val: string) => {
        if (val === "") {
            toast.error("Please provide answer");
            return;
        }
        setOtherAnswer(val);
        setStep(2);
    }

    const onSubmitEmail: SubmitHandler<FormValues> = (values) => {
        cancelSubs({
            mainAnswer: questionnaire,
            email: values.email,
            otherFeedback: otherAnswer
        }).unwrap().then(() => {
            setStep(3);
            toast.success("Your subscription successfully canceled");
        }).catch(() => toast.error("Email does not match!"))
    }
    const hasDiscount =
        questionnaire === cancelationQuestionnaireOptions[0] ||
        questionnaire === cancelationQuestionnaireOptions[5] ||
        questionnaire === cancelationQuestionnaireOptions[4];

    useEffect(() => {
        if (!helpers.isOpen) {
            setStep(0);
            setQuestionnaire("");
            setValue("email", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [helpers.isOpen])

    return (
        <Modal
            helpers={helpers}
            shouldCloseOnOverlayClick={false}
            className="w-full max-w-[1061px]"
            cardClassName=" h-full max-w-[1401px] w-full pt-[65px] pb-[38px] relative"
            maxWidth="max-w-[1061px]"
            buttonCloseClassName="!top-[25px] !right-5.5"
            hideBorder
            overlay
        >
            <Button
                title="Back"
                height="h-9"
                titleClassName="font-bold"
                className={`z-40 max-w-[104px] w-full bg-white max-lg:hidden absolute top-[22px] left-[28px] dark:bg-deepSpace900 ${step === 0 || step === 3 ? "invisible" : ""}`}
                handleClick={onBackClick}
                StartIcon={LeftArrow}
            />
            {step === 0 && <CancellationQuestionnaire
                hasDiscount={hasDiscount}
                onNextClick={(step: number) => setStep(step)}
                selectedOption={questionnaire}
                onSelect={onSelectOption}
            />}
            {step === 1 && <SaveUptoHalf
                onUpgrade={onUpgrade}
                onCancel={onCancelClick}
            />}
            {step === 2 && <CancelationEnterEmail
                register={register}
                handleSubmit={handleSubmit(onSubmitEmail)}
                errors={errors}
                onUpgrade={onUpgrade}
                isLoading={isLoading}
            />}
            {step === 3 && <CancelSuccess date={data?.result.renewalDate} />}
            {step === 4 && <Other handleSubmit={handleOtherAnswer} />}
        </Modal >
    )
}

export default CancelationFunnel