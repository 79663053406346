const getPlanValue = (plan: string) => {
  switch (plan) {
    case "StarterM":
      return 1;
    case "StarterY":
      return 4;
    case "StandardM":
      return 2;
    case "StandardY":
      return 5;
    case "EnterpriseM":
      return 3;
    case "EnterpriseY":
      return 6;
    case "ProLT":
      return 7;
    case "ProPlusLT":
      return 8;
    case "EnterpriseLT":
      return 9;
    default:
      return 0;
  }
};

export const getPlanName = (plan: string) => {
  switch (plan) {
    case "StarterM":
      return "Pro";
    case "StarterY":
      return "Pro";
    case "StandardM":
      return "Pro+";
    case "StandardY":
      return "Pro+";
    case "EnterpriseM":
      return "Enterprise";
    case "EnterpriseY":
      return "Enterprise";
    case "ProLT":
      return "Pro";
    case "ProPlusLT":
      return "Pro+";
    case "EnterpriseLT":
      return "Enterprise";
    case "ProPlus":
      return "Pro+";
    default:
      return "Pro";
  }
};

export const isUpgrade = (currentPlan: string, newPlan: string) => {
  const currentPlanVal = getPlanValue(currentPlan);
  const newPlanVal = getPlanValue(newPlan);

  if (newPlanVal >= currentPlanVal) return true;

  return false;
};

export const isDowngrade = (currentPlan: string, newPlan: string) => {
  const currentPlanVal = getPlanValue(currentPlan);
  const newPlanVal = getPlanValue(newPlan);

  if (newPlanVal < currentPlanVal) return true;

  return false;
};

export const isCurrentPlan = (currentPlan: string, newPlan: string) => {
  const currentPlanVal = getPlanValue(currentPlan);
  const newPlanVal = getPlanValue(newPlan);

  if (currentPlanVal === newPlanVal) return true;

  return false;
};

export const getPlanSuffix = (planType: string) => {
  const lastChar = planType.charAt(planType.length - 1);

  const suffix = lastChar === "M" ? "/mo" : lastChar === "Y" ? "/yr" : "";

  return suffix;
};

export const getPlanFullSuffix = (planType: string) => {
  const lastChar = planType.charAt(planType.length - 1);

  const suffix =
    lastChar === "M"
      ? "Monthly"
      : lastChar === "Y"
      ? "Yearly"
      : lastChar === "T"
      ? "Lifetime"
      : planType === "Month"
      ? "Monthly"
      : planType === "Year"
      ? "Yearly"
      : planType === "Biannually"
      ? "Biannually"
      : "";

  return suffix;
};

export const getCheckoutNoteText = (planType: string) => {
  const discount =
    planType === "StarterM" ||
    planType === "StandardM" ||
    planType === "EnterpriseM"
      ? "50"
      : planType === "StarterY"
      ? "30"
      : planType === "StandardY"
      ? "40"
      : planType === "EnterpriseY"
      ? "50"
      : "";

  return `You registered to trial and will be charged for $1 only. After 7 days, your subscription will be automatically renewed with ${discount}% discount for the first month of use`;
};

export const getPlanDuration = (plan: string, billingInterval: number) => {
  if (billingInterval === 1) {
    return "Monthly";
  } else if (billingInterval === 12) {
    return "Yearly";
  } else if (
    plan === "ProPlusLT" ||
    plan === "EnterpriseLT" ||
    plan === "ProLT"
  ) {
    return "Lifetime";
  }
};
