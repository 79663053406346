import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { FC } from "react";

interface Props {
  checked: boolean;
  id?: string | number;
  value: string | number;
  checkboxClassName?: string;
  className?: string;
  handleChange: (val: string | number) => void;
}
const Checkbox: FC<Props> = ({
  checked,
  value,
  id,
  className,
  checkboxClassName,
  handleChange,
}) => (
  <div className="relative h-6 cursor-pointer">
    {checked ? (
      <Checkmark
        className={`${checked ? "" : ""} fill-white dark:fill-deepSpace900 z-[1] absolute top-[7px] left-1/2 -translate-x-1/2 w-3 h-[9px] ${className || ""
          }`}
        onClick={() => handleChange(value)}
      />
    ) : (
      ""
    )}
    <input
      type="checkbox"
      id={id?.toString()}
      checked={checked}
      onChange={() => handleChange(value)}
      className={`border-2 border-darkBlue cursor-pointer checked:bg-primaryPurple dark:checked:bg-purple500 dark:bg-transparent bg-white relative appearance-none  dark:border-lilac400 dark:checked:border-purple500
      hover:border-primaryPurple dark:hover:border-purple500 rounded-full w-6 h-6 ${checkboxClassName || ""
        }`}
    />
  </div>
);

export default Checkbox;
