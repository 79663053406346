import { helpVideos } from "assets/data/helpVideos";
import {
  ebaySettingsSourceWebsites,
  quickSettingsEbaySites,
  quickSettingsMarketplace,
  quickSettingsToCountry,
  settingsShippingLocation,
  settingsSourceAmazonSites,
  settingsSourceWebsites
} from "assets/data/quickSettings";
import { ReactComponent as Aliexpress } from "assets/icons/aliexpress.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as Calculator } from "assets/icons/calculator.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as ShippingLocation } from "assets/icons/shipping-location.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";

import Button from "components/Button";
import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import HeaderHelpVideo from "components/Header/HelpVideo";
import Input from "components/Input";
import Select from "components/Select";
import SelectOption from "components/Select/Option";
import Toaster from "components/Toast";
import { COUNTRY_FLAGS } from "config/constants";
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import useResetApi from "hooks/useResetApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useGetSettingsQuery,
  useSaveSettingsMutation
} from "store/api/quickSettingsApi";
interface Props {
  handleClose?: () => void;
  setIgnoreClose?: (close: boolean) => void;
}

const selectStyles = {
  className: " max-w-[146px]",
  btnClassName: "max-w-[146px] min-w-[146px] !h-9",
  listWrapperClassName: "!top-11 dark:bg-deepSpace900",
}
export const quickSettingsState = {
  shippingLocation: "",
  sourceWebsite: "",
  breakEven: "",
  flag: "",
};

const QuickSettings = ({ handleClose, setIgnoreClose }: Props) => {
  const reset = useResetApi();
  const marketplaceModal = useModal()
  const sourceSiteModal = useModal()
  const [marketplaceRef] = useOutsideClick<HTMLDivElement>(marketplaceModal.close);
  const [sourceSiteRef] = useOutsideClick<HTMLDivElement>(sourceSiteModal.close);
  const [ref] = useOutsideClick<HTMLDivElement>(handleClose);
  const [showEbayOptions, setShowEbayOptions] = useState(false)
  const [showAmazonOptions, setShowAmazonOptions] = useState(false)
  const { filters, handleFilterChange } = useFilters(quickSettingsState);
  const { data } = useGetSettingsQuery({}, { refetchOnMountOrArgChange: true });
  const [saveSettings, { isLoading, isSuccess }] = useSaveSettingsMutation();
  const { t } = useTranslation(['home']);

  const marketplaceTitle = [...quickSettingsMarketplace, ...quickSettingsEbaySites].find((item) => item.value === filters.flag)
    ?.text


  const sourceSiteTitle = [...settingsSourceWebsites, ...settingsSourceAmazonSites, ...ebaySettingsSourceWebsites].find((item) => item.value === filters.sourceWebsite)
    ?.text
  useEffect(() => {
    if (isSuccess) {
      toast.success("Settings updated Successfully");
      handleClose && handleClose();
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!marketplaceModal.isOpen) {
      setShowEbayOptions(false)
    }
  }, [marketplaceModal.isOpen])

  useEffect(() => {
    if (!sourceSiteModal.isOpen) {
      setShowAmazonOptions(false)
    }
  }, [sourceSiteModal.isOpen])

  useEffect(() => {
    if (data) {
      handleFilterChange(data?.result.sellerSite, "flag");
      handleFilterChange(data?.result.breakEven, "breakEven");
      handleFilterChange(data?.result.shippingLocation, "shippingLocation");
      handleFilterChange(data?.result.amazonsite, "sourceWebsite");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSourceSiteChange = (newVal: string) => {
    handleFilterChange(newVal, "sourceWebsite");
    sourceSiteModal.close()
  }
  const onFlagChange = (flag: string) => {
    //TODO: This needs refactoring
    handleFilterChange(flag, "flag");
    const country = quickSettingsToCountry.find((item) => item.text === flag)
      ?.value

    var amazonSite = country === "MY" || country == "ES" || country == "FR" || country == "IT" || country == "CA" || country === "AliExpress" ? "Ali" : country

    saveSettings({
      breakEven: Number(filters.breakEven),
      shippingLocation: country === "UK" ? "GB" : country === "AliExpress" ? "CN" : country,
      amazonSite: amazonSite,
      competitionLocation: country,
      userId: 0,
    });
  };

  const onApplyChanges = () => {
    const country = quickSettingsToCountry.find((item) => item.text === filters.flag)
      ?.value
    saveSettings({
      breakEven: Number(filters.breakEven),
      shippingLocation: filters.shippingLocation,
      amazonSite: filters.sourceWebsite,
      competitionLocation:
        (filters.shippingLocation === "Worldwide"
          ? "US" : country === "UK" ? "GB" : country),
      userId: 0,
    });
  };

  const sourceWebsites = filters.flag === ".com" ? ebaySettingsSourceWebsites : settingsSourceWebsites
  const isAli = filters.flag === "AliExpress"

  return (
    <div className="absolute left-[36px] w-[286px] z-50 mb-[10rem] pointer-events-auto">
      <div className="absolute right-5 top-5 flex gap-2.5">

        <button
          className="group bg-neutral2 w-8 h-8 flex items-center justify-center rounded-full -mr-[3px] hover:bg-primaryPurple dark:hover:bg-purple500 dark:bg-black900"
          onClick={handleClose}
        >
          <ArrowLeft className="fill-darkBlue group-hover:fill-white dark:fill-grey100" />
        </button>
      </div>

      <div className="w-full" ref={ref}>

        <Card
          className="bg-white border-primaryPurple dark:border-purple500 px-7 pt-[18px] pb-[31px] dark:text-grey100 dark:bg-deepSpace900"
          rounded="rounded-[10px]"
          bordered
        >
          <div className="flex items-center justify-between mb-3.5 h-8 dark:text-grey100 mr-[30px]">
            <div className="font-bold" onClick={handleClose}>{t('QuickSettings')}</div>
            <HeaderHelpVideo
              videos={helpVideos.quickSettings}
              iconClassName="!h-4 m-auto"
              className="w-8 h-8 !p-0"
              setIgnoreClose={setIgnoreClose}
            />
          </div>
          <div className="mb-2.5 py-[5px]">
            <div className="flex items-center mb-[5px] gap-2.5">
              <div className="w-6 h-6 flex items-center justify-center">
                <Store className="fill-darkBlue dark:fill-paradiseBlue w-3.5 h-3" />
              </div>
              <div className="text-sm font-bold">{t('Marketplace')}</div>
            </div>
            <div className="relative" ref={marketplaceRef}>
              <Select
                iconClassName={isAli ? "w-5 h-5" : "w-6 h-6"}
                helpers={marketplaceModal}
                Icon={isAli ? Aliexpress : undefined}
                title={marketplaceTitle}
                handleChange={() => { }}
                value=""
                img={isAli ? undefined : `${COUNTRY_FLAGS}/${quickSettingsToCountry.find(item => item.text === filters.flag)?.value}.png`}

                {...selectStyles}
                CustomOptions={
                  <ul>
                    <div
                      onMouseOver={() => setShowEbayOptions(true)}>
                      <SelectOption handleOptionClick={() => { }} value={filters.flag} item={quickSettingsMarketplace[0]} listItemClassName={showEbayOptions ? "!bg-primaryPurple dark:!bg-purple500 !text-white" : ""} />
                    </div>
                    {quickSettingsMarketplace.slice(1).map((item, i) => (
                      <div onMouseOver={() => setShowEbayOptions(false)} key={i}>
                        <SelectOption handleOptionClick={onFlagChange} value={filters.flag} item={item} />
                      </div>
                    ))}
                  </ul>
                }
              />
              <div className={`${showEbayOptions ? "block" : "hidden"} pl-2 absolute -right-[80px] z-[100] top-[44px] bg-transparent max-w-[165px] w-full`}>
                <div className="flex flex-col bg-white shadow-lg border-[1px] border-primaryPurple dark:border-purple500 w-full rounded-[16px] pt-2.5 px-2 pb-2 overflow-y-auto dark:bg-deepSpace900 ">
                  {quickSettingsEbaySites.map((item) => (
                    <SelectOption key={item.text} handleOptionClick={onFlagChange} value={filters.flag} item={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2.5 py-[5px] ">
            <div className="flex items-center gap-2.5  mb-2">
              <div className="w-6 h-6 flex items-center justify-center">
                <ShippingLocation className="fill-darkBlue dark:fill-paradiseBlue w-[18px] h-[11px]" />
              </div>
              <div className="text-sm font-bold ">{t('ShippingLocation')}</div>
              <InfoTooltip title="Choose which location your items will be shipped from" />
            </div>
            <Select
              options={settingsShippingLocation}
              value={filters.shippingLocation}
              cutLongOption={11}
              cutTitle={11}
              handleChange={(newVal) => {
                handleFilterChange(newVal, "shippingLocation");
              }}
              {...selectStyles}
              iconClassName="w-4.5"
            />
          </div>

          <div className="mb-2.5 py-[5px]">
            <div className="flex items-center gap-2.5  mb-2">
              <div className="w-6 h-6 flex items-center justify-center">
                <Globe className="fill-darkBlue dark:fill-paradiseBlue w-3.5 h-3.5" />
              </div>
              <div className="text-sm font-bold">{t('SourceWebsite')}</div>
              <InfoTooltip title="Choose which website or supplier you are sourcing your product from" />
            </div>
            <div className="relative w-fit" ref={sourceSiteRef}>
              <Select
                value=""
                title={sourceSiteTitle}
                helpers={sourceSiteModal}
                {...selectStyles}
                CustomOptions={
                  <ul>
                    <div
                      onMouseOver={() => setShowAmazonOptions(true)}>
                      <SelectOption handleOptionClick={() => { }} value={filters.flag} item={settingsSourceWebsites[0]} listItemClassName={showEbayOptions ? "!bg-primaryPurple dark:!bg-purple500 !text-white" : ""} />
                    </div>

                    {sourceWebsites.slice(1).map((item) =>
                      <div onMouseOver={() => setShowAmazonOptions(false)} key={item.text}>
                        <SelectOption
                          key={item.text}
                          cutLongOption={11}
                          handleOptionClick={onSourceSiteChange}
                          value={filters.sourceWebsite}
                          item={item}
                        />
                      </div>

                    )}
                  </ul>
                }
                cutLongOption={11}
                cutTitle={11}
                handleChange={() => { }}
                iconClassName="w-4.5"
              />
              <div className={`${showAmazonOptions ? "block" : "hidden"} pl-2 absolute -right-[142px] z-[100] top-[44px] bg-transparent  max-w-[165px] w-full`}>
                <div className={`flex flex-col bg-white  dark:bg-deepSpace800  shadow-lg border-[1px] border-primaryPurple dark:border-purple500 w-full rounded-[16px] pt-2.5 px-2 pb-2 overflow-y-auto `}>
                  {settingsSourceAmazonSites.map((item) => (
                    <SelectOption
                      key={item.text}
                      cutLongOption={11}
                      handleOptionClick={onSourceSiteChange}
                      value={filters.sourceWebsite}
                      item={item}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-[25px] py-[5px]">
            <div className="flex items-center gap-2  mb-2">
              <div className="w-6 h-6 flex items-center justify-center">
                <Calculator className="fill-darkBlue dark:fill-paradiseBlue" />
              </div>
              <div className="text-sm font-bold">{t('BreakEVenCalculation')}</div>
              <InfoTooltip title="Set your calculated estimated profit. The break-even point is the point at which total cost and total revenue are equal" />
            </div>

            <Input
              value={filters.breakEven}
              handleChange={(newVal) => { Number(newVal) <= 100 && handleFilterChange(newVal, "breakEven") }}
              erase
              type="number"
              placeholder=""
              className="max-w-[146px] !h-9 !pb-0 "
              inputClassName="max-w-[30px] !pr-0"
              hasPercentSuffix
            />
          </div>

          <div className="w-full flex justify-center flex-col items-center gap-[15px]">
            <Button
              className="max-w-[158px]"
              height="h-9"
              color="smoothGreen"
              loading={isLoading}
              title="Apply Changes"
              handleClick={onApplyChanges}
            />
            <Link className="text-[14px] underline dark:text-paradiseBlue hover:text-primaryPurple" to={PATH.quickSettings.account}>Looking for <span className="font-bold">Advanced Settings?</span></Link>
          </div>
        </Card>
        <Toaster />
      </div>
    </div>
  );
};

export default QuickSettings;
