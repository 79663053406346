// Icons
import Toaster from "components/Toast";
import { useGetUserDetailsQuery } from "store/api/quickSettingsApi";
import AccountDetails from "./AccountDetails";
import UserAffiliate from "./Affiliate";
import ChangePassword from "./ChangePassword";

const Account = () => {
  const { data } = useGetUserDetailsQuery();

  return (
    <>
      <div className="flex gap-[69px] justify-center max-xl:gap-[30px] max-xl:justify-start max-[1080px]:flex-wrap  mx-auto">
        <AccountDetails data={data} />
        <ChangePassword userId={data?.result.userId || 0} />
        <UserAffiliate />
      </div>
      <Toaster />
    </>
  );
};
export default Account;
