import { ReactComponent as Smiley } from "assets/images/smiley.svg";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Loading from "components/Loading";
import Scrollbar from "components/Scrollbar";
import { useGetOnBoardingQuestionQuery } from "store/api/onboardingApi";

interface Props {
  selectedAnswers: string[];
  handleSelectAnswer: (answer: string) => void;
  loading: boolean;
  handleSave: () => void;
}
const Questionnaire = ({
  selectedAnswers,
  handleSelectAnswer,
  loading,
  handleSave,
}: Props) => {
  const { data, isFetching: questionLoading } = useGetOnBoardingQuestionQuery(
    3,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <>
      <div className="flex flex-col justify-center mb-[35px] max-sm:mt-2.5">
        <Smiley className="self-center mt-3 mb-[35px] max-lg:hidden" />
        {questionLoading ? <Loading fill="fill-primaryPurple dark:fill-purple500 mx-auto" width={40} height={40} /> : data && (
          <>
            <h1 className="text-xl text-center font-bold text-darkBlue dark:text-grey100 mb-[25px] max-lg:mb-5.5">
              {data?.result[0].questionLabel}
            </h1>
            <Scrollbar className="max-h-[300px] max-sm:max-h-full ">
              <FilterCheckbox
                options={data.result[0].onBoardingQuestionOptions.map((item) => ({
                  value: item.optionValue.toString(),
                  text: item.optionLabel
                }))}
                multiselect
                value={selectedAnswers}
                optionLabelClassName={"min-h-[41px] max-lg:min-h-[56px] !text-base max-sm:!text-sm"}
                className="w-full grid grid-cols-2 sm:px-8 gap-y-3.5 gap-x-[19px] max-lg:gap-x-[36px] max-md:grid-cols-1"
                handleChange={(newVal) => handleSelectAnswer(newVal.toString())}
              />
            </Scrollbar>

          </>
        )}
      </div>
      <div className="w-full flex justify-center max-sm:mb-10">
        <Button
          title="Next"
          height="h-16 max-sm:h-14"
          className={`max-w-[200px] max-lg:max-w-[350px] self-center mx-auto`}
          titleClassName="!text-base"
          handleClick={handleSave}
          loading={loading}
          disabled={selectedAnswers.length <= 0}
        />
      </div>

    </>
  );
};
export default Questionnaire;
