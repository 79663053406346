import AliExpressPage from "assets/images/ali_express.png";
import DropShippingPage from "assets/images/dropshipping.png";
import HomePage from "assets/images/home_page.png";
import PricingPage from "assets/images/pricing.png";
import PrPage from "assets/images/product_research.png";
import TitleBuilderPage from "assets/images/title_builder.png";
import {
  LANDING_PAGE_LINKS,
  LANDING_PAGE_LINKS as LINKS,
} from "config/constants";

export const advertisingChannels = [
  {
    title: "Youtube",
    subtitle:
      "You can create a video tutorial or training upload your video to youtube.",
  },
  {
    title: "Facebook Group",
    subtitle:
      "You can create your own facebook group and share information with your followers.",
  },
  {
    title: "Forums",
    subtitle:
      "You can write, post or comment on questions sharing your affiliate link.",
  },
  {
    title: "Instagram",
    subtitle:
      "You can share information about ZIK on your Instagram page. From our experience, sharing social proof such as sales results or winning products you found with ZIK works very well.",
  },
  {
    title: "Facebook Ads",
    subtitle:
      "You can promote ZIK on facebook ads using video ad. We recommend to always start with giving something for free like free guide or free training and that later try and sell zik analytics. Free giveaways reduce the cost per lead and increase your chances to grow your followers and make more sales.",
  },
  {
    title: "Google Adwords",
    subtitle:
      'You can target relevant keywords like: "how to sell on eBay" or "how to find items to sell on eBay", we recommend to do in depth research before starting. Note - it is not allowed to target ZIK brand keywords affiliator that will do it will get ban forever.',
  },
  {
    title: "Youtube Ads",
    subtitle:
      "YouTube advertising can be very profitable as the competition in the eBay Dropshipping industry is very low",
  },
  {
    title: "Reddit, Quara & other Paid Channels",
    subtitle:
      "You can create your own facebook group and share information with your followers",
  },
];

export const landingPages = [
  { title: "Homepage", link: LINKS.home },
  { title: "eBay Market Research", link: LINKS.ebayMarketPlace },
  { title: "AliExpress Market Research", link: LINKS.aliexpressMarketPlace },
  {
    title: "Advanced Tools <b>(Automated item finders for eBay Dropshippers)</b>",
    link: LINKS.zikProTools,
  },
  {
    title: "Finding winning products for eBay Droppshipping",
    link: LINKS.dropshippingTool,
  },
  { title: "eBay Title Builder and SEO", link: LINKS.ebayTitleBuilder },
];

export const affiliate_landing = [
  {
    img: HomePage,
    link: LANDING_PAGE_LINKS.home,
    id: "Homepage",
    pageTitle: "Home",
  },
  {
    img: PrPage,
    link: LANDING_PAGE_LINKS.ebayMarketPlace,
    id: "EbayMarketResearch",
    pageTitle: "eBay Market Research",
  },
  {
    img: AliExpressPage,
    link: LANDING_PAGE_LINKS.aliexpressMarketPlace,
    id: "AliExpressMarketResearch",
    pageTitle: "AliExpress Market Research",
  },
  {
    img: DropShippingPage,
    link: LANDING_PAGE_LINKS.zikProTools,
    id: "ZIKPro",
    pageTitle: "Advanced Tools Item Finder for eBay Dropshippers",
  },
  {
    img: DropShippingPage,
    link: LANDING_PAGE_LINKS.dropshippingTool,
    id: "FindWinningProducts",
    pageTitle: "Find winning products for eBay Dropshipping",
  },
  {
    img: TitleBuilderPage,
    link: LANDING_PAGE_LINKS.ebayTitleBuilder,
    id: "EbayTitleBuilder",
    pageTitle: "eBay title builder and seo",
  },
  {
    img: PricingPage,
    link: LANDING_PAGE_LINKS.pricing,
    id: "Pricing",
    pageTitle: "Pricing",
  },
];

export const addCampaignTypeOptions = [
  {
    text: "Email Marketing",
    value: "email_marketing",
  },
  {
    text: "Facebook Post",
    value: "facebook_post",
  },
  {
    text: "Instagram Story",
    value: "instagram_story",
  },
  {
    text: "Youtube Video",
    value: "youtube_video",
  },
  {
    text: "Other paid Adds",
    value: "other_paid_adds",
  },
];
