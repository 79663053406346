import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Present from "assets/images/present.png";
import Button from "components/Button";
import Modal from "components/Modal";
import { REGISTRATION_BACKGROUND } from "config/constants";
import useModal from "hooks/useModal";
import { useNavigate } from "react-router-dom";

interface Props {
    loading?: boolean;
}

const RegistrationPaymentCompleted = ({ loading }: Props) => {
    const modal = useModal()
    const navigate = useNavigate();

    return (
        <>
            <img src={REGISTRATION_BACKGROUND} alt="ZIK Dashboard" className="min-h-screen min-w-screen bg-cover w-full max-lg:hidden" />

            <Modal disableDarkMode helpers={{ ...modal, isOpen: true }} maxWidth="max-w-[641px] min-h-[384px] max-lg:h-screen max-lg:max-w-full" cardClassName="rounded-[10px]" className=""
                overlay
                hideBorder
                alwaysOpen
            >
                <div className="w-full h-full flex justify-center items-center overflow-y-auto my-auto">
                    <div className="flex flex-col items-center justify-center max-lg:pt-[80px] max-sm:h-full max-sm:mb-[105px] bg-white w-fit max-lg:mb-10">
                        <h5 className="text-lg text-center font-bold mb-[23px] max-sm:text-xl lg:mt-10">
                            You are all set!
                        </h5>
                        <img src={Present} alt="" className="w-10 h-10 mb-[39px]  max-lg:mb-[34px] max-sm:mb-[60px]" />
                        <h6 className="mb-4 max-lg:mb-5 max-sm:mb-4 text-2xl font-bold text-center">Payment Completed!</h6>
                        <div className="bg-smoothGreen rounded-full w-[35px] h-[35px] flex justify-center items-center mb-10 max-lg:mb-[35px] max-sm:mb-10">
                            <Checkmark className="w-[23.33px] h-[17.5px] fill-white" />
                        </div>
                        <div className="text-center bg-secondary py-5.5 px-2.5 rounded-[10px] mb-10 max-lg:mb-[46px] max-sm:mb-10  max-w-[335px] lg:hidden">
                            <h6 className="font-bold">
                                Optimal Experience Tip
                            </h6>
                            <p>
                                To fully benefit from ZIK's features,
                                we recommend using it on desktop or laptop.
                            </p>
                        </div>
                        <Button
                            loading={loading}
                            title="Start using ZIK!"
                            className="max-w-[232px] max-lg:max-w-[350px] max-sm:mt-auto max-sm:bg-darkBlue max-sm:text-smoothGreen hover:text-white hover:bg-darkBlue"
                            titleClassName="font-bold !text-base"
                            height="h-[60px]"
                            handleClick={() => navigate("/")}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RegistrationPaymentCompleted