import { PAGE_SIZE } from "config/constants";
import { useCallback, useEffect, useState } from "react";

const usePagination = (
  initialTotal = 0,
  initialPageSize: number = PAGE_SIZE
) => {
  const [current, setCurrent] = useState<number>(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [total, updateTotal] = useState(initialTotal);
  const handleTotalChange = useCallback(() => {
    updateTotal(initialTotal);
    setCurrent(0);
  }, [initialTotal]);

  const handlePageSizeChange = useCallback(() => {
    setPageSize(initialPageSize);
    setCurrent(0);
  }, [initialPageSize]);

  useEffect(() => {
    handleTotalChange();
  }, [handleTotalChange]);

  useEffect(() => {
    handlePageSizeChange();
  }, [handlePageSizeChange]);

  const totalPages = Math.ceil(total / pageSize);
  const hasNext = current < totalPages - 1;
  const hasPrev = current > 0;

  const changePageSize = (newSize: number) => setPageSize(newSize);
  const nextPage = () => setCurrent((prev) => prev + 1);
  const prevPage = () => setCurrent((prev) => prev - 1);
  const goto = (page: number) => setCurrent(page);
  const setTotal = (total: number) => updateTotal(total);
  const begin = current * pageSize;
  const end = begin + pageSize;

  return {
    current,
    pageSize,
    totalPages,
    hasNext,
    goto,
    hasPrev,
    changePageSize,
    nextPage,
    prevPage,
    setTotal,
    total,
    begin,
    end,
  };
};

export default usePagination;
