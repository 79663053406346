import { Table as ReactTable } from "@tanstack/react-table";
import { SortDir } from "hooks/useSort";
import { TableStyles } from "./Table";
import TableBodyRow from "./TableBodyRow";
import TableEmpty from "./TableEmpty";
import TableHeadRow from "./TableHeadRow";

interface Sort {
  direction: SortDir;
  column: string;
}
interface Props<T> {
  table: ReactTable<T>;
  sort?: Sort
  isLoading?: boolean;
  hideTableHead?: boolean;
  hideTableBody?: boolean;
  styles?: TableStyles;

}

const Table = <T extends object = {}>({
  table,
  styles,
  sort,
  isLoading,
  hideTableHead,
  hideTableBody,
}: Props<T>) => {
  const tableStyles = `w-full h-full table-auto border-separate border-spacing-y-2 text-xs -mt-2 text-darkBlue dark:text-grey100 ${styles?.minWidth || "min-w-440px"
    } ${styles?.tableClassName || ""}`;

  const { rows } = table.getRowModel();
  const headerGroups = table.getHeaderGroups();

  return (
    <div
      className={`max-[1400px]:overflow-x-auto scrollbar w-full ${styles?.px || "px-4"
        }  ${styles?.style || ""}`}
    >
      <table className={tableStyles} style={{ width: "100%" }}>
        <thead className={hideTableHead ? "hidden" : ""}>
          {headerGroups.map((headerGroup, i) => (
            <TableHeadRow<T>
              sort={sort}
              headerGroup={headerGroup}
              key={i}
              getColumn={table.getColumn}
              styles={styles && styles.head}
            />
          ))}
        </thead>
        <tbody
          className={`${hideTableBody ? "hidden" : ""} ${rows.length > 0 ? "" : "relative"
            } ${isLoading ? "relative" : ""}`}
        >
          {rows.length > 0 && !isLoading
            ? rows.map((row, i) => (
              <TableBodyRow<T>
                row={row}
                key={i}
                styles={styles && styles.body}
              />
            ))
            : headerGroups.map((headerGroup, i) => (
              <TableEmpty<T>
                styles={styles && (styles.empty || styles.body)}
                headerGroup={headerGroup}
                isLoading={isLoading || false}
                key={i}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
