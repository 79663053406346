import { ReactComponent as Logo } from "assets/icons/logo.svg";
import Loading from "components/Loading";
import useDecodeToken from "hooks/useDecodeToken";
import { useGetPaymentInvoiceQuery } from "store/api/managePaymentApi";
import { formatCurrency } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";
import { getPlanDuration, getPlanName } from "utils/planUtils";

interface Props {
    id: string;
    paymentType?: string | null;
}

const InvoicePaymentContent = ({ id, paymentType }: Props) => {
    const user = useDecodeToken();
    const { data, isLoading } = useGetPaymentInvoiceQuery({ type: paymentType! + '-BLUESNAP', paymentId: Number(id) });

    const monthName = new Date(data?.result.payment?.transactionDate || "").toLocaleString('en-US', { month: 'long' });;

    return (
        <>
            {
                isLoading ? (
                    <div className="max-w-[740px] w-full flex justify-center items-center h-[500px]">
                        <Loading fill="fill-primaryPurple dark:fill-purple500" height={48} width={48} />
                    </div>
                ) : (<div className="border-2 border-secondary rounded-[20px] max-w-[740px] min-w-[650px] px-8 py-7 text-sm bg-white">
                    <div className="flex justify-between">
                        <div className="flex items-center h-fit gap-2 max-xl:justify-center">
                            <Logo className="w-[30px] h-[30px] max-xl:w-9 max-xl:h-9" />
                            <h1 className="text-4xl max-xl:text-3xl">ZIK</h1>
                        </div>

                    </div>

                    <div className="flex justify-between">
                        <div className="w-1/2 mt-[23px]">
                            <p>
                                <span className="font-bold">N.G.C.A Technology Enterprises LTD</span><br />
                                1 Arpiliou, 8 Aristo Centre, Block B, Floor B<br />
                                Office 204<br />
                                8011 Paphos, Cyprus<br />
                                <span className="font-bold">Email:</span> info@zikanalytics.com
                            </p>
                            <p className="mt-[15px] mb-[15px]">
                                <span className="font-bold"> Company ID:</span> HE 370976<br />
                                <span className="font-bold">Tax ID:</span> CY 10370976V
                            </p>
                            <p>
                                <span className="font-bold">Bill to:</span> <span className="capitalize">{user.unique_name} <br></br> {data?.result.address}</span><br />
                                <span className="font-bold">Country:</span> {user.country}<br />
                                <span className="font-bold">Email:</span> {user.email}<br />
                                {data?.result.vatNumber ? <span> <span className="font-bold"> VAT number:</span> {data?.result.vatNumber ? data?.result.vatNumber : "N/A"}</span> : ""}
                            </p>
                        </div>
                        <div>
                            <h5 className="uppercase font-bold text-base mb-[9px] mt-[-25px]">Invoice</h5>
                            <p className="mb-5">
                                Invoice: <span>{data?.result.payment?.transactionId}</span>
                            </p>
                            <p>
                                <span className="font-bold">Balance Due:</span> $<span>{data?.result.payment.amount}</span>
                            </p>
                            <p>
                                Date: <span>{formatDate(new Date(), "DD MMM YYYY")}</span>
                            </p>
                            <p className="mb-5">
                                Due Date: <span>{formatDate(data?.result.payment.transactionDate, "DD MMM YYYY")}</span>
                            </p>

                            <p>
                                <span className="font-bold">Payment Method:</span> <br /><span className="capitalize">{data?.result.payment.paymentMethod.replace("_", " ").toLowerCase()}</span>
                            </p>
                        </div>
                    </div>




                    <div className="mb-[55px] mt-[37px] font-medium">
                        <div className="flex uppercase border-2 border-secondary rounded-10 px-9 justify-between py-[11px] mb-2">
                            <div>
                                <p>Description</p>
                            </div>
                            <div className="flex gap-14">
                                <p>Amount Paid</p>
                            </div>
                        </div>
                        <div className="flex bg-neutral2 py-[13px] justify-between px-9 rounded-10 mb-[30px]">
                            <div>
                                <p><span className="capitalize">{monthName}</span> Payment: {getPlanDuration(data?.result.payment.blueSnapSubscription.type || "", data?.result.payment.blueSnapSubscription.billingInterval || 0)} {getPlanName(data?.result.payment.blueSnapSubscription.type || "")} Subscription</p>
                            </div>
                            <div>
                                <p>{formatCurrency(data?.result.payment.amount || 0)}</p>
                            </div>
                        </div>
                        <div className="flex max-w-[250px] w-full justify-between pt-3.5 ml-auto mr-9">
                            <p>Subscription Price:</p>
                            <p>{formatCurrency(data?.result.payment.amount || 0)}</p>
                        </div>
                        <div className="flex max-w-[250px] w-full justify-between pt-3.5 ml-auto mr-9">
                            <p>VAT:</p>
                            <p>({data?.result.payment.vatpercentage}%) <span className="ml-[2px]">{formatCurrency(data?.result.payment.vatamount || 0)}</span></p>
                        </div>
                        <div className="flex max-w-[250px] w-full justify-between pt-3.5 border-t-2 border-secondary ml-auto mr-9 mt-[25px]">
                            <p>Total:</p>
                            <p>{formatCurrency((data?.result.payment.amount || 0) + (data?.result.payment.vatamount || 0))}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">

                        <h5 className="font-bold text-base">Thank you!</h5>
                    </div>
                </div >)
            }
        </>

    )
}

export default InvoicePaymentContent