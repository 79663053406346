import { ReactComponent as Amazon } from "assets/icons/amazon-f.svg";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as Other } from "assets/icons/other-seller.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Button from "components/Button";
import Modal from "components/Modal";
import Toaster from "components/Toast";
import { BLUESNAP_SCRIPT } from "config/config";
import { REGISTRATION_BACKGROUND } from "config/constants";
import useExternalScript from "hooks/useExternalScript";
import useModal from "hooks/useModal";
import { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { JSX } from "react/jsx-runtime";
import { useLazyGetPfTokenQuery } from "store/api/managePaymentApi";
import {
  useSaveFunnelMutation,
  useSaveSalesChannelMutation,
  useSaveSellerTypeMutation
} from "store/api/onboardingApi";
import { TSelectedPlan } from "types/plans";
import RegistrationFunnelNavbar from "./Navbar";
import "./Stepper.css";
import Introduction from "./steps/Introduction";
import Payment from "./steps/Payment";
import Questionnaire from "./steps/Questionnaire";
import SalesChannel from "./steps/SalesChannel";
import SelectPlan from "./steps/SelectPlan";

const modalMaxWidth = [
  "max-w-[717px]", "max-w-[910px]", "max-w-[900px]", "max-w-[1299px]", "max-w-[1060px]"
]
const RegistrationFunnel = () => {
  const [introStep, setIntroStep] = useState<0 | 1 | 2>(0)
  const [step, setStep] = useState(0);
  const [method, setMethod] = useState("")
  const modal = useModal();
  const [saveSellerType, { isLoading: addLoading }] =
    useSaveSellerTypeMutation();
  const [mainChannel, setMainChannel] = useState<number | null>(null);
  const [channels, setChannels] = useState<number[]>([]);
  const [saveSalesChannel, { isLoading: loadingChannel }] =
    useSaveSalesChannelMutation();
  const [saveQuestionaire, { isLoading: funnelLoading }] = useSaveFunnelMutation();
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<TSelectedPlan>({
    type: "",
    planName: "",
    planPrice: 0,
    skip: false
  });
  useEffect(() => {
    modal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [getPfToken] = useLazyGetPfTokenQuery();
  useEffect(() => {
    getPfToken(null)
      .unwrap()
      .then((data) => {
        window.gpfToken = data.message;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useExternalScript(BLUESNAP_SCRIPT, "");
  const saveSeller = async (seller: boolean) => {
    //saveSeller(seller);
    await saveSellerType({ isSeller: seller }).unwrap().then(() => {
      setStep(step + 1);
    });
  };

  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    if (step === 0 && introStep === 1) { setIntroStep(0) }
    if (step === 0 && introStep === 2) { setIntroStep(1) }
    if (step > 0 && step !== 4) { setStep(step - 1); }
    if (step === 4) {
      if (method === "") {
        setStep(3)
      } else {
        setMethod("")
      }
    }

  };

  const handleMethod = (type: string) => {
    setMethod(type);
  };

  const handleChannelSelection = (channel: number) => {
    if (mainChannel === null) setMainChannel(channel);
    if (!channels.includes(channel)) {
      var newChannels = [...channels, channel];
      setChannels(newChannels);
    } else {
      var indexToRemove = channels.indexOf(channel);
      const newChannelsList = [
        ...channels.slice(0, indexToRemove),
        ...channels.slice(indexToRemove + 1),
      ];
      setChannels(newChannelsList);
      if (mainChannel === channel) {
        setMainChannel(newChannelsList[0] || null);
      }
    }
  };

  const handleSaveChannel = () => {
    saveSalesChannel({ mainChannel, salesChannels: channels }).unwrap().then(() => {
      handleNext();
    });
  };

  const handleSaveQuestionaire = () => {
    saveQuestionaire({ funnelValues: selectedAnswers }).unwrap().then(() => {
      handleNext();
    });
  }

  const resolveSalesChannel = (hideOther?: boolean) => {
    if (step === 2 && channels.length > 0) {
      let iconList: JSX.Element[] = [];

      // eslint-disable-next-line array-callback-return
      channels.map((channel) => {
        if (channel === 1)
          iconList.push(
            <Ebay className="w-[56px] h-[31px]" />
          );
        if (channel === 2)
          iconList.push(
            <Amazon className="w-[76px] h-6" />
          );
        if (channel === 3)
          iconList.push(
            <Shopify className="w-[86px] h-6" />
          );
        if (channel === 4 && !hideOther)
          iconList.push(
            <Other className="w-[60px] h-[28px]" />
          );
      });

      return iconList;
    } else {
      return (
        <Ebay className="w-[56px] h-[31px] invisible" />
      );
    }
  };

  const handleSelectAnswer = (answerId: string) => {
    if (!selectedAnswers.includes(answerId)) {
      var newAnswers = [...selectedAnswers, answerId];
      setSelectedAnswers(newAnswers);
    } else {
      var indexToRemove = selectedAnswers.indexOf(answerId);
      const newAnswers = [
        ...selectedAnswers.slice(0, indexToRemove),
        ...selectedAnswers.slice(indexToRemove + 1),
      ];
      setSelectedAnswers(newAnswers);
    }
  };

  const stepStyleConfig = {
    activeBgColor: "#1BCFBA",
    completedBgColor: "#ECF5FF",
    inactiveBgColor: "#ECF5FF",
    activeTextColor: "#150D3F",
    inactiveTextColor: "#B0B6BD",
    completedTextColor: "#B0B6BD",
    size: 36,
    circleFontSize: 16,
    labelFontSize: 16,
    borderRadius: 100,
    fontWeight: 500,
  };
  const checkActive = (isActive: boolean) => isActive ? "stepper" : "inactive-stepper"
  const handleSelectPlan = (newVal: TSelectedPlan) => {
    setSelectedPlan(newVal);
    handleNext();
  };

  return (
    <>
      <img src={REGISTRATION_BACKGROUND} alt="ZIK Dashboard" className="min-h-screen min-w-screen bg-cover w-full max-lg:hidden" />

      <Modal
        helpers={modal}
        className="flex flex-col items-center max-lg:max-w-full max-lg:h-full"
        cardClassName={`flex flex-col lg:justify-center max-lg:max-w-full max-lg:h-full max-lg:rounded-none max-lg:p-0 max-lg:px-5
          lg:max-h-[40rem] xl:max-h-[47rem]
          ${step === 0 ? introStep === 2 ? "pt-[2px] pb-[37px]" : "py-[42px]" : step === 5 ? "pt-[31px] pb-[41px]" : "pt-[2px] pb-[35px]"} 
        `}
        maxWidth={modalMaxWidth[step]}
        buttonCloseClassName="!top-[22px] right-[21px]"
        overlay
        hideBorder
        alwaysOpen
      >
        <RegistrationFunnelNavbar handleBack={handleBack} step={step} introStep={introStep} />
        {(step === 0 && introStep === 0 || introStep === 1) || step === 5 ? "" : (
          <div className={`flex justify-between max-lg:justify-center ${step === 4 ? "absolute" : ""} w-full top-[0px]`}>
            <Button
              title="Back"
              height="h-9"
              titleClassName="font-bold"
              className={`z-40 max-w-[104px] w-full ml-[35px] bg-white max-lg:hidden mt-[25px] ${step === 0 ? "invisible" : ""}`}
              handleClick={handleBack}
              StartIcon={LeftArrow}
            />
            {(step < 4 && introStep > 1) && (
              <Stepper
                activeStep={step}
                className="bg-green max-w-[416px] grow"
                styleConfig={stepStyleConfig}

              >
                <Step
                  className={checkActive(step === 0)}
                  onClick={() => setStep(0)}
                />
                <Step
                  className={checkActive(step === 1)}
                  onClick={() => setStep(1)}
                />
                <Step
                  className={checkActive(step === 2)}
                  onClick={() => setStep(2)}
                />
                <Step
                  className={checkActive(step === 3)}
                  onClick={() => setStep(3)}
                />
              </Stepper>
            )}
            <div className="flex flex-col gap-3 mt-[30px] max-lg:hidden">
              <div className="self-center w-[80px] h-[30px] mr-10 invisible" />
            </div>
          </div>
        )}
        {step === 2 && channels.length > 0 && (
          <div className="lg:hidden flex items-center justify-center gap-2.5 mt-4 mb-5.5">
            {resolveSalesChannel(true)}
          </div>
        )}

        <div className="max-h-full overflow-y-auto sm:scrollbar w-full">
          {step === 0 && (
            <Introduction
              onSetSeller={saveSeller}
              loading={addLoading}
              introStep={introStep}
              onIntroStepChange={(step) => setIntroStep(step)}
            />
          )}
          {step === 1 && (
            <SalesChannel
              channels={channels}
              handleChannelSelection={handleChannelSelection}
              loading={loadingChannel}
              handleSave={handleSaveChannel}
            />
          )}
          {step === 2 && (
            <Questionnaire
              selectedAnswers={selectedAnswers}
              handleSelectAnswer={handleSelectAnswer}
              loading={funnelLoading}
              handleSave={handleSaveQuestionaire}
            />
          )}
          {step === 3 &&

            <SelectPlan handleSelectPlan={handleSelectPlan} />
          }
        </div>
        {step === 4 && <Payment selectedPlan={selectedPlan} handleMethod={handleMethod} method={method} />}
        <div className="absolute top-[28px] right-[27px] flex flex-col gap-2 max-lg:hidden">
          {resolveSalesChannel(true)}
        </div>
      </Modal>

      <Toaster />

    </>
  );
};
export default RegistrationFunnel;
