import Card from "components/Card";

// Icons
import { paymentHistoryCols } from "assets/tableColumns/paymentHistory";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";

import { useGetSubscriptionHistoryQuery } from "store/api/managePaymentApi";

const tableStyles: TableStyles = {
  minWidth: "min-w-[925px]",
  px: "px-0",
  head: { th: "font-bold" },
  style: "mb-7",
  body: { contHeight: "h-[37px]", rowHeight: "h-[55px]", cont: "font-medium" },

};

const PaymentHistory = () => {
  const { data, isFetching } = useGetSubscriptionHistoryQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { table } = useTable({
    columns: paymentHistoryCols,
    data: data?.result.blueSnapPayments || [],
    dataDeps: [data],
    pagination: true,
  });

  return (
    <>
      <Card className="px-4 py-7 mb-4">
        <span className="font-bold ml-11 mb-6 block dark:text-grey100">Billing History</span>
        <Table table={table} styles={tableStyles} isLoading={isFetching} />
        <Pagination table={table} className="justify-between" labeled />
      </Card>
    </>
  );
};

export default PaymentHistory;
