import { ReactComponent as ArrowLeftCurved } from "assets/icons/arrow-left-curved.svg";
import SmileyCelebrate from 'assets/images/smiley-celebrate.png';
import Plans from "components/Plans/Index";
import PlansComparison from "components/Plans/PlansComparison";
import PricingUsesCounter from "components/Plans/PricingUsesCounter";
import SettingTabs from "components/SettingsTabs/Index";
import SettingsTab from "components/SettingsTabs/SettingsTab";
import usePlans from "hooks/usePlans";
import { useEffect, useState } from "react";
import { TSelectedPlan } from "types/plans";
import { gtmOnboardingPlansScreenView } from "utils/googleTagManager";

interface Props {
  handleSelectPlan: (newVal: TSelectedPlan) => void;
}

type PlanId = "pro" | "pro-plus" | "enterprise"
const planTitles: { title: string; id: PlanId }[] = [{ title: "Pro", id: "pro" }, { title: "Pro+", id: "pro-plus" }, { title: "Enterprise", id: "enterprise" }]
const SelectPlan = ({ handleSelectPlan }: Props) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanId>("pro")
  const planSwitchData = ["monthly", "yearly"];
  const { plans, handlePlanChange } = usePlans("monthly");
  const hasPricingTabBadge = false;

  useEffect(() => {
    gtmOnboardingPlansScreenView(); // google tag manager event that fires when user land on this page
  }, [])

  return (
    <div className="flex flex-col justify-center items-center">

      <img src={SmileyCelebrate} className="w-[40px] h-[40px]" />
      <h2 className="text-[18px] font-normal text-darkBlue leading-[28px]">
        <span className="font-bold bg-clip-text text-transparent bg-[linear-gradient(90deg,_rgb(86,_25,_253)_32.81%,_rgb(228,_223,_252)_100.07%)]">Limited Time:</span>
        {" "}50% Off All Yearly Plans
      </h2>
      {/* <h2 className="text-center leading-[28px] text-[18px] mb-[8px] max-md:hidden">Choose one of the following Plans</h2> */}

      <PricingUsesCounter />
      <div className="relative">
        {hasPricingTabBadge && (<div className="flex gap-[10px] absolute left-[calc(100%+11px)] top-[-16px] max-md:hidden">
          <ArrowLeftCurved className="text-smoothGreen" />
          <div className="w-[162px] h-[42px] flex justify-center items-center bg-primaryPurple dark:bg-purple500 border-[2px] border-smoothGreen rounded-[9px] px-[5px] py-[7px] text-white gap-[5px] shadow-shadow3" >
            2 Months Free
          </div>
        </div>)}



        <SettingTabs
          className=" !p-[2px] mb-6"
          tabs={
            <>
              <SettingsTab
                title="Monthly"
                className="min-w-[78px] h-8"
                isActive={plans.period === planSwitchData[0]}
                handleClick={() => handlePlanChange(planSwitchData[0])}
              />
              <SettingsTab
                title="Yearly"
                className="min-w-[78px] h-8"
                isActive={plans.period === planSwitchData[1]}
                handleClick={() => handlePlanChange(planSwitchData[1])}
              />
            </>
          }
        />
      </div>

      <div className="flex items-center justify-center gap-[13px] lg:hidden mb-5 w-full max-w-[310px]" >
        {planTitles.map((item) =>
          <div
            key={item.id}
            className={`px-[17px] py-[16px] flex uppercase items-center justify-center border-2 border-secondary rounded-[15px] font-bold text-sm w-full 
            ${item.id === selectedPlan ? "bg-darkBlue text-white" : "bg-neutral1 text-darkBlue dark:text-grey100"}`}
            onClick={() => setSelectedPlan(item.id)}
          >
            {item.title}
          </div>)}
      </div>
      <div className="">
        <Plans
          plans={plans}
          selectedPlan={selectedPlan}
          currentPlan={""}
          handleSelectPlan={handleSelectPlan}
          fromRegistration={true}
        />
      </div>
      <div className="mx-auto max-w-[1284px] w-full">
        <PlansComparison
          plans={plans}
          selectedPlan={selectedPlan}
          hasFooter={true}
          contStyles="px-8 !max-w-[1284px] max-md:px-3 !mt-5"
          currentPlan={""}
          handleSelectPlan={handleSelectPlan}
          fromRegistration={true}
        />
      </div>
    </div >

  );

};
export default SelectPlan;
