import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { BASE_URL } from 'config/config';
import { PATH } from 'routes/path';
import { RootState } from 'store';
import { GetAccessToken } from 'utils/localStorage';
import { makeQueryParams } from 'utils/makeQueryParams';

export type BaseQueryFn<
  Args = any,
  Result = unknown,
  Error = unknown,
  DefinitionExtraOptions = {},
  Meta = {}
> = (
  args: Args,
  api: BaseQueryApi,
  extraOptions: DefinitionExtraOptions,
  timeout?: number
) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

const baseQueryPrepareHeaders = (timeout: number) => {
  return fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken =
        (getState() as RootState).user.result?.accessToken || GetAccessToken();

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
    timeout: timeout || 100000,
  });
};

const baseQuery: BaseQueryFn = async (
  args,
  api,
  extraOptions,
  timeout = 100000
) => {
  const baseQueryInstance = baseQueryPrepareHeaders(timeout);

  let result = await baseQueryInstance(args, api, extraOptions);
  const { error } = result;
  const logout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('user');
    window.location.replace(
      makeQueryParams(PATH.login, {
        redir: encodeURIComponent(
          window.location.pathname + window.location.search
        ),
      })
    );
  };

  if ((error && error.status === 401) || error?.status === 403) {
    if (error && error.status === 401) {
      logout();
    }
  }

  return result;
};

export default baseQuery;
