import ProductNameCell from "components/ProductNameCell";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { PATH } from "routes/path";
import { RootState } from "store";
import {
  MARKET_PLACES,
  SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import { TrendingProductList } from "types/services/newDashboard";
import ProductInnerTable from "./ProductInnerTable";

import { ReactComponent as AlibabaBoxHovered } from "assets/icons/boxes/alibaba-hovered.svg";
import { ReactComponent as AlibabaBox } from "assets/icons/boxes/alibaba.svg";
import { ReactComponent as AliexpressBoxHovered } from "assets/icons/boxes/aliexpress-hovered.svg";
import { ReactComponent as AliexpressBox } from "assets/icons/boxes/aliexpress.svg";
import { ReactComponent as AmazonBoxHovered } from "assets/icons/boxes/amazon-hovered.svg";
import { ReactComponent as AmazonBox } from "assets/icons/boxes/amazon.svg";
import { ReactComponent as EbayBox } from "assets/icons/boxes/box.svg";
import { ReactComponent as WalmartBoxHovered } from "assets/icons/boxes/walmart-hovered.svg";
import { ReactComponent as WalmartBox } from "assets/icons/boxes/walmart.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as SellerScan } from "assets/icons/seller-scan.svg";

import HoveredIcon from "components/HoveredIcon";
import IconFinder from "components/IconFinder";
import useModal from "hooks/useModal";
import { Link } from "react-router-dom";
import { CURRENCY } from "types/currency";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";

interface Props extends TrendingProductList {
  isFirst?: boolean;
  isFire?: boolean;
  ascending: boolean;
  handleSort: (by: keyof TrendingProductList) => void
}

const AmazonIcon = {
  Icon: AmazonBox,
  HoveredIcon: AmazonBoxHovered,
};
const sourcingFromBoxes = {
  [SOURCE_MARKET_PLACES.amazon]: AmazonIcon,
  [SOURCE_MARKET_PLACES.amazonUk]: AmazonIcon,
  [SOURCE_MARKET_PLACES.amazonAu]: AmazonIcon,
  [SOURCE_MARKET_PLACES.walmart]: {
    Icon: WalmartBox,
    HoveredIcon: WalmartBoxHovered,
  },
  [SOURCE_MARKET_PLACES.aliexpress]: {
    Icon: AliexpressBox,
    HoveredIcon: AliexpressBoxHovered,
  },
  [SOURCE_MARKET_PLACES.alibaba]: {
    Icon: AlibabaBox,
    HoveredIcon: AlibabaBoxHovered,
  },
  [SOURCE_MARKET_PLACES.ebay]: {
    Icon: EbayBox,
  },
  [SOURCE_MARKET_PLACES.none]: AmazonIcon,
};

const amazonSite = {
  [SOURCE_MARKET_PLACES.amazon]: "US",
  [SOURCE_MARKET_PLACES.amazonUk]: "UK",
  [SOURCE_MARKET_PLACES.amazonAu]: "AU",
  [SOURCE_MARKET_PLACES.walmart]: "WAL",
  [SOURCE_MARKET_PLACES.aliexpress]: "Ali",
  [SOURCE_MARKET_PLACES.alibaba]: "Baba",
  [SOURCE_MARKET_PLACES.ebay]: "US",
  [SOURCE_MARKET_PLACES.none]: "US",
};

const ProductRow: FC<Props> = ({
  title,
  sales,
  totalSold,
  price,
  profit,
  feedback,
  image,
  seller,
  itemId,
  isFirst,
  isFire,
  asin,
  ascending,
  handleSort
}) => {
  const { sourceMarketplace, marketplace, currency } =
    useAppSelector((state: RootState) => state.sellerSettings);

  const modal = useModal();

  const hideIconFinder =
    marketplace === MARKET_PLACES.shopify ||
    marketplace === MARKET_PLACES.woocommerce;

  const sourcingMarketplaceIcon =
    sourceMarketplace?.includes("amazon") ? SOURCE_MARKET_PLACES.amazon :
      sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress ? SOURCE_MARKET_PLACES.aliexpress :
        sourceMarketplace === SOURCE_MARKET_PLACES.walmart ? SOURCE_MARKET_PLACES.walmart :
          SOURCE_MARKET_PLACES.alibaba;

  const isAmazonOrWalmart = sourceMarketplace?.includes("amazon") || sourceMarketplace === SOURCE_MARKET_PLACES.walmart
  const getProductUrl = () => {
    if (
      [MARKET_PLACES.shopify, MARKET_PLACES.woocommerce].includes(marketplace)
    ) {
      if (sourceMarketplace === "aliexpress") {
        return `https://${sourceMarketplace}.us/item/${itemId}.html`;
      } else {
        return `https://${sourceMarketplace}.com/itm/${itemId}`;
      }
    }
    if (sourceMarketplace === "aliexpress") {
      return `https://ebay.com/itm/${itemId}`; //for now, aliexpress will always send user to ebay.com
    }

    return `https://${marketplace}/itm/${itemId}`;
  };

  const getSource = () => {
    if (sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress && ([MARKET_PLACES.shopify, MARKET_PLACES.woocommerce].includes(marketplace))) {
      return "aliexpress";
    } else {
      return "ebay";
    }
  };

  return (
    <div className="py-7 px-5 border-2 border-secondary rounded-10  dark:border-deepSpace800">
      <div className={`grid gap-1.5 items-center text-xs text-darkBlue dark:text-grey100
        ${hideIconFinder ? "grid-cols-[45%_7%_47%]" : "grid-cols-[41%_6%_46%_6%]"}`}
      >
        <div className="flex gap-1.5 items-center">
          <div className="w-[6rem]">
            <ProductNameCell
              img={image}
              maxHeight="max-h-[65px]"
              hoveredImgClassName={`left-[75px] ${isFirst ? "-top-8" : ""}`}
              width="80px"
              isFirst={isFirst}
              height="61px"
            />
          </div>
          <div className="h-[45px] flex items-center overflow-hidden">
            <Link
              className="hover:text-primaryPurple dark:hover:text-purple500 cursor-pointer flex items-center w-full m-auto"
              to={getProductUrl()}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </Link>
          </div>
        </div>

        <div className="align-middle col-span-1">
          <Link
            to={makeQueryParams(PATH.competitorResearch[getSource()], {
              search: seller,
            })}
            target="_blank"
            rel="noreferrer"
          >
            <HoveredIcon
              Icon={isFire ? SellerScan : CompetitorResearch}
              height="h-9"
              title={seller}
              width="w-9"
              className="m-auto"
              iconClassName={`${isFire ? "w-[25px] h-[23px]" : "w-5 h-5"}`}
            />
          </Link>
        </div>

        <div className="col-span-1 align-middle">
          <ProductInnerTable
            sales={sales}
            price={price}
            totalSold={totalSold}
            profit={profit}
            feedback={feedback}
            ascending={ascending}
            handleSort={handleSort}
          />
        </div>

        {!hideIconFinder && sourcingMarketplaceIcon ? (
          <div className="col-span-1 align-middle">
            <div className="relative w-fit">
              {isAmazonOrWalmart ? (
                <div className="absolute bg-spaceGreen right-1 top-0 w-[15px] h-[15px] flex items-center justify-center rounded-full">
                  <Checkmark className="fill-darkBlue w-2 h-1.5" />
                </div>
              ) : ""}
              <HoveredIcon
                {...sourcingFromBoxes[sourcingMarketplaceIcon]}
                handleClick={modal.open}
                iconClassName="w-[23px] h-[24.5px]"
                hoveredIconClassName="w-[23px] h-[24.5px]"
                height="h-9"
                width="w-9"
              />
            </div>

          </div>
        ) : (
          ""
        )}
      </div>

      <IconFinder
        helpers={modal}
        marketplace={
          amazonSite[sourceMarketplace || SOURCE_MARKET_PLACES.alibaba]
        }
        item={{
          title,
          price,
          sold: sales,
          img: image,
          url: getProductUrl(),
          id: itemId,
          isSimilar: true,
          isGreen: true,
          asin,
        }}
        currency={
          currency === CURRENCY.dollar
            ? ("$" as CURRENCY_SIGNS)
            : currency === CURRENCY.euro
              ? ("€" as CURRENCY_SIGNS)
              : currency === CURRENCY.pound
                ? ("£" as CURRENCY_SIGNS)
                : ("RM" as CURRENCY_SIGNS)
        }
        sourcePlace={marketplace}
        dashboardMarketPlace={true}
      />
    </div>
  );
};

export default ProductRow;
