import { createApi } from "@reduxjs/toolkit/query/react";
import {
  FeedbackResponse,
  OnBoardingQuestionResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  SalesChannelsResponse,
  SaveFunnelRequest,
  SavePotentialReq,
  SavePotentialRes,
  SaveSalesChannelRequest,
  SaveSellerTypeRequest,
} from "types/services/onboarding";
import { makeQueryParams } from "utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  baseQuery,
  endpoints: (builder) => ({
    registerUser: builder.mutation<RegisterUserResponse, RegisterUserRequest>({
      query: (body) => ({
        url: queryKeys.onboarding.register,
        method: "POST",
        body,
      }),
    }),
    getOnBoardingQuestion: builder.query<OnBoardingQuestionResponse, number>({
      query: (stepNumber: number) => ({
        url: makeQueryParams(queryKeys.onboarding.getOnBoardingQuestion, {
          stepNumber,
        }),
        method: "GET",
      }),
    }),
    saveSellerType: builder.mutation<void, SaveSellerTypeRequest>({
      query: (body) => ({
        url: queryKeys.onboarding.postSellerType + `?isSeller=${body.isSeller}`,
        method: "POST",
      }),
    }),
    saveSalesChannel: builder.mutation<void, SaveSalesChannelRequest>({
      query: (body) => ({
        url: queryKeys.onboarding.postSalesChannel,
        method: "POST",
        body,
      }),
    }),
    getSalesChannels: builder.query<SalesChannelsResponse, void>({
      query: () => ({
        url: queryKeys.onboarding.getOnBoardingQuestion,
        method: "GET",
      }),
    }),
    saveFunnel: builder.mutation<void, SaveFunnelRequest>({
      query: (body) => ({
        url: queryKeys.onboarding.postFunnel,
        method: "POST",
        body,
      }),
    }),
    saveFeedback: builder.mutation<void, string>({
      query: (body) => ({
        url: queryKeys.onboarding.postFeedback,
        method: "POST",
        body: { feedback: body },
      }),
    }),
    savePotentialUser: builder.mutation<SavePotentialRes, SavePotentialReq>({
      query: (body) => ({
        url: queryKeys.onboarding.postPotentialUser,
        method: "POST",
        body,
      }),
    }),
    getFeedback: builder.query<FeedbackResponse, void>({
      query: () => ({
        url: queryKeys.onboarding.getFeedback,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useGetOnBoardingQuestionQuery,
  useSaveSellerTypeMutation,
  useSaveSalesChannelMutation,
  useGetSalesChannelsQuery,
  useSaveFeedbackMutation,
  useSaveFunnelMutation,
  useSavePotentialUserMutation,
  useGetFeedbackQuery,
} = onboardingApi;
