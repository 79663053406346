import { createApi } from "@reduxjs/toolkit/query/react";
import {
  PostCrAliRequest,
  PostCrAliResponse,
  PostCrEbayRequest,
  PostCrEbayResponse,
  PostCrSaveBulkRequest,
} from "types/services/cr";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const crApi = createApi({
  reducerPath: "cr",
  baseQuery,
  endpoints: (builder) => ({
    crEbay: builder.mutation<PostCrEbayResponse, PostCrEbayRequest>({
      query: (body) => ({
        url: queryKeys.cr.getEbay,
        method: "POST",
        body,
      }),
    }),

    crSaveBulk: builder.mutation<null, PostCrSaveBulkRequest>({
      query: (body) => ({
        url: queryKeys.cr.saveBulk,
        method: "POST",
        body: body,
      }),
    }),

    crAli: builder.mutation<PostCrAliResponse, PostCrAliRequest>({
      query: (body) => ({
        url: queryKeys.cr.getAli,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useCrAliMutation, useCrEbayMutation, useCrSaveBulkMutation } =
  crApi;
