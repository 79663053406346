import Modal from "components/Modal";
import Scrollbar from "components/Scrollbar";
import VideoEmbed from "components/VideoEmbed/Index";

interface Props {
  helpers: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
  };
  videos: {
    title: string;
    source: string;
  }[];
  setIgnoreClose?: (close: boolean) => void;
}

const HelpVideo = ({ helpers, videos, setIgnoreClose }: Props) => (
  <Modal
    helpers={helpers}
    className="flex flex-col items-center "
    cardClassName="shadow-none h-[100vh] !bg-gray-200 !bg-opacity-90 !rounded-none"
    maxWidth="max-w-[100vw]"
    title=""
    hideBorder
    buttonCloseClassName="!top-[22px] right-[21px]"
    handleCloseClick={() => setIgnoreClose && setIgnoreClose(false)}
  >
    <Scrollbar className="h-full p-[50px]">
      <div className="max-w-[950px] w-full mx-auto">
        {videos.map((video, i) => (
          <div key={i} className="mb-[30px]">
            <div className="font-bold mb-2.5 dark:text-darkBlue">{video.title}</div>
            <VideoEmbed
              source={video.source}
              className="rounded-[8px] shadow-lg"
            />
          </div>
        ))}
      </div>
    </Scrollbar>
  </Modal>
);

export default HelpVideo;
