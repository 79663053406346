import { ReactComponent as Trophy } from "assets/icons/trophy.svg";
import Dots from "components/Dots";
interface UpgradeProps {
    isUpgrade?: boolean;
    isDowngrade?: boolean;
    isChanged?: boolean;
    isRegistration?: boolean;
}
const Upgrade: React.FC<UpgradeProps> = ({ isUpgrade = false, isChanged = false, isDowngrade = false, isRegistration = false }) => (
    <div className="min-h-screen bg-blue-100 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg md:w-1/2 lg:w-1/2 text-center shadow-[0px_8px_16px_-6px_#14171A0A]">
            <Trophy className="w-16 h-16 mx-auto my-4" />
            <h2 className="text-3xl text-darkBlue dark:text-grey100 font-bold">Congratulations!</h2>
            <p className="text-3xl text-darkBlue dark:text-grey100 font-medium">
                You’ve successfully {isChanged
                    ? "changed"
                    : isUpgrade
                        ? "upgraded"
                        : isDowngrade
                            ? "downgraded"
                            : isRegistration
                                ? "activated"
                                : "activated"} your ZIK Analytics package
            </p>
            <p className="text-xl text-darkBlue dark:text-grey100 font-medium mt-[50px]">We're now redirecting you to your dashboard</p>
            <Dots />
        </div>
    </div>
);

export default Upgrade;