import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import { ReactComponent as RegistrationFunnelLogo } from "assets/icons/registration-funnel-logo.svg";
import Button from "components/Button";
import { FC } from "react";

interface Props {
    handleBack: () => void;
    step: number;
    isRegistration?: boolean
    introStep?: number;
}
const RegistrationFunnelNavbar: FC<Props> = ({ handleBack, step, isRegistration, introStep }) => (
    <div className={`flex justify-between items-center lg:hidden ${isRegistration ? "" : "mt-[26px]"}`}>
        <RegistrationFunnelLogo />
        {/*   <div className="sm:hidden">
            <Logo className="w-[38.18px] h-[38.78px]" />
        </div> */}
        {/*     <Button
            title="Back"
            height="max-sm:h-9 h-11"
            titleClassName="font-bold !text-sm"
            iconClassName="max-sm:w-[13.33px]"
            className={`z-40 min-w-[99px] bg-white lg-hidden max-lg:hidden max-w-[99px]  ${introStep === 0 ? "invisible" : ""} ${isRegistration ? "hidden" : ""}`}
            handleClick={handleBack}
            StartIcon={LeftArrow}
        /> */}

        <Button
            title=""
            height="h-[36px]"
            titleClassName="hidden"
            variant="contained"
            iconClassName="w-[24px] h-[24px] !fill-darkBlue"
            className={`z-40 w-[36px] bg-white border-none hover:bg-white max-w-[36px] lg:hidden  ${introStep === 0 ? "invisible" : ""} ${isRegistration ? "hidden" : ""}`}
            handleClick={handleBack}
            StartIcon={LeftArrow}
        />
    </div>
)

export default RegistrationFunnelNavbar