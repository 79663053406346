import Input from "components/Input";

// Hooks

// Icons
import CopyToClipboard from "components/CopyToClipboard";
import CenteredLoading from "components/Loading/CenteredLoading";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";

interface Props {
  url: string;
  title: string;
}

const UrlCopy = ({ url, title }: Props) => {
  const { isFetching } = useGetEarningStatsQuery();

  return (
    <div className="px-8 py-5 border-2 border-neutral2 rounded-10 flex-1 h-full dark:border-grey700 dark:bg-grey800">
      <CenteredLoading loading={isFetching}>
        <div className="font-bold ml-7 mb-[30px]">{title}</div>
        <div className="flex items-center gap-7">
          <Input
            value={url}
            handleChange={() => { }}
            className="w-full !border-secondary"
            disabled
          />

          <CopyToClipboard url={url} />
        </div>
      </CenteredLoading>
    </div>
  );
};

export default UrlCopy;
