import { useAppSelector } from "hooks/useAppSelector";
import jwt from "jwt-decode";
import { GetAccessToken } from "utils/localStorage";

export type UserPackages = "Free" | "Pro" | "ProPlus" | "Enterprise";
export interface DecodedUser {
  country: string;
  active: string;
  active_trail: string;
  already_trial: string;
  amazon_site: string;
  aud: string;
  email: string;
  exp: number;
  has_special_subscription: string;
  iat: number;
  iss: string;
  journey_completed: string;
  jti: string;
  market_type: string;
  nameid: string;
  nbf: number;
  no_of_subscriptions: string;
  package_name: string;
  package: UserPackages;
  role: string;
  session_id: string;
  store: string;
  theme: string;
  unique_name: string;
  days_for_trial: string;
}

const useDecodeToken = () => {
  const { accessToken } = useAppSelector((state) => state.user.result);
  const token = accessToken || GetAccessToken();
  const user: DecodedUser = jwt(token || "");

  return user;
};

export default useDecodeToken;
