import Modal from "components/Modal";
import Tooltip from "components/Tooltip";
import { LANDING_PAGE_LINKS } from "config/constants";
import useModal from "hooks/useModal";
import { FC } from "react";
import { toast } from "react-toastify";
import { CampaignList } from "types/services/affiliateManager";
import CampaignGraph from "./CampaignGraph";

interface Props {
  helpers: ReturnType<useModal>;
  item: CampaignList;
}

const InvoiceDetails: FC<Props> = ({ helpers, item }) => {
  const url = LANDING_PAGE_LINKS.r + "/" + item.url;

  const onUrlCopy = () => {
    toast.success("Copied to clipboard!");
    navigator.clipboard.writeText(url);
  };

  return (
    <Modal
      helpers={helpers}
      title={`Campaign: ${item.campaignName}`}
      maxWidth="max-w-[740px]"
      titleClassName="pt-9"
    >
      <div className="pb-9">
        <div className="text-sm flex max-sm:flex-col items-center justify-center gap-[5rem] mb-7">
          <div className="flex flex-col gap-2.5">
            <h6 className="font-bold">URL</h6>
            <div>
              <button
                className="hover:cursor-pointer hover:text-primaryPurple dark:hover:text-purple500"
                data-tooltip-id="click-to-copy-url"
                onClick={onUrlCopy}
              >
                {url}
              </button>
              <Tooltip title="Click to copy!" anchorId="click-to-copy-url" />
            </div>

            <h6 className="font-bold">
              Total Clicks:<span className="font-normal"> {item.clicks}</span>{" "}
            </h6>
          </div>
          <div className="flex flex-col gap-2.5">
            <h6 className="font-bold">Page Section</h6>
            <p>{item.pageSection}</p>
            <h6 className="font-bold">
              Total Registered:
              <span className="font-normal"> {item.registered}</span>{" "}
            </h6>
          </div>
        </div>

        <CampaignGraph campaignId={item.campaignId.toString()} />
      </div>
    </Modal>
  );
};

export default InvoiceDetails;
