export const queryTags = {
  bulkScanner: {
    scannedSellers: "ScannedSellers",
    multiBulkSellers: "MultiBulkSellers",
    matchedItemsList: "MatchedItemsList",
    similarItemsList: "SimilarItemsList",
    notFoundItemsList: "NotFoundItemsList",
  },
  store: {
    getStores: "getStores",
    loadStore: "loadStore",
  },
  quickSettings: {
    getSettings: "QuickSettings",
    tokenList: "TokenList",
    getUserDeatils: "GetUSerDetails"
  },
  folders: {
    folders: "Folders",
  },
  iconFinder: {
    findItems: "FindItems",
  },
  watchList: {
    list: "WatchList",
  },
  waitingList: {
    list: "WaitingList",
  },
  dashboardInsight: {
    favoritesMenu: "FavoritesMenu",
    announcements: "Announcements",
  },
  turboScanner: "TurboScanner",
  productMangement: "ProductManagement",
  affiliateManager: {
    campaigns: "campaigns",
    earningStats: "earningStats",
  },
  managePayments: {
    subscription: "userSubscription",
    defaultCoupon: "defaultCoupon",
    checkSubscriptionRegistration: "checkSubscriptionRegistration",
  },
};
