import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { ReferralList } from "types/services/affiliateManager";
import { formatCurrency } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";
export const referralCols: ColumnDef<ReferralList>[] = [
  {
    header: "Full Name",
    accessorKey: "referredEmail",
    cell: ({ row: { original } }) => {
      const cuted = original.referredEmail.substring(4);

      return `****${cuted}`;
    },
  },
  {
    header: "Payment Date",
    accessorKey: "paymentDate",
    cell: ({ row: { original } }) => formatDate(original.createdOn),
  },
  {
    header: "Payout Date",
    accessorKey: "createdOn",
    cell: (date) => (
      <div>
        {dayjs(String(date.getValue())).add(1, "month").format("DD/MM/YYYY")}
      </div>
    ),
  },
  {
    header: "Amount Earned",
    accessorKey: "earnedAmount",
    cell: (amount) => formatCurrency(String(amount.getValue())),
  },
  {
    header: "Earned",
    accessorKey: "refunded",
    cell: ({ row: { original } }) => {
      if (original.refunded != null) {
        if (original.refunded === true) {
          return <span className="text-errorRed">Refunded</span>;
        }

        if (original.earned) {
          return <span className="text-smoothGreen dark:text-paradiseBlue">Approved</span>;
        } else {
          return "NotApproved";
        }
      } else {
        if (original.earned) {
          return <span className="text-smoothGreen dark:text-paradiseBlue">Approved</span>;
        } else {
          return "NotApproved";
        }
      }
    },
  },
];
