import { ReactComponent as Starts } from "assets/images/stars.svg";
import Button from "components/Button";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import Loading from "components/Loading";
import PaymentMethod from "components/PaymentMethod/Index";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useGetDefaultCouponQuery,
  useLazyCheckRegistrationQuery,
  useLazyJourneyCompleteQuery,
  useLazyPayoneerPurchaseQuery,
  useLazyPaypalRegistrationPurchaseQuery,
  useLazySubmbitCreditCardQuery
} from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { TSelectedPlan } from "types/plans";
import { gtmOnboardingCcClick, gtmOnboardingPaymantScreenView, gtmOnboardingPaymentSubmit, gtmOnboardingPaymentSubmitAttempt, gtmOnboardingPayoneerClick, gtmOnboardingPaypalClick } from "utils/googleTagManager";
import { SetAccessToken } from "utils/localStorage";
import { getPlanFullSuffix } from "utils/planUtils";

interface Props {
  selectedPlan: TSelectedPlan;
  method: string
  handleMethod: (type: string) => void
}
const Payment = ({ selectedPlan, method, handleMethod }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ccPaymentLoading, setCCPaymentLoading] = useState(false)
  const [couponValidating, setCouponValidating] = useState(true);
  const [coupon, setCoupon] = useState<string>();
  const [alreadyTrial, setAlreadyTrial] = useState(false);
  const [isPayNow, setIsPayNow] = useState(false)
  const { data: defaultCoupon, isFetching: couponLoading } =
    useGetDefaultCouponQuery(selectedPlan.type);
  const [checkRegistration, { data, isFetching }] =
    useLazyCheckRegistrationQuery();

  const [paypalPurchase, { isFetching: paypalFetching }] =
    useLazyPaypalRegistrationPurchaseQuery();

  const [payoneerPurchase, { isFetching: payoneerFetching }] =
    useLazyPayoneerPurchaseQuery();
  const [, { isLoading }] = useLazySubmbitCreditCardQuery();
  const [journeyComplete, { isFetching: journeyCompleteFetching }] = useLazyJourneyCompleteQuery();

  const handleJourneyComplete = () => {
    journeyComplete(null).unwrap().then((data) => {
      SetAccessToken(data.message);
      dispatch(setUserAccessToken(data.message));
      navigate(PATH.alreadyTrial);
    })
  }

  const handleApplyCoupon = (newVal: string) => {
    setCoupon(newVal);
  };


  const handleAlreadyTrial = (isTrial: boolean) => {
    setAlreadyTrial(isTrial);
  }

  useEffect(() => {
    if (selectedPlan?.type != null && !couponLoading && !couponValidating) {
      checkRegistration({
        package: selectedPlan.type,
        coupon: coupon || "",
        skipTrial: selectedPlan.skip,
      }).unwrap().then().catch((error) => toast.error(error.data.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon, selectedPlan.type, selectedPlan.skip]);

  const handleVatNumberUpdate = () => {
    if (selectedPlan?.type != null) {
      checkRegistration({
        package: selectedPlan.type,
        coupon: coupon || "",
        skipTrial: selectedPlan.skip,
      })
        .unwrap()
        .then()
        .catch((error) => toast.error(error.data.message));
    }
  }


  const handlePurchase = () => {
    if (method === "") {
      toast.error("Please, select a payment method.");

      return;
    }
    if (method === "cc") {
      setIsPayNow(true)
    }
    if (method === "pp") {
      paypalPurchase({
        couponName: coupon || "",
        requestedSubscriptionType: selectedPlan.type,
        skipTrial: selectedPlan.skip
      })
        .unwrap()
        .then((data) => {
          gtmOnboardingPaymentSubmit(); // send event to gtm 
          window.location.replace(`${data.message}`);
        });
    }

    if (method === "py") {
      payoneerPurchase({
        couponName: coupon || "",
        type: selectedPlan.type,
        skipTrial: selectedPlan.skip
      })
        .unwrap()
        .then((data) => {
          gtmOnboardingPaymentSubmit(); // send event to gtm
          window.location.replace(`${data.message}`);
        });
    }
  };

  useEffect(() => {
    // send gtm event when user choose payment method
    if (method) {
      method === "cc" ?
        gtmOnboardingCcClick() :
        method === "pp" ? gtmOnboardingPaypalClick()
          : gtmOnboardingPayoneerClick();
    }
  }, [method])


  useEffect(() => {
    gtmOnboardingPaymantScreenView(); // google tag manager event that fires when user land on this page
  }, [])

  return (
    <div className="overflow-y-auto scrollbar max-lg:pb-10">
      <h1 className="text-lg text-center font-bold text-darkBlue dark:text-grey100 mt-[31px] max-sm:mt-3.5">
        Almost there!
      </h1>
      <Starts className="my-5 max-sm:mt-[15px] max-sm:mb-3 mx-auto" />
      <div className="flex gap-[15px] max-sm:gap-5 px-[38px] max-sm:px-[9px] pb-2.5 max-lg:flex-col max-sm:flex-col-reverse max-lg:items-center">
        <div className="max-w-[675px] max-lg:max-w-[618px] w-full px-5 border-[1px] border-secondary max-sm:border-none h-fit rounded-[15px] py-[21px] max-sm:p-0">
          <div className="flex flex-col justify-center w-full">

            <h1 className={`${method !== "" ? "max-sm:hidden" : ""} text-lg max-sm:text-base text-center font-bold text-darkBlue`}>
              Choose your Payment Method
            </h1>
            <PaymentMethod
              className={`my-[4.5px] max-sm:my-5 ${method === "" ? "max-sm:my-5" : "max-sm:mt-0 max-sm:mb-5.5"} justify-center`}
              handleMethod={handleMethod}
              selectedPlan={selectedPlan.type}
              selectedMethod={method}
              coupon={coupon || ""}
              skip={selectedPlan.skip}
              onCCPaymentLoading={(isLoading) => setCCPaymentLoading(isLoading)}
              fromRegistration={true}
              isPayNow={isPayNow}
              handleStopPay={() => setIsPayNow(false)}
              handleAlreadyTrial={handleAlreadyTrial}
              amount={data?.result?.totalToday}
            />
          </div>
          {!couponLoading && (
            <CheckoutCouponInput
              handleApplyCoupon={handleApplyCoupon}
              selectedPlan={selectedPlan.type}
              fromRegistration={true}
              preloadCoupon={defaultCoupon?.message}
              couponValidating={setCouponValidating}
            />
          )}
          {method === "" ? "" : <div className="flex justify-center mt-[25px] sm:hidden">
            <Button
              title="PAY NOW"
              color="smoothGreen"
              height="h-16"
              titleClassName="!text-base"
              className="font-bold max-w-[250px] bg-smoothGreen text-white hover:!text-darkBlue"
              handleClick={() => { handlePurchase(); gtmOnboardingPaymentSubmitAttempt(); }}
            />
          </div>}
        </div>


        <div className="w-full max-w-[333px] max-lg:max-w-[618px] max-sm:border-none border-[1px] border-secondary rounded-[15px] flex items-center py-[26px] max-sm:py-0">
          <div className="mx-auto w-full">
            {!couponLoading && !couponValidating && data ?
              <div className="max-w-[283px] mx-auto">
                <CheckoutOrderSummary
                  selectedPlan={selectedPlan}
                  isLoading={isFetching}
                  data={data}
                  fromRegistration={true}
                  handleVatNumberUpdate={handleVatNumberUpdate}
                />
              </div>
              : <Loading fill="fill-primaryPurple dark:fill-purple500 mx-auto mb-10" width={40} height={40} />
            }
            {
              alreadyTrial &&
              <div className="lg:-mt-10 max-sm:mt-5 max-lg:-mt-8">
                <div className="w-full h-[2px] bg-secondary rounded-full mb-2.5 max-w-[283px] mx-auto max-sm:mb-3.5" />
                <div className="mb-2.5 text-sm font-bold text-center max-lg:text-base max-sm:mb-3.5">{selectedPlan.planName} {getPlanFullSuffix(selectedPlan.type)}</div>
                <p className="mb-3 text-sm font-medium text-center w-full max-lg:mb-5 max-sm:mb-[35px]">
                  You used a Trial before, do you wish
                  to continue with the subscription?
                </p>
                <div className="flex justify-center items-center gap-3 flex-col max-w-[215px] max-lg:max-w-[350px] mx-auto max-sm:gap-5" >
                  <Button
                    title="Yes"
                    height="h-11 max-lg:h-16 max-sm:h-[60px]"
                    titleClassName="!text-base font-bold"
                    handleClick={() => handleAlreadyTrial(false)}
                    loading={journeyCompleteFetching}
                    className="font-medium bg-darkBlue text-smoothGreen hover:bg-smoothGreen hover:text-darkBlue hover:!border-smoothGreen"
                  />
                  <Button
                    title="No"
                    height="h-11 max-lg:h-16 max-sm:h-[60px]"
                    titleClassName="!text-base font-bold"
                    handleClick={handleJourneyComplete}
                    loading={journeyCompleteFetching}
                    className="font-medium"
                  />
                </div>
              </div>
            }
            {(method === "" || alreadyTrial) ? "" : (
              <div className="flex justify-center max-sm:hidden">
                <Button
                  title="PAY NOW"
                  color="smoothGreen"
                  height="h-16"
                  titleClassName="!text-base"
                  loading={paypalFetching || payoneerFetching || isLoading || ccPaymentLoading}
                  className="font-bold bg-smoothGreen text-white hover:!text-darkBlue w-full max-w-[260px] shadow-[2px_5px_30px_-4px_rgba(28, 163, 147, 0.15)]"
                  handleClick={() => { handlePurchase(); gtmOnboardingPaymentSubmitAttempt(); }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  );
};
export default Payment;
