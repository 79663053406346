import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AmazonItemReq,
  DeleteFavoritesMenuReq,
  FavoritesMenuRes,
  getAnnouncementRes,
  InsightsPreferencesRes,
  SaveFavoritesMenuReq,
  TrendingEbaySellersReq,
  TrendingEbaySellersRes,
  TrendingProductsReq,
  TrendingProductsRes,
  UpcomingEventsReq,
  UpcomingEventsRes,
} from "types/services/newDashboard";
import {
  TrendingEbayNichesReq,
  TrendingEbayNichesRes,
} from "../../types/services/newDashboard";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const newDashboardApi = createApi({
  reducerPath: "newDashboardApi",
  baseQuery,
  tagTypes: [queryTags.dashboardInsight.favoritesMenu],
  endpoints: (builder) => ({
    getAnnouncements: builder.query<getAnnouncementRes, void>({
      query: () => ({
        url: queryKeys.dashboard.announcements,
        method: "GET",
      }),
    }),
    readAnnouncements: builder.mutation<void, void>({
      query: () => ({
        url: queryKeys.dashboard.readAnnouncements,
        method: "POST",
      }),
      invalidatesTags: [queryTags.dashboardInsight.announcements],
    }),
    unreadAnnouncements: builder.query<{ result: number }, void>({
      query: () => ({
        url: queryKeys.dashboard.unreadAnnouncements,
        method: "GET",
      }),
      providesTags: [queryTags.dashboardInsight.announcements],
    }),
    deleteAnnouncement: builder.mutation<void, string>({
      query: (id) => ({
        url: queryKeys.dashboard.deleteAnnouncement,
        params: { id },
        method: "GET",
      }),
    }),
    getTrendingProducts: builder.query<
      TrendingProductsRes,
      TrendingProductsReq
    >({
      query: (params) => ({
        url: queryKeys.dashboardInsight.trendingProducts,
        method: "GET",
        params,
      }),
    }),
    getInsightsPreferences: builder.query<InsightsPreferencesRes, void>({
      query: () => ({
        url: queryKeys.dashboardInsight.insightsPreferences,
        method: "GET",
      }),
    }),
    getFavoritesMenu: builder.query<FavoritesMenuRes, void>({
      query: () => ({
        url: queryKeys.dashboardInsight.favoritesMenu,
        method: "GET",
      }),
      providesTags: [queryTags.dashboardInsight.favoritesMenu],
    }),
    saveFavoritesMenu: builder.mutation<void, SaveFavoritesMenuReq>({
      query: (params) => ({
        url: queryKeys.dashboardInsight.saveFavoritesMenu,
        method: "POST",
        params,
      }),
      invalidatesTags: [queryTags.dashboardInsight.favoritesMenu],
    }),
    deleteFavoritesMenu: builder.mutation<void, DeleteFavoritesMenuReq>({
      query: (params) => ({
        url: queryKeys.dashboardInsight.deleteFavoritesMenu,
        method: "DELETE",
        params,
      }),
      invalidatesTags: [queryTags.dashboardInsight.favoritesMenu],
    }),
    getEbayNiches: builder.query<TrendingEbayNichesRes, TrendingEbayNichesReq>({
      query: (params) => ({
        url: queryKeys.dashboardInsight.trendingEbayNiches,
        params,
        method: "GET",
      }),
    }),
    getEbaySellers: builder.query<
      TrendingEbaySellersRes,
      TrendingEbaySellersReq
    >({
      query: (params) => ({
        url: queryKeys.dashboardInsight.trendingEbaySellers,
        params,
        method: "GET",
      }),
    }),
    getUpcomingEvents: builder.query<UpcomingEventsRes, UpcomingEventsReq>({
      query: (params) => ({
        url: queryKeys.dashboardInsight.upcomingEvents,
        method: "GET",
        params,
      }),
    }),
    getAmazonItem: builder.query<void, AmazonItemReq>({
      query: () => ({
        url: queryKeys.dashboardInsight.amazonItem,
        method: "GET",
      }),
    }),

    updateNichePreferences: builder.mutation<void, TrendingEbayNichesReq>({
      query: (body) => ({
        url: queryKeys.dashboardInsight.updateNichePreference,
        method: "PATCH",
        body: body,
      }),
    }),

    updatePreferences: builder.mutation<void, TrendingEbayNichesReq>({
      query: (body) => ({
        url: queryKeys.dashboardInsight.updatePreferences,
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetTrendingProductsQuery,
  useGetInsightsPreferencesQuery,
  useGetFavoritesMenuQuery,
  useGetEbayNichesQuery,
  useGetEbaySellersQuery,
  useGetUpcomingEventsQuery,
  useGetAmazonItemQuery,
  useDeleteAnnouncementMutation,
  useSaveFavoritesMenuMutation,
  useDeleteFavoritesMenuMutation,
  useUpdateNichePreferencesMutation,
  useReadAnnouncementsMutation,
  useUnreadAnnouncementsQuery,
  useUpdatePreferencesMutation,
} = newDashboardApi;
