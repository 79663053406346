import Button from "components/Button";
import Card from "components/Card";
import Search from "components/Search";

// Types

// Icons
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { searchedListCols } from "assets/tableColumns/searchedList";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import usePagination from "hooks/usePagination";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useDeleteWaitingListItemMutation,
  useDeleteWaitingListMutation,
  useDeleteWaitingPageMutation,
  useLoadWaitingListQuery
} from "store/api/waitingListApi";

const tableStyles: TableStyles = {
  px: "px-0",
  style: "mb-4",
  tableClassName: "!table-fixed",
  minWidth: "min-w-[850px]",
  empty: {
    rowHeight: "h-[80px]",
  },
};

const SearchedList = () => {
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const [selectedItem, setSelectedItem] = useState("");
  const pagination = usePagination();
  const [
    deleteAll,
    { isLoading: deleteAllLoading, isSuccess: deleteAllSuccess },
  ] = useDeleteWaitingListMutation();
  const [
    deletePage,
    { isLoading: deletePageLoading, isSuccess: deletePageSuccess },
  ] = useDeleteWaitingPageMutation();
  const [
    deleteItem,
    { isLoading: deleteItemLoading, isSuccess: deleteItemSuccess },
  ] = useDeleteWaitingListItemMutation();
  const { data, isLoading } = useLoadWaitingListQuery({
    start: pagination.current * 10,
    length: 10,
    sortColumn: "",
    sortColumnDir: "",
    filterWord: delayedValue,
  });

  useEffect(() => {
    if (deleteAllSuccess || deleteItemSuccess || deletePageSuccess) {
      toast.success(
        deleteAllSuccess
          ? `All Users removed from list queue`
          : deletePageSuccess
            ? `Page ${pagination.current + 1} data removed`
            : `User removed from list queue`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAllSuccess, deleteItemSuccess, deletePageSuccess]);

  const onDeleteAll = () => deleteAll();

  const onDeletePage = () =>
    deletePage(data?.result.data.map((item) => item.id) || []);

  const deleteLoading = (id: string) =>
    deleteItemLoading && selectedItem === id;

  const onDelete = (id: string) => {
    setSelectedItem(id);
    deleteItem({ id });
  };

  const { table } = useTable({
    columns: searchedListCols(onDelete, deleteLoading),
    data: data?.result.data || [],
    dataDeps: [data],
    columnDeps: [deleteLoading],
    pagination: true,
    search: true,
  });

  useEffect(() => {
    if (data) {
      pagination.setTotal(data?.result.recordsTotal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Card className="px-4 py-7 mb-4">
        <div className="flex items-center justify-between mb-7 max-xl:flex-col max-xl:items-start max-xl:gap-4">
          <div className="font-bold ml-8 shrink-1 dark:text-grey100">Waiting List Queue</div>
          <div className="flex items-center justify-end gap-8 shrink-0 grow-1 max-xl:justify-start max-xl:w-full max-xl:flex-row max-md:flex-col max-md:gap-4 max-xl:items-start">
            <Button
              className="max-w-[148px] shrink-0 dark:bg-deepSpace900"
              height="h-9"
              Icon={Trash}
              title="Delete Page"
              loading={deletePageLoading}
              handleClick={onDeletePage}
            />
            <Button
              className="max-w-[148px] shrink-0 dark:bg-deepSpace900"
              Icon={Trash}
              height="h-9"
              title="Delete All"
              loading={deleteAllLoading}
              handleClick={onDeleteAll}
            />
            <Search
              search={immediateValue}
              handleChange={onChange}
              placeholder="Enter Keyword"
              maxWidth="18.8125rem"
              size="sm"
              htmlFor="table-search"
              className="shrink-0"
            />
          </div>
        </div>
        <Table table={table} styles={tableStyles} isLoading={isLoading} />
        <Pagination
          pagination={pagination}
          table={table}
          className="justify-between"
          labeled
        />
      </Card>
    </>
  );
};

export default SearchedList;
