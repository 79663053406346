// Hooks

// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-extended.svg";
import { LANDING_PAGE_LINKS } from "config/constants";
import { toast } from "react-toastify";
import { CampaignList } from "types/services/affiliateManager";
import { formatNumber } from "utils/formatCurrency";

export const campaignListCols = (
  onSelectItem: (item: CampaignList) => void
): ColumnDef<CampaignList>[] => [
    {
      header: "Campaign Name",
      accessorKey: "campaignName",
    },
    {
      header: "URL",
      accessorKey: "url",
      cell: (url) => (
        <button
          className="hover:cursor-pointer hover:text-primaryPurple dark:hover:text-purple500"
          id="click-to-copy-url"
          onClick={() => {
            toast.success("Copied to clipboard!");
            navigator.clipboard.writeText(
              LANDING_PAGE_LINKS.r + "/" + String(url.getValue())
            );
          }}
        >
          {`${LANDING_PAGE_LINKS.r}/${String(url.getValue())}`}
        </button>
      ),
    },
    {
      header: "LP Views",
      accessorKey: "clicks",
      cell: (clicks) => formatNumber(String(clicks.getValue())),
    },

    {
      header: "",
      accessorKey: "campaignId",
      cell: ({ row: { original } }) => (
        <button
          className="flex items-center gap-2.5 font-bold text-primaryPurple dark:text-paradiseBlue"
          onClick={() => onSelectItem(original)}
        >
          View More <ArrowRight className="fill-primaryPurple dark:fill-paradiseBlue" />
        </button>
      ),
    },
  ];
