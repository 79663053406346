import Toast from "components/Toast";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import SearchedList from "../lists/WaitingList/SearchedList";

const WaitingList = () => {
  const dataFetchedRef = useRef(false);
  useEffect(() => {
    if (!dataFetchedRef.current) {
      toast.info(
        <p className="text-sm">
          Important Notice: Your Waiting List is not your Saved List - this list
          only shows your queued sellers. The information in this table is
          deleted every 20-25 days. To save a seller permanently you'll need to
          add it to your watchlist.
        </p>,
        { autoClose: false }
      );
      dataFetchedRef.current = true;
    }
  }, []);

  return (
    <>
      <SearchedList />
      <Toast />
    </>
  );
};

export default WaitingList;
