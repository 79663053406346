import { configureStore } from '@reduxjs/toolkit';
import sellerSettingsReducer from 'store/slices/sellerSettingsSlice';
import { affiliateManagerApi } from './api/affiliateManagerApi';
import { authApi } from './api/authApi';
import { autopilotApi } from './api/autopilotApi';
import { bannersApi } from './api/bannersApi';
import { bulkScannerApi } from './api/bulkScannerApi';
import { catResApi } from './api/catResApi';
import { crApi } from './api/crApi';
import { dashboardApi } from './api/dashboardApi';
import { foldersApi } from './api/foldersApi';
import { iconFinderApi } from './api/iconFinderApi';
import { managePaymentsApi } from './api/managePaymentApi';
import { newDashboardApi } from './api/newDashboardApi';
import { onboardingApi } from './api/onboardingApi';
import { prApi } from './api/prApi';
import { productManagementApi } from './api/productManagementApi';
import { quickSettingsApi } from './api/quickSettingsApi';
import { storeApi } from './api/storeApi';
import { testingApi } from './api/testingApi';
import { turboScannerApi } from './api/turboScannerApi';
import { waitingListApi } from './api/waitingListApi';
import { watchListApi } from './api/watchListApi';
import { zikProApi } from './api/zikProApi';
import catResSliceReducer from './slices/catResSlice';
import crSliceReducer from './slices/crSlice';
import intercomSliceReducer from './slices/intercomSlice';
import prSliceReducer from './slices/prSlice';
import themeSliceReducer from './slices/themeSlice';
import turboScannerSliceReducer from './slices/turboScannerSlice';
import userSliceReducer from './slices/userSlice';

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    prSlice: prSliceReducer,
    crSlice: crSliceReducer,
    sellerSettings: sellerSettingsReducer,
    themeSlice: themeSliceReducer,
    catResSlice: catResSliceReducer,
    turboScannerSlice: turboScannerSliceReducer,
    intercomSlice: intercomSliceReducer,
    [authApi.reducerPath]: authApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [prApi.reducerPath]: prApi.reducer,
    [crApi.reducerPath]: crApi.reducer,
    [foldersApi.reducerPath]: foldersApi.reducer,
    [iconFinderApi.reducerPath]: iconFinderApi.reducer,
    [catResApi.reducerPath]: catResApi.reducer,
    [zikProApi.reducerPath]: zikProApi.reducer,
    [bulkScannerApi.reducerPath]: bulkScannerApi.reducer,
    [quickSettingsApi.reducerPath]: quickSettingsApi.reducer,
    [turboScannerApi.reducerPath]: turboScannerApi.reducer,
    [watchListApi.reducerPath]: watchListApi.reducer,
    [waitingListApi.reducerPath]: waitingListApi.reducer,
    [affiliateManagerApi.reducerPath]: affiliateManagerApi.reducer,
    [autopilotApi.reducerPath]: autopilotApi.reducer,
    [productManagementApi.reducerPath]: productManagementApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [newDashboardApi.reducerPath]: newDashboardApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [managePaymentsApi.reducerPath]: managePaymentsApi.reducer,
    [testingApi.reducerPath]: testingApi.reducer,
    [bannersApi.reducerPath]: bannersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      prApi.middleware,
      crApi.middleware,
      foldersApi.middleware,
      iconFinderApi.middleware,
      zikProApi.middleware,
      catResApi.middleware,
      bulkScannerApi.middleware,
      quickSettingsApi.middleware,
      turboScannerApi.middleware,
      watchListApi.middleware,
      waitingListApi.middleware,
      affiliateManagerApi.middleware,
      autopilotApi.middleware,
      productManagementApi.middleware,
      newDashboardApi.middleware,
      storeApi.middleware,
      dashboardApi.middleware,
      onboardingApi.middleware,
      managePaymentsApi.middleware,
      testingApi.middleware,
      bannersApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
