
import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg";


interface Props {
    showFeature: boolean;
    handleToggleShowFeature: () => void;
}

const ShowFeatureToggle = ({ showFeature, handleToggleShowFeature }: Props) => {

    return (
        <div className="hidden max-[965px]:flex flex-col justify-center items-center gap-[5px] mt-[35px] relative z-10" onClick={() => handleToggleShowFeature()}>
            <div className="text-primaryPurple text-[16px] font-bold leading-[24px]">{showFeature ? "Hide Feature" : "Show Feature"}</div>
            <div className="w-[26px] h-[26px] flex items-center justify-center">
                <Arrow className={`fill-primaryPurple transition transition-duration-[200ms] ease ${!showFeature ? "rotate-[180deg]" : ""}`} />
            </div>

            <div className="max-w-[330px] w-full h-[2px] rounded-[50px] bg-primaryPurple"></div>
        </div>
    )
}

export default ShowFeatureToggle