import SadEmoji from "assets/images/sad-emoji.png";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import { FC } from "react";
import { FieldErrors } from "react-hook-form";

interface FormValues {
    email: string
}interface Props {
    onUpgrade: () => void;
    isLoading: boolean;
    register: any;
    errors: FieldErrors<FormValues>;
    handleSubmit: () => void;
}

const CancelationEnterEmail: FC<Props> = ({ onUpgrade, errors, isLoading, register, handleSubmit }) => (
    <div className="flex flex-col items-center w-full" >
        <img src={SadEmoji} alt="" className="text-center mb-4 w-10 h-10" />
        <h4 className="text-xl font-semibold mb-2.5">We’re sad to see you go...</h4>
        <p className="text-center text-lg font-medium leading-[28px]">In order to confirm the cancelation, please enter the email address
            <br />  associated with your account
        </p>
        <form
            className="w-full flex flex-col items-center gap-[58px] mx-auto"
            onSubmit={handleSubmit}>
            <div className="max-w-[300px] w-full">
                <ControlledInput register={register} errors={errors} name="email" className="pb-[15px] pt-4.5 !text-sm  w-full" placeholder="Enter your Email" />
            </div>
            <div className="flex gap-[35px] w-full max-w-[535px]">
                <div className="rounded-full w-full" style={{ boxShadow: "0px 0px 1.02411px 0px #8FFFF2, 0px 0px 2.04821px 0px #8FFFF2, 0px 0px 7.16874px 0px #8FFFF2, 0px 0px 14.33749px 0px #8FFFF2, 0px 0px 24.57855px 0px #8FFFF2, 0px 0px 43.01246px 0px #8FFFF2" }}>
                    <Button
                        title="I changed my mind"
                        height="h-16"
                        className="border-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:border-paradiseBlue dark:hover:bg-paradiseBlue dark:hover:border-paradiseBlue"
                        titleClassName="!text-base font-medium"
                        type="button"
                        handleClick={onUpgrade}
                    />
                </div>
                <Button title="Yes, Cancel" height="h-16" className="border-neutral3 text-neutral3" titleClassName="!text-base font-medium" loading={isLoading} handleClick={handleSubmit} />
            </div>
        </form>

    </div>
)

export default CancelationEnterEmail