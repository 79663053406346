// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReferredUserStatsList } from "types/services/affiliateManager";
import { formatDate } from "utils/formatTime";

export const referralStatisticsCols: ColumnDef<ReferredUserStatsList>[] = [
  {
    header: "Full Name",
    accessorKey: "firstName",
    cell: ({ row: { original } }) => (
      <p>{original.firstName + " " + original.lastName}</p>
    ),
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Date of Registration",
    accessorKey: "dateOfRegistration",
    cell: (date) => formatDate(String(date.getValue())),
  },

  {
    header: "Status",
    accessorKey: "getRresponse",
    cell: ({
      row: {
        original: { getRresponse: status },
      },
    }) =>
      status === 1 || status === 4 ? (
        <p>{status === 4 ? "Free Trial" : "Subscriber"}</p>
      ) : status === 2 ? (
        <p className="text-errorRed">Canceled</p>
      ) : status === 3 ? (
        <p className="text-smoothGreen dark:text-paradiseBlue">New</p>
      ) : status === 4 ? (
        <p>FreeTrial</p>
      ) : (
        "-"
      ),
  },
];
