import { ReactComponent as LogoHovered } from "assets/icons/logo-hovered.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as ManagementPanel } from "assets/icons/management-panel.svg";
import DarkModeSwitch from "components/DarkModeSwitch";
import Scrollbar from "components/Scrollbar";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useLogout from "hooks/useLogout";
import useOutsideClick from "hooks/useOutsideClick";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PATH } from "routes/path";
import { queryKeys } from "store/api/queryKeys";
import { GetSavedCity, GetSavedLang, SetSavedCity } from "utils/localStorage";
import { sidebarCountries, sidebarItems } from "./SidebarConfig";
import SidebarItem from "./SidebarItem";
import SubmenuCities from "./SubmenuCities";

interface Country {
  location: { name: string; tz_id: string; country: string };
  current: {
    temp_c: number;
    condition: { text: string; icon: string; code: number };
  };
}
dayjs.extend(utc);
dayjs.extend(timezone);

const Sidebar = () => {
  const logout = useLogout();
  const { height } = useWindowDimensions();
  const isSmallSidebar = height < 800
  const [submenuOpen, setSubmenuOpen] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState("");
  const { loading } = useAppSelector((state) => state.themeSlice);
  const location = useLocation();
  const { i18n } = useTranslation(['home']);
  const userDetails = useDecodeToken();
  const { store } = userDetails;
  const onOpenChange = (newVal: string | null) => setSubmenuOpen(newVal);
  const [ref] = useOutsideClick<HTMLDivElement>(() => onOpenChange(null));
  const [fetchIpAddress, setIp] = useState();
  const [getLocalCountry, setLocalCoutry] = useState<Country>();
  const [, setTime] = useState("");
  const [fetchWeather, setWeather] = useState<Country>();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchWeather !== undefined) {
        setTime(dayjs().tz(fetchWeather.location.tz_id).format("HH:mm"));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchWeather]);

  const handleClose = () => {
    onOpenChange(null);
  };
  const checkCountry = (country: string) =>
    country !== "United States of America" &&
    country !== "United Kingdom" &&
    country !== "Germany" &&
    country !== "Philippines" &&
    country !== "Portugal" &&
    country !== "Israel";

  useEffect(() => {
    let storedLang = GetSavedLang();
    if (storedLang) {
      i18n.changeLanguage(storedLang);
    }

    const fetchData = async () => {
      try {
        const response = await fetch(queryKeys.ipify);
        const data = await response.json();
        setIp(data.ip);
        let storedData = GetSavedCity();
        if (storedData) {
          const weather = await fetchWeatherData(storedData);
          setSelectedCity(storedData);
          setWeather(weather);
          const _data = await fetchWeatherData(data.ip);
          if (checkCountry(_data.location.country)) {
            setLocalCoutry(_data);
          }
        } else {
          const _data = await fetchWeatherData(data.ip);
          if (checkCountry(_data.location.country)) {
            setLocalCoutry(_data);
          }
          setWeather(_data);
        }
      } catch (error) { }
    };

    if (fetchIpAddress === undefined) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWeatherData = async (ip: string) => {
    try {
      const response = await fetch(queryKeys.getWhether + ip);
      const data = await response.json();

      return data;
    } catch (error) { }
  };
  const handleSelectCity = async (id: string) => {
    if (id !== "USA") {
      setSelectedCity(id);
      onOpenChange(null);
      const weather = await fetchWeatherData(id);
      setWeather(weather);
      SetSavedCity(id);
    }
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedCity]);

  return (
    <Scrollbar
      as="aside"
      className={`h-full pt-5 fixed z-50  overflow-y-visible`}
      variant="transparent"
    >
      <div
        className="w-[74px] ml-[0.6875rem]"
      >
        <div
          className="w-14"
        >
          <div
            className={`bg-white dark:bg-black900 shadow rounded-[15px] gap-2.5 flex flex-col items-center justify-center py-[1.125rem] mb-[15px] ${isSmallSidebar ? "w-[46px] mx-auto" : "w-14"}`}
          >
            {process.env.REACT_APP_ENVIRONMENT !== 'affiliate' ? (
              <>
                <SidebarItem
                  Icon={Logo}
                  path={PATH.dashboard.root}
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  iconStyles="w-[24px] h-[22px]"
                  IconHovered={LogoHovered}
                  title="Dashboard"
                  translate="Dashboard"
                />
                {sidebarItems.map((item) => (
                  <SidebarItem
                    {...item}
                    key={item.path}
                    submenu={item.submenu}
                    quickSettings={item.quickSettings}
                    isOpen={submenuOpen === item.title}
                    handleChange={() => onOpenChange(item.title)}
                    handleClose={handleClose}
                    translate={item.translate}
                    store={store}
                  />
                ))}
              </>
            ) : (
              <>
                <SidebarItem
                  Icon={Logo}
                  path={PATH.managementPanel.affiliateManager}
                  iconStyles="w-[24px] h-[22px]"
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  IconHovered={LogoHovered}
                  title="Dashboard"
                  translate="Dashboard"
                />
                <SidebarItem
                  Icon={ManagementPanel}
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  path={PATH.quickSettings.account}
                  iconStyles="w-[24px] h-[22px]"
                  title="Management Panel"
                  translate="Management Panel"
                />
              </>
            )}
            <SidebarItem
              Icon={Logout}
              translate="Logout"
              path="logout"
              smIconStyles="w-[13px] h-[15.17px]"
              iconStyles="w-[18px] h-[21px]"
              handleCustomClick={logout}
              title="Logout"
            />
          </div>


          {!isAffiliate && userDetails.active_trail === "True" &&
            <div data-tooltip-id={userDetails.days_for_trial}
              className="bg-primaryPurple dark:bg-purple500 rounded-full w-[36px] h-[36px] border-2 border-darkBlue m-auto text-xl text-white text-center flex items-center justify-center mb-[15px]"
            >
              <Tooltip place="left" title="Reminder: Your Trial is Expiring Soon! Your account will be charged for your chosen plan. If you do not wish to continue, please cancel your subscription in account settings." anchorId={userDetails.days_for_trial} />
              <span className="">
                {userDetails.days_for_trial}
              </span>
            </div>

          }
        </div>


        <div className="w-[56px] relative">
          <div className="mb-[6px] flex justify-center">
            <DarkModeSwitch />
          </div>
          <div
            className={`flex items-center flex-col text-xs cursor-pointer ${submenuOpen === "city" ? "pointer-events-none" : ""
              }`}
            onClick={() => onOpenChange("city")}
          >
            {fetchWeather && (
              <div className={`text-center ${loading ? "text-white" : "text-darkBlue dark:text-lilac400"}`}>
                <img src={fetchWeather.current.condition.icon} alt="" className="w-9 h-[auto] mx-auto" />
                <div >
                  <p>
                    {fetchWeather.location.country === "United States of America"
                      ? (sidebarCountries.find((item) => item.id === "USA")?.cities?.find((city) => city.id == selectedCity)?.title ?? "USA")
                      : fetchWeather.location.country}
                  </p>
                  <p>{fetchWeather.current.temp_c} &deg;C</p>
                  <p>{dayjs().tz(fetchWeather.location.tz_id).format("HH:mm")}</p>
                </div>

              </div>
            )}
            {submenuOpen === "city" ? (
              <div ref={ref}>
                <SubmenuCities
                  selectedCity={selectedCity}
                  handleSelectCity={handleSelectCity}
                  localCountry={getLocalCountry}
                />
              </div>
            ) : (
              ""
            )}
          </div>

        </div>
      </div>
    </Scrollbar>

  );
};

export default Sidebar;
