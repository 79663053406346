import { ReactComponent as Amazon } from "assets/icons/amazon-f.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as Other } from "assets/icons/other-seller.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";

import { ReactComponent as Question } from "assets/images/question.svg";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";
import Loading from "components/Loading";
import { useGetOnBoardingQuestionQuery } from "store/api/onboardingApi";

interface Props {
  channels: number[];
  handleChannelSelection: (channel: number) => void;
  loading: boolean;
  handleSave: () => void;
}
const SalesChannel = ({
  channels,
  handleChannelSelection,
  loading,
  handleSave,
}: Props) => {
  const { data, isFetching: questionLoading } = useGetOnBoardingQuestionQuery(
    2,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const ResolveImage = (label: string) => {
    if (label === "ebay")
      return <Ebay className="w-[86px] h-[49px] max-lg:w-[52px] max-lg:h-[30px]" />;
    if (label === "amazon")
      return <Amazon className="w-[76px] h-6 max-lg:w-[70px] max-lg:h-[21px]" />;
    if (label === "shopify")
      return <Shopify className="w-[86px] h-6" />;
    if (label === "other")
      return <Other className="w-[60px] h-7" />;
  };

  return (
    <div className="flex flex-col justify-center mt-4.5">
      <Question className="self-center mb-[35px] max-lg:mb-[22px]" />
      {!questionLoading && data ? (
        <>
          <h1 className="text-[20px] text-center font-bold text-darkBlue dark:text-grey100 mb-[25px] max-lg:mb-[22px]">
            {data?.result[0].questionLabel}
          </h1>
          <div className="flex justify-center space-between">
            <div className="gap-[25px] grid max-lg:max-w-[400px] max-lg:w-full grid-cols-4 max-lg:grid-cols-1 items-center justify-center mb-[34px] max-lg:mb-10">
              {data.result[0].onBoardingQuestionOptions.map((option) => (
                <label
                  key={option.id}
                  htmlFor={option.optionValue.toString()}
                  className="flex items-center justify-center max-lg:w-full gap-[15px] max-lg:justify-between px-5
                  bg-neutral1 border-2 border-secondary hover:bg-neutral2 border-solid rounded-[10px] h-[84px] max-lg:h-14 lg:w-[163px] hover:shadow-xl cursor-pointer"
                >
                  {ResolveImage(option.optionLabel)}
                  <Checkbox
                    id={option.optionValue}
                    checked={channels.includes(option.optionValue)}
                    value={option.optionValue}
                    handleChange={() => {
                      handleChannelSelection(option.optionValue);
                    }}
                  />
                </label>
              ))}
            </div>
          </div>
          <div className="flex justify-center max-sm:mb-10">
            <Button
              title="Next"
              height="h-16 max-sm:h-14"
              titleClassName="font-medium !text-base"
              className={`max-w-[200px] max-lg:max-w-[350px]`}
              handleClick={handleSave}
              loading={loading}
              disabled={channels.length <= 0}
            />
          </div>
        </>
      ) : <Loading fill="fill-primaryPurple dark:fill-purple500 mx-auto" width={40} height={40} />}
    </div>
  );
};

export default SalesChannel;
