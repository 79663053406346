import Button from "components/Button";
import { PlanList, TSelectedPlan } from "types/plans";
import {
  getPlanSuffix,
  isCurrentPlan,
  isDowngrade,
  isUpgrade
} from "utils/planUtils";
import PlanFeatures from "./PlanFeatures/PlanFeatures";

interface Props {
  plan: PlanList;
  currentPlan: string;
  paymentStatus?: number;
  isTrial?: boolean;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  handleProToolUpgrade?: () => void;
  fromRegistration?: boolean;
  buttonFilled?: boolean;
  goToCheckout?: () => void
  alreadyTrial?: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  fromNoSubscription?: boolean;
  alwaysUpgrade?: boolean
}

const Plan = ({
  plan,
  currentPlan,
  paymentStatus,
  isTrial,
  goToCheckout,
  handleSelectPlan,
  handleProToolUpgrade,
  fromRegistration = false,
  buttonFilled,
  alreadyTrial,
  selectedPlan,
  fromNoSubscription = false,
  alwaysUpgrade = false
}: Props) => (
  <div
    className={`w-[310px] max-[1060px]:w-[260px] max-[965px]:w-[310px] bg-white flex flex-col justify-between rounded-[15px] ${selectedPlan && selectedPlan !== plan.planId ? "max-[965px]:hidden" : ""}  ${plan.highlight && "!border-[1px] !border-smoothGreen"
      } ${fromRegistration ? "border-[2px] border-secondary" : "border-white"
      }`}
  >
    <div className="pl-[10px] pr-[10px] pt-5 pb-[5px]">
      <div className="max-w-[278px] mx-auto max-[1060px]:max-w-[216px]">
        <div className="flex items-center justify-between mb-[5px]">
          <div className="text-[28px] font-bold uppercase leading-[36px]">
            {plan.planName}
          </div>
          {plan.popularBadge && (
            <div className="max-w-[122px] w-full h-[26px] bg-darkBlue text-white text-xs rounded-full flex items-center justify-center font-bold">
              MOST POPULAR
            </div>
          )}
        </div>

        <p className="text-xs h-12 mb-1">{plan.planDescription}</p>

        {plan.isDiscount ?
          <>
            <div>
              $
              <span className="relative">
                {plan.planPrice}
                <span className="absolute w-[46px] h-[2px] bg-errorRed rounded-full rotate-[-17.17deg] left-[-5px] top-2 " />
              </span>
              <span>{getPlanSuffix(plan.type)}</span>
            </div>

            <div className="text-2xl font-bold h-[32px]">
              ${plan.planDiscountPrice}
              {getPlanSuffix(plan.type)}
              <span className={`${fromRegistration || fromNoSubscription ? "text-darkBlue dark:text-grey100" : "text-neutral3"}`} dangerouslySetInnerHTML={{ __html: plan.planPeriod }} />
            </div>
          </>
          :
          <>
            <div className="text-2xl font-bold h-[32px]">
              ${plan.planPrice}
              {getPlanSuffix(plan.type)}
              <span className={`${fromRegistration || fromNoSubscription ? "text-darkBlue dark:text-grey100" : "text-neutral3"}`} dangerouslySetInnerHTML={{ __html: plan.planPeriod }} />
            </div>
          </>}

      </div>



      {isDowngrade(currentPlan, plan.type) && !alwaysUpgrade ? (
        <Button
          className={`normal-case max-w-[230px] h-[60px] flex w-full mx-auto mt-[11px] mb-[19px] dark:border-darkBlue shadow-greenShadow ${plan.type.includes("LT") && "!mb-[13px]"
            }`}
          titleClassName="font-medium !text-base dark:text-darkBlue group-hover:text-white"
          title={isTrial ? "Change" : "Downgrade"}
          handleClick={() => {
            handleSelectPlan?.({
              type: plan.type,
              planName: plan.planName,
              planPrice: plan.planPrice,
              skip: false
            });
            goToCheckout && goToCheckout();
            handleProToolUpgrade && handleProToolUpgrade();

          }}
        />
      ) : isCurrentPlan(currentPlan, plan.type) ? (
        <div className="max-w-[210px] w-full mx-auto mt-7 mb-[29px] relative">
          <div className="mb-[15px] text-center text-electricBlue text-sm font-medium">
            This is your Current Plan
          </div>
          <div className="h-[2px] w-full  after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:rounded-full after:bg-secondary " />
        </div>
      ) : isUpgrade(currentPlan, plan.type) || alwaysUpgrade ? (
        <button
          className={` flex items-center justify-center rounded-full max-w-[230px] mt-[11px] mb-[5px] flex w-full mx-auto h-[60px] border-2 shadow-greenShadow
              border-smoothGreen bg-transparent text-darkBlue hover:bg-smoothGreen hover:border-smoothGreen hover:text-white font-bold !text-base
               ${(plan.type.toLowerCase().includes("standard") ||
              plan.type.toLowerCase().includes("proplus") || buttonFilled) && !fromRegistration && !fromNoSubscription &&
            "!bg-smoothGreen text-white hover:!text-darkBlue"
            } 
                ${(plan.type.toLowerCase().includes("standard") ||
              plan.type.toLowerCase().includes("proplus")) && (fromRegistration || fromNoSubscription) &&
            "!border-darkBlue !bg-darkBlue !text-smoothGreen hover:!bg-smoothGreen hover:!border-smoothGreen hover:!text-darkBlue"
            } 
            ${plan.type.includes("LT") && "!mb-[13px]"}
            ${fromRegistration ? "" : fromNoSubscription ? "capitalize" : "uppercase"}
            `}
          onClick={() => {
            handleSelectPlan?.({
              type: plan.type,
              planName: plan.planName,
              planPrice: plan.planPrice,
              skip: false
            });
            goToCheckout && goToCheckout();
            handleProToolUpgrade && handleProToolUpgrade();
          }}
        >
          {fromRegistration
            ? "Start $1 Trial Now"
            : isTrial
              ? "Change"
              : paymentStatus === 0 || alreadyTrial
                ? "Pay now"
                : "Upgrade now"}
        </button>
      ) : null}
      {fromRegistration && plan.skipAmout && <div className="flex justify-center items-center mb-3.5">
        <button
          className="text-sm cursor-pointer text-electricBlue hover:text-primaryPurple underline"
          onClick={() => {
            handleSelectPlan?.({
              type: plan.type,
              planName: plan.planName,
              planPrice: plan.planPrice,
              skip: true
            });
            goToCheckout && goToCheckout();
            handleProToolUpgrade && handleProToolUpgrade();
          }}
        >
          Skip Trial and Save ${plan.skipAmout}
        </button>
      </div>
      }
      {plan.planNote !== "" && (
        <div className="text-xs text-center mb-1">{plan.planNote}</div>
      )}

      <PlanFeatures planType={plan.type} />
    </div>
    <div className="h-[52px] flex justify-center items-center font-bold bg-darkBlue rounded-b-[15px] -mx-[1px] -mb-[1px] text-white text-xs">
      VIP Community + Training + Live Chat
    </div>
  </div>
);

export default Plan;
