import { PlansType, TSelectedPlan } from "types/plans";
import Plan from "./Plan";

interface Props {
  plans: PlansType;
  currentPlan: string;
  paymentStatus?: number;
  isTrial?: boolean;
  plansWrapperClassName?: string;
  goToCheckout?: () => void;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  handleProToolUpgrade?: () => void;
  fromRegistration?: boolean;
  fromNoSubscription?: boolean;
  buttonFilled?: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  alreadyTrial?: boolean;
  alwaysUpgrade?: boolean;
}

const Plans = ({
  plans,
  currentPlan,
  plansWrapperClassName,
  selectedPlan,
  paymentStatus,
  isTrial,
  goToCheckout,
  handleSelectPlan,
  handleProToolUpgrade,
  fromRegistration = false,
  buttonFilled,
  alreadyTrial,
  fromNoSubscription = false,
  alwaysUpgrade = false
}: Props) => (
  <div
    className={`max-w-[1220px] w-full flex justify-end gap-[40px] max-[1060px]:gap-[30px] max-[965px]:flex-wrap max-[1060px]:justify-center ${plansWrapperClassName || ""
      }`}
  >
    {plans.planList.map((plan, i) => (
      <Plan
        key={i}
        selectedPlan={selectedPlan}
        plan={plan}
        currentPlan={currentPlan}
        goToCheckout={goToCheckout}
        handleSelectPlan={handleSelectPlan}
        handleProToolUpgrade={handleProToolUpgrade}
        fromRegistration={fromRegistration}
        paymentStatus={paymentStatus}
        isTrial={isTrial}
        buttonFilled={buttonFilled}
        alreadyTrial={alreadyTrial}
        fromNoSubscription={fromNoSubscription}
        alwaysUpgrade={alwaysUpgrade}
      />
    ))}
  </div>
);

export default Plans;
