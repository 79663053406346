import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import Card from "components/Card";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import { FC, ReactNode, useEffect } from "react";
import ReactModal from "react-modal";

interface Props {
  helpers: ReturnType<useModal>;
  maxWidth?: string;
  className?: string;
  cardClassName?: string;
  titleClassName?: string;
  children: ReactNode;
  title?: string;
  buttonCloseClassName?: string;
  handleCloseClick?: () => void;
  overlay?: boolean;
  hideBorder?: boolean
  alwaysOpen?: boolean;
  disableDarkMode?: boolean
  shouldCloseOnOverlayClick?: boolean
}

ReactModal.setAppElement("#root");

const Modal: FC<Props> = ({
  helpers,
  children,
  title,
  className,
  maxWidth,
  cardClassName,
  titleClassName,
  hideBorder,
  buttonCloseClassName,
  handleCloseClick,
  overlay,
  alwaysOpen = false,
  disableDarkMode = false,
  shouldCloseOnOverlayClick = true
}) => {
  const { isDarkMode } = useAppSelector((state) => state.themeSlice)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      zIndex: "100",
      backgroundColor: overlay ? "rgb(128,128,128,0.3)" : "transparent",
    },
  };

  useEffect(() => {
    if (helpers.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [helpers.isOpen]);

  return (
    <ReactModal
      isOpen={alwaysOpen ? true : helpers.isOpen}
      onRequestClose={helpers.close}
      shouldCloseOnEsc
      overlayClassName={""}
      style={customStyles}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`z-50 absolute ${maxWidth || "max-w-[30rem]"} w-full ${className || ""} ${isDarkMode && !disableDarkMode ? "dark" : ""}`}
    >
      <Card
        shadow="shadow-2xl"
        className={`w-full dark:text-grey100 ${maxWidth || "max-w-[30rem]"}  ${cardClassName || ""} ${hideBorder ? "" : "border-2 border-secondary dark:border-grey500"}`}
        bg="bg-white dark:bg-grey800"
      >
        {!alwaysOpen && (
          <button className={`flex w-5 h-5 items-center justify-center absolute top-8 right-8 group ${buttonCloseClassName}`}
            onClick={() => {
              helpers.close();
              handleCloseClick?.();
            }}>
            <Close className="w-3 h-3 group-hover:fill-primaryPurple  dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100" />
          </button>
        )}

        {title && (
          <h5
            className={`text-center mb-6.5 font-bold dark:text-grey100 ${titleClassName || ""}`}
          >
            {title}
          </h5>
        )}
        {children}
      </Card>
    </ReactModal>
  );
};

export default Modal;
