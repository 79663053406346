import InfoTooltip from "components/Card/InfoTooltip";
import CenteredLoading from "components/Loading/CenteredLoading";
import { useTranslation } from "react-i18next";
import { useGetEarningStatsQuery } from "store/api/affiliateManagerApi";
import { EarningsRow, EarningsRowNumeral } from "./EarningRow";


const TotalEarnings = () => {
  const { data, isFetching } = useGetEarningStatsQuery();
  const { t } = useTranslation(['home']);

  return (
    <div className="px-8 py-5.5 border-2 border-neutral2 rounded-10 w-full dark:border-grey700 dark:bg-grey800">
      <div className="flex items-center gap-[20px]  mb-[15px]">
        <div className="font-bold ml-7">{t('Total Earning')}</div>
        <InfoTooltip title="A breakdown of your total earnings from the affiliate program" />
      </div>

      <CenteredLoading loading={isFetching}>
        <div className="flex flex-col gap-[13px]">
          <EarningsRow
            title={t('ClearedBalance')}
            amount={data?.result.clearedBalance || 0}
          />
          <EarningsRow
            title={t('PendingBalance')}
            amount={data?.result.pendingBalance || 0}
          />
          <EarningsRowNumeral
            title={t('ReferredUsers')}
            amount={data?.result.referredUsers || 0}
          />
          <EarningsRow
            title={t('CommissionEarning')}
            amount={data?.result.commissionPercentage || 0}
            isPercentage={true}
          />
        </div>
      </CenteredLoading>
    </div>
  );
};

export default TotalEarnings;
