import { createApi } from "@reduxjs/toolkit/query/react";
import {
  FindSimilarItemsAliRes,
  FindSimilarItemsAmazonRes,
  FindSimilarItemsBabaRes,
  FindSimilarItemsCjdRes,
  FindSimilarItemsWalRes,
  FindThirdPartyDataRes,
  GetAliseekProductRes,
  PostAddManuallyRequest,
  PostAddManuallyResponse,
  SaveItemRequest,
  SaveItemResponse,
} from "types/services/iconFinder";
import { makeQueryParams } from "utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const iconFinderApi = createApi({
  reducerPath: "iconFinder",
  baseQuery,
  tagTypes: [queryTags.iconFinder.findItems],
  endpoints: (builder) => ({
    findAmazonItems: builder.query<
      FindSimilarItemsAmazonRes,
      {
        asin: string;
        ebayId: string | number;
      }
    >({
      query: ({ asin, ebayId }) => ({
        url: queryKeys.iconFinder.findItems.amazonData,
        params: { asin, ebayId },
        method: "GET",
      }),
    }),
    findThirdPartyData: builder.query<FindThirdPartyDataRes, number>({
      query: (ebayId) => ({
        url: queryKeys.iconFinder.findItems.thirdPartyData,
        params: { ebayId },
        method: "POST",
      }),
    }),
    // findAmazonItems: builder.query<FindSimilarItemsAmazonRes, number | string>({
    //   query: (ebayId) => ({
    //     url: queryKeys.iconFinder.findItems.amazonData,
    //     params: { ebayId },
    //     method: "POST",
    //   }),
    // }),
    findWalItems: builder.query<FindSimilarItemsWalRes, number>({
      query: (ebayId) => ({
        url: queryKeys.iconFinder.findItems.WAL,
        params: { ebayId },
        method: "POST",
      }),
    }),
    findAliItems: builder.query<FindSimilarItemsAliRes, string>({
      query: (img) => ({
        url: makeQueryParams(queryKeys.iconFinder.findItems.Ali, {
          imageUrl: encodeURIComponent(img),
          bypassURLCreation: true,
        }),
        method: "POST",
      }),
    }),
    findBabaItems: builder.query<FindSimilarItemsBabaRes, string>({
      query: (img) => ({
        url: queryKeys.iconFinder.findItems.Baba,
        params: {
          imageUrl: img,
        },
        method: "POST",
      }),
    }),
    findCjdItems: builder.query<FindSimilarItemsCjdRes, string>({
      query: (img) => ({
        url: queryKeys.iconFinder.findItems.Cjd,
        params: {
          imageUrl: encodeURIComponent(img),
        },
        method: "POST",
      }),
    }),
    addManually: builder.mutation<
      PostAddManuallyResponse,
      PostAddManuallyRequest
    >({
      query: ({ isWal, ...body }) => ({
        url: queryKeys.iconFinder.addManually[isWal ? "wal" : "amazon"],
        params: {
          ...(isWal ? { upc: body.asin } : { asin: body.asin }),
          itemId: body.itemId,
        },
        method: "POST",
      }),
      invalidatesTags: [queryTags.iconFinder.findItems],
    }),
    getAliSalesById: builder.query<null, string>({
      query: (aliId) => ({
        url: queryKeys.iconFinder.findItems.aliSalesById,
        params: { aliId },
        method: "GET",
      }),
    }),
    getAliseekProduct: builder.query<GetAliseekProductRes, string>({
      query: (url) => ({
        url: queryKeys.iconFinder.findItems.aliseekProduct,
        params: {
          detailsURL: encodeURIComponent(url),
        },
        method: "GET",
      }),
    }),
    saveItem: builder.mutation<SaveItemResponse, SaveItemRequest>({
      query: (body) => ({
        url: queryKeys.iconFinder.saveItemPr,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAliSalesByIdQuery,
  useGetAliseekProductQuery,
  useFindAmazonItemsQuery,
  useFindWalItemsQuery,
  useFindAliItemsQuery,
  useFindBabaItemsQuery,
  useFindCjdItemsQuery,
  useSaveItemMutation,
  useAddManuallyMutation,
  useFindThirdPartyDataQuery,
  useLazyGetAliseekProductQuery,
} = iconFinderApi;
