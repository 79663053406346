import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { logoutUser } from "store/slices/userSlice";
import { useAppDispatch } from "./useAppDispatch";

const useLogout = () => {
  const [, , removeCookie] = useCookies(["CAT"]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logout = () => {
    removeCookie("CAT", {
      domain: process.env.REACT_APP_DOMAIN_URL,
    });
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("user");

    dispatch(logoutUser());
    navigate(PATH.login);
    window.location.reload();
  };

  return logout;
};

export default useLogout;
