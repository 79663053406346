import Button from "components/Button";

// Hooks

// Icons
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { campaignListCols } from "assets/tableColumns/campaignList";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { useState } from "react";
import { useGetCampaignsQuery } from "store/api/affiliateManagerApi";
import { CampaignList } from "types/services/affiliateManager";
import AddCampaign from "../../components/AddCampaign";
import InvoiceDetails from "../../components/InvoiceDetails";

const tableStyles: TableStyles = {
  px: "px-0",
  head: { rowHeight: "h-[50px]", contHeight: "h-[36px]" },
  body: { rowHeight: "h-[55px]", contHeight: "h-[37px]" },
  minWidth: "min-w-[730px]",
  empty: { rowHeight: "h-[80px]", contHeight: "" },
};
const Campaigns = () => {
  const { data, isFetching } = useGetCampaignsQuery();
  const modal = useModal();
  const campaignModal = useModal();
  const [selectedItem, setSelectedItem] = useState<CampaignList | null>(null);

  const onSelectItem = (item: CampaignList) => {
    setSelectedItem(item);
    campaignModal.open();
  };

  const { table } = useTable({
    columns: campaignListCols(onSelectItem),
    data: data?.result || [],
    dataDeps: [data],
    pagination: true,
  });

  return (
    <div className="border-2 border-neutral2 rounded-[10px] pt-[23px] px-[15px] pb-4.5 h-full dark:border-grey700 dark:bg-grey800">
      <div className="flex items-center justify-between mb-8 max-sm:flex-col max-sm:gap-3">
        <Button
          title="Create New Campaign"
          handleClick={modal.open}
          color="smoothGreen"
          Icon={Plus}
          height="h-[51px]"
          className="max-w-[231px] dark:bg-deepSpace900"
        />

        <Pagination table={table} className="justify-between" />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isFetching} />

      {selectedItem ? (
        <InvoiceDetails helpers={campaignModal} item={selectedItem} />
      ) : (
        ""
      )}
      <AddCampaign helpers={modal} />
    </div>
  );
};

export default Campaigns;
