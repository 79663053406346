/* eslint-disable no-lone-blocks */
import Button from "components/Button";
import Input from "components/Input";
import Loading from "components/Loading";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useAddVatNumberMutation } from "store/api/managePaymentApi";
import { handleBootIntercom } from "store/slices/intercomSlice";
import { TSelectedPlan } from "types/plans";
import { UpgradeCheckRes } from "types/services/managePayments";
import { formatCurrency } from "utils/formatCurrency";
import { getPlanFullSuffix } from "utils/planUtils";

interface Props {
  selectedPlan: TSelectedPlan;
  isLoading: boolean;
  data?: UpgradeCheckRes;
  fromRegistration?: boolean;
  wrapperStyles?: string;
  handleVatNumberUpdate?: () => void;
}

const CheckoutOrderSummary = ({
  selectedPlan,
  isLoading,
  wrapperStyles,
  data,
  fromRegistration = false,
  handleVatNumberUpdate
}: Props) => {

  const [showInput, setShowInput] = useState(false);
  const [vatNumber, setVatNumber] = useState<string>("");
  const [addVatNumber] = useAddVatNumberMutation();
  const [vatErrorMessage, setVatErrorMessage] = useState<string | null>(null)
  const [showVatError, setShowVatError] = useState(false)
  const dispatch = useAppDispatch()
  //const addVatValidationQuery = (vat: string) => useAddVatValidationQuery(vat);

  const handleButtonClick = () => {
    addVatNumber(vatNumber).unwrap().then((res) => {
      if (res.result) {
        setVatErrorMessage(null)
        setShowVatError(false)
        handleVatNumberUpdate?.();
        setShowInput(false)
      }
      else {
        setVatErrorMessage("An error occured")
        setShowVatError(true)
      }
    })
      .catch((err) => {
        setVatErrorMessage(err.data)
        setShowVatError(true)
      });
  };
  const toggleInput = () => {
    setShowInput(!showInput);
  };

  useEffect(() => {
    if (data) {
      setVatNumber(data?.result?.countryCode || "")
    }
  }, [data?.result?.countryCode])

  return (
    <div
      // style={data ? { visibility: "visible" } : { visibility: "hidden" }}
      className={` ${data ? "" : "hidden"}
       ${fromRegistration
          ? "w-full mb-[53px] max-sm:mb-0"
          : "rounded-[10px] max-w-[445px] border-[2px] border-[#EBF4FE] w-full px-[25px] py-7 max-sm:p-2 dark:bg-deepSpace900 dark:border-deepSpace900"} ${wrapperStyles || ""}`
      }
    >
      <h4 className={`font-bold ${fromRegistration ? "text-center text-lg mb-[15px] " : "mb-[25px]"}`}>Order Summary</h4>
      {isLoading ? (
        <Loading
          fill="fill-primaryPurple dark:fill-purple500"
          className="h-[100px] flex items-center justify-center"
        />
      ) : (
        <>
          <div className="w-full h-[2px] bg-secondary dark:bg-deepSpace800 rounded-full mb-4" />
          <div className="flex items-center justify-between mb-[15px] max-sm:mb-2.5">
            <div className="font-bold text-sm">
              {selectedPlan.planName} {!fromRegistration && getPlanFullSuffix(selectedPlan.type)}
            </div>
            <div className="font-medium text-sm">${data?.result.price} {fromRegistration && getPlanFullSuffix(selectedPlan.type)}</div>
          </div>
          {data?.result.extraDiscount ? (
            <div className="flex items-center justify-between mb-[15px]">
              <h4 className="font-medium text-sm">Current Plan Discount</h4>
              <p className="font-medium text-sm uppercase text-success">
                -{formatCurrency((data?.result.extraDiscount) || 0)}
              </p>
            </div>
          ) : ""}

          {data?.result.skipDiscount !== null && (
            <div className="flex items-center justify-between mb-[15px]">
              <h4 className="font-medium text-sm">Skip Trial Discount</h4>
              <p className="font-medium text-sm uppercase text-success">
                -{formatCurrency(data?.result.skipDiscount || 0)}
              </p>
            </div>
          )}
          {data?.result.couponDiscountPercentage !== null && (
            <div className="flex items-center justify-between mb-[15px] max-sm:mb-2.5">
              <div className="font-medium text-sm">
                Promo or Gift Code ({data?.result.couponDiscountPercentage}%)</div>
              <div className="font-medium text-sm uppercase text-success">
                -{formatCurrency(data?.result.couponDiscount || 0)}
              </div>
            </div>
          )}


          {data?.result.vatCharge != null && (
            <div className="flex max-sm:flex-col items-center justify-between mb-3 w-full">
              <div className="flex justify-between w-full">
                <h4 className="font-bold text-sm uppercase">Vat<span className="font-medium">({data?.result.vatPercentage}%)</span></h4>
                <span className="ml-4">
                  {data?.result.vatNumber}
                </span>
              </div>
              <div className={`flex  w-full ${data?.result.eligibleForVat ? "justify-between" : "justify-end"}`}>
                {data?.result.eligibleForVat && (
                  <button onClick={toggleInput} className="w-[102px] h-5 text-sm underline text-electricBlue mr-[10px]">
                    + Add Number
                  </button>
                )}
                <p className="font-medium text-sm">${data?.result.vatCharge}</p>
              </div>

            </div>
          )}
          {showInput && (
            <div>
              <div className="flex items-center gap-2.5">
                <Input
                  className="border-[2px] rounded-[7px] max-w-[249px] h-9 w-full px-[17px] !py-[5px]"
                  value={vatNumber}
                  variant="contained"
                  placeholder="Add Vat Number"
                  handleChange={(newVal) => setVatNumber(newVal)}
                  maxLength={20}
                />
                <Button
                  loading={false}
                  className="max-w-[103px] w-full"
                  height="h-9"
                  title="Save"
                  handleClick={() => {
                    handleButtonClick()
                  }}
                />
              </div>
              {showVatError && vatErrorMessage && (<div className="text-errorRed text-xs mt-2">{vatErrorMessage}</div>)}
            </div>
          )}

          <div className={`w-full h-[2px] bg-secondary dark:bg-deepSpace800 rounded-full ${fromRegistration ? "mt-[21px] mb-[30px] max-lg:mb-5 max-sm:mb-3" : "my-5.5"}`} />
          {fromRegistration && !selectedPlan.skip && data?.result.discountedPrice !== data?.result.totalToday &&
            < div className="flex items-center justify-between font-medium text-sm mb-5 max-sm:mb-2.5">
              <h6>Total after 7 days</h6>
              <p>{formatCurrency(data?.result.discountedPrice || 0)}</p>
            </div>}
          <div className="flex items-center justify-between mb-5 max-sm:mb-2.5">
            <div className="font-bold text-sm">{fromRegistration ? "Total today" : "Total"}</div>

            <div className="font-medium text-sm">
              {formatCurrency(data?.result.totalToday || 0)}
            </div>
          </div>
          {fromRegistration ? (
            <>
              <div className="flex items-center justify-between text-xs font-medium text-neutral3">
                <p>Cancel anytime hassle free</p>
                <button className="underline" onClick={() => dispatch(handleBootIntercom(true))}>
                  Need Help?
                </button>
              </div>
              {/* <div className="text-xs mt-[10px]">{getCheckoutNoteText(selectedPlan.type)}</div> */}
            </>
          ) : ""}
          {data?.result.skipDiscount !== null && data?.result.defaultCouponName && data?.result.frequency === 'Monthly' &&
            <p className="font-medium text-sm text-success mt-3">
              {`You skipped the trial and will receive $${data?.result.skipDiscount} discount this month. The ${data?.result.defaultCouponName} coupon will be applied to your next month's bill.`}
            </p>
          }
        </>
      )
      }
    </div >
  )
};

export default CheckoutOrderSummary;
