import HoveredIcon from "components/HoveredIcon";
import Label from "components/Label";

// Types

// Hooks

// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { WaitingList } from "types/services/waitingList";
import { formatDate } from "utils/formatTime";

export const searchedListCols = (
  onDelete: (id: string) => void,
  deleteLoading: (id: string) => void
): ColumnDef<WaitingList>[] => [
    {
      header: "Store Name",
      accessorKey: "storeName",
      cell: (storeName) => (
        <Link
          to={`${PATH.competitorResearch.ebay}?search=${String(
            storeName.getValue()
          )}`}
          target="_blank"
          rel="noreferrer"
          className="hover:text-primaryPurple dark:hover:text-purple500"
        >
          {String(storeName.getValue())}
        </Link>
      ),
    },
    {
      header: "Date Added",
      accessorKey: "dateAdded",
      cell: (date) => formatDate(String(date.getValue())),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (status) => (
        <Label
          bgColor={Boolean(status.getValue()) ? "success" : "errorOrange"}
          title={Boolean(status.getValue()) ? "Completed" : "In Progress"}
          maxWidth="max-w-[298px] w-full"
          className="mx-4 text-white"
        />
      ),
    },
    {
      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "id",
      cell: (id) => (
        <HoveredIcon
          variant="outlined"
          handleClick={() => onDelete(String(id.getValue()))}
          isLoading={Boolean(deleteLoading(String(id.getValue())))}
          Icon={Trash}
          width="w-7"
          height="h-7"
          iconClassName="h-4"
        />
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
