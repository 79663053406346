import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  shadow?: string | false;
  bg?: string;
  rounded?: string;
  bordered?: boolean;
}
const Card: FC<Props> = ({
  children,
  className,
  shadow,
  bg,
  rounded,
  bordered,
}) => (
  <div
    className={`${shadow ? shadow : shadow === false ? "" : "shadow-lg"} ${bg || "bg-white dark:bg-black900"
      } ${rounded || "rounded-10"} ${bordered ? "border-2 border-neutral2 dark:border-grey700" : ""
      } ${className}`}
  >
    {children}
  </div>
);

export default Card;
