import { useEffect, useState } from "react";
import CreditCard from "./CreditCard";
import PaymentTab from "./PaymentTab";



interface Props {
  className?: string;
  selectedPlan: string;
  coupon: string;
  handleMethod?: (type: string) => void;
  skip: boolean;
  fromRegistration: boolean;
  handleAlreadyTrial?: (isTrial: boolean) => void;
  handleApplyCoupon?: (newVal: string) => void;
  isPayNow?: boolean;
  handleStopPay?: () => void;
  selectedMethod?: string;
  onCCPaymentLoading?: (isLoading: boolean) => void;
  alreadyTrial?: boolean;
  amount?: number

}

const PaymentMethod = ({
  className,
  handleMethod,
  selectedMethod,
  selectedPlan,
  coupon,
  skip,
  fromRegistration,
  handleAlreadyTrial,
  handleApplyCoupon,
  onCCPaymentLoading,
  isPayNow,
  handleStopPay,
  alreadyTrial = false,
  amount
}: Props) => {
  const [tab, setTab] = useState("");
  const handleSetTab = (type: string) => {
    setTab(type);
    handleMethod && handleMethod(type);
  };

  useEffect(() => {
    setTab(selectedMethod || "")
  }, [selectedMethod]);

  return (
    <div>
      <div className={`flex items-center max-sm:flex-col gap-5 max-sm:gap-[15px] sm:h-[70px] ${className || ""}`}>
        <PaymentTab
          type="cc"
          handleClick={handleSetTab}
          tab={tab}
        />
        <PaymentTab
          type="pp"
          handleClick={handleSetTab}
          tab={tab}
        />
        <PaymentTab
          type="py"
          handleClick={handleSetTab}
          tab={tab}
        />
      </div>

      {tab === "cc" && (
        <CreditCard
          selectedPlan={selectedPlan}
          coupon={coupon} skip={skip}
          fromRegistration={fromRegistration}
          handleAlreadyTrial={handleAlreadyTrial}
          handleApplyCoupon={handleApplyCoupon}
          isPayNow={isPayNow}
          onCCPaymentLoading={onCCPaymentLoading}
          handleStopPay={handleStopPay}
          alreadyTrial={alreadyTrial}
          amount={amount}
        />
      )}
    </div>
  );
};

export default PaymentMethod;
